// src/pages/PropertyTaxCard/components/modals/EditCurrentOwnerModal.js

import React, { useState, useEffect, useMemo } from 'react';
import axiosInstance from '../../../axiosInstance';
import getApiUrl from '../../../config';
import './Modal.css';
import { toast } from 'react-toastify';
import OwnerSearch from '../components/OwnerSearch'; // Ensure correct path
import DeedInfoForm from '../components/DeedInfoForm';
import AddOwnerForm from '../components/AddOwnerForm';

const EditCurrentOwnerModal = ({
    isOpen,
    onClose,
    accountNumber,
    onOwnerUpdated,
    existingOwners,
    existingDeedInfo,
    isReplacingOwners, // New prop to handle replacing owners
}) => {
    const [allOwners, setAllOwners] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedOwners, setSelectedOwners] = useState([]); // Owners to retain or add
    const [showAddOwnerForm, setShowAddOwnerForm] = useState(false);
    const [deedInfo, setDeedInfo] = useState({
        deedBook: '',
        deedPage: '',
        deedDate: '',
    });
    const [isLoading, setIsLoading] = useState(false);

    // Format date for input[type="date"]
    const formatDateForInput = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        if (isNaN(date)) return '';
        const yyyy = date.getFullYear();
        const mm = String(date.getMonth() + 1).padStart(2, '0');
        const dd = String(date.getDate()).padStart(2, '0');
        return `${yyyy}-${mm}-${dd}`;
    };

    useEffect(() => {
        if (isOpen) {
            fetchAllOwners();
            // Initialize deedInfo with existingDeedInfo
            if (existingDeedInfo) {
                setDeedInfo({
                    ...existingDeedInfo,
                    deedDate: formatDateForInput(existingDeedInfo.deedDate),
                });
                console.log('Deed info initialized:', {
                    ...existingDeedInfo,
                    deedDate: formatDateForInput(existingDeedInfo.deedDate),
                });
            } else {
                setDeedInfo({
                    deedBook: '',
                    deedPage: '',
                    deedDate: '',
                });
                console.log('Deed info initialized with empty fields');
            }
            // Initialize selectedOwners based on whether we're replacing owners
            if (isReplacingOwners) {
                setSelectedOwners([]); // Remove all existing owners
                console.log('Replacing owners: selectedOwners set to empty');
            } else if (existingOwners && existingOwners.length > 0) {
                setSelectedOwners(existingOwners.map(owner => owner.ownerId));
                console.log('Selected Owners initialized:', existingOwners.map(owner => owner.ownerId));
            } else {
                setSelectedOwners([]); // Empty in add mode
                console.log('Selected Owners initialized as empty');
            }
        } else {
            // Reset state when modal closes
            setSearchTerm('');
            setSelectedOwners([]);
            setShowAddOwnerForm(false);
            setDeedInfo({
                deedBook: '',
                deedPage: '',
                deedDate: '',
            });
            console.log('Modal closed, states reset');
        }
    }, [isOpen, existingDeedInfo, existingOwners, isReplacingOwners]);

    const fetchAllOwners = async () => {
        setIsLoading(true);
        const apiUrl = getApiUrl();
        try {
            const response = await axiosInstance.get(`${apiUrl}/api/property-owners`);
            setAllOwners(response.data);
            console.log('All owners fetched:', response.data);
        } catch (error) {
            console.error('Error fetching all owners:', error);
            toast.error('Failed to fetch owners.');
        } finally {
            setIsLoading(false);
        }
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    // Filter owners based on search term
    const filteredOwners = useMemo(() => {
        return allOwners.filter(owner => {
            return owner.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
                   owner.lastName.toLowerCase().includes(searchTerm.toLowerCase());
        });
    }, [allOwners, searchTerm]);

    const handleOwnerSelect = (ownerId) => {
        setSelectedOwners(prev => {
            if (prev.includes(ownerId)) {
                const updated = prev.filter(id => id !== ownerId);
                console.log('Owner deselected:', ownerId, 'Updated selectedOwners:', updated);
                return updated;
            } else {
                const updated = [...prev, ownerId];
                console.log('Owner selected:', ownerId, 'Updated selectedOwners:', updated);
                return updated;
            }
        });
    };

    // Compute ownersToRemove, ownersToAdd, deedInfoChanged, and hasChanges
    const { ownersToRemove, ownersToAdd, deedInfoChanged, hasChanges } = useMemo(() => {
      const existingOwnerIds = existingOwners.map(owner => owner.ownerId);
      const ownersToRemove = existingOwnerIds.filter(id => !selectedOwners.includes(id));
      const ownersToAdd = selectedOwners.filter(id => !existingOwnerIds.includes(id));
      const deedInfoChanged = (
          deedInfo.deedBook !== (existingDeedInfo?.deedBook || '') ||
          deedInfo.deedPage !== (existingDeedInfo?.deedPage || '') ||
          deedInfo.deedDate !== formatDateForInput(existingDeedInfo?.deedDate || '')
      );
      const hasChanges = ownersToRemove.length > 0 || ownersToAdd.length > 0 || deedInfoChanged;
      return { ownersToRemove, ownersToAdd, deedInfoChanged, hasChanges };
  }, [existingOwners, selectedOwners, deedInfo, existingDeedInfo]);
  

    const handleSaveChanges = async () => {
      // Validate deed information
      if (!deedInfo.deedBook || !deedInfo.deedPage || !deedInfo.deedDate) {
          toast.error('Please fill in all deed information fields.');
          return;
      }
  
      const apiUrl = getApiUrl();
      try {
          // Perform the transactional update
          await axiosInstance.put(`${apiUrl}/api/properties/${accountNumber}/edit-owners`, {
              ownerIdsToAdd: ownersToAdd, // Always an array
              ownerIdsToRemove: ownersToRemove, // Always an array
              deedBook: deedInfo.deedBook,
              deedPage: deedInfo.deedPage,
              deedDate: deedInfo.deedDate,
              isReplacingOwners: isReplacingOwners, // Ensure it's a boolean
          });
          toast.success('Owners and deed information updated successfully.');
  
          onOwnerUpdated(); // Refresh owners in parent component
          onClose(); // Close the modal
  
          // Reset form states
          setSelectedOwners([]);
          setShowAddOwnerForm(false);
          setDeedInfo({
              deedBook: '',
              deedPage: '',
              deedDate: '',
          });
          console.log('Form reset successfully.');
      } catch (error) {
          console.error('Error updating owners:', error);
          if (error.response) {
              console.error('Response data:', error.response.data);
              toast.error(`Failed to update owners: ${error.response.data.error || 'Please try again.'}`);
          } else if (error.request) {
              console.error('No response received:', error.request);
              toast.error('No response from server. Please check your network connection.');
          } else {
              console.error('Error setting up request:', error.message);
              toast.error('Error updating owners. Please try again.');
          }
      }
  };

    const handleAddOwner = async (ownerData) => {
        const apiUrl = getApiUrl();
        try {
            const response = await axiosInstance.post(`${apiUrl}/api/property-owners`, ownerData);
            const createdOwner = response.data;
            setSelectedOwners(prev => [...prev, createdOwner.ownerId]);
            setAllOwners(prev => [...prev, createdOwner]); // Add to allOwners list
            toast.success('New owner added successfully.');
            setShowAddOwnerForm(false);
            console.log('New owner added:', createdOwner);
        } catch (error) {
            console.error('Error adding new owner:', error);
            if (error.response) {
                console.error('Response data:', error.response.data);
                toast.error(`Failed to add new owner: ${error.response.data.error || 'Please try again.'}`);
            } else if (error.request) {
                console.error('No response received:', error.request);
                toast.error('No response from server. Please check your network connection.');
            } else {
                console.error('Error setting up request:', error.message);
                toast.error('Error adding new owner. Please try again.');
            }
        }
    };

    const handleDeedInfoChange = (e) => {
        const { name, value } = e.target;
        setDeedInfo({ ...deedInfo, [name]: value });
    };

    if (!isOpen) return null;

    return (
        <div className="modal-overlay" role="dialog" aria-modal="true" aria-labelledby="edit-modal-title">
            <div className="modal-content">
                <h2 id="edit-modal-title">Edit Owners</h2>
                <button className="close-button" onClick={onClose} aria-label="Close Modal">×</button>
                {isLoading ? (
                    <div className="loader"></div>
                ) : (
                    <>
                        {/* Owner Management Section */}
                        <div className="owner-section">
                            <OwnerSearch
                                searchTerm={searchTerm}
                                onSearchChange={handleSearchChange}
                                owners={filteredOwners}
                                selectedOwnerIds={selectedOwners}
                                onSelectOwner={handleOwnerSelect}
                            />

                            {/* Buttons for Owner Operations */}
                            <div className="button-container">
                                <button
                                    type="button"
                                    onClick={handleSaveChanges}
                                    className="primary-button"
                                    disabled={!hasChanges} // Disable if no changes
                                >
                                    Save Changes
                                </button>
                                <button
                                    type="button"
                                    onClick={() => setShowAddOwnerForm(true)}
                                    className="secondary-button"
                                >
                                    Add New Owner
                                </button>
                            </div>

                            {/* AddOwnerForm Modal */}
                            {showAddOwnerForm && (
                                <AddOwnerForm
                                    onAddOwner={handleAddOwner}
                                    onCancel={() => setShowAddOwnerForm(false)}
                                />
                            )}
                        </div>

                        {/* Deed Information Editing Section */}
                        <hr />

                        <div className="deed-info-section">
                            <DeedInfoForm
                                deedInfo={deedInfo}
                                onChange={handleDeedInfoChange}
                            />
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default EditCurrentOwnerModal;
