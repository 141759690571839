import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './AccountSettings.css';
import axiosInstance from '../../axiosInstance';

const AccountSettings = () => {
    const [username, setUsername] = useState('');
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        console.log('Fetching user data...');
        axiosInstance.get('/api/current')
            .then(response => {
                console.log('User data received:', response.data);
                if (response.data && response.data.username) {
                    setUsername(response.data.username);
                    console.log('Username set to:', response.data.username);
                } else {
                    console.error('Username not found in response data');
                    setError('Username not found in response');
                }
            })
            .catch(error => {
                console.error('Error fetching user data:', error.response ? error.response.data : error.message);
                setError('Failed to fetch user data. Please try logging in again.');
            });
    }, []);

    const handleSave = (e) => {
        e.preventDefault();
        if (newPassword !== confirmPassword) {
            setError("New passwords don't match!");
            return;
        }
        
        axiosInstance.post('/api/change-password', { currentPassword, newPassword })
            .then(response => {
                console.log('Password changed successfully');
                setSuccessMessage('Password changed successfully! Redirecting to dashboard...');
                setCurrentPassword('');
                setNewPassword('');
                setConfirmPassword('');
                setError(null);
                setTimeout(() => {
                    navigate('/dashboard');
                }, 2000); // Redirect after 2 seconds
            })
            .catch(error => {
                console.error('Error changing password:', error.response ? error.response.data : error.message);
                setError(error.response ? error.response.data : 'Failed to change password. Please try again.');
                setSuccessMessage(null);
            });
    };

    return (
        <div className="account-settings">
            <h2>Account Settings</h2>
            {error && <p className="error-message">{error}</p>}
            {successMessage && <p className="success-message">{successMessage}</p>}
            <form onSubmit={handleSave}>
                <div className="form-group">
                    <label>Username</label>
                    <input
                        type="text"
                        value={username}
                        readOnly
                    />
                </div>
                <div className="form-group">
                    <label>Current Password</label>
                    <input
                        type="password"
                        value={currentPassword}
                        onChange={(e) => setCurrentPassword(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label>New Password</label>
                    <input
                        type="password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label>Confirm New Password</label>
                    <input
                        type="password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                    />
                </div>
                <button type="submit">Change Password</button>
            </form>
        </div>
    );
};

export default AccountSettings;