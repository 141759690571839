import { useState, useEffect, useCallback } from 'react';
import { isTokenValid, logout } from '../utils/auth';

const useAuth = () => {
    const [token, setToken] = useState(localStorage.getItem('token'));

    const logoutIfInvalid = useCallback(async () => {
        const valid = await isTokenValid();
        if (!valid) {
            logout();
            setToken(null);
        }
    }, []);
    
    useEffect(() => {
        const handleStorageEvent = (event) => {
            if (event.key === 'token') {
                setToken(localStorage.getItem('token'));
            }
        };

        const validateToken = () => {
            const newToken = localStorage.getItem('token');
            setToken(newToken);
            if (newToken) {
                logoutIfInvalid();
            }
        };

        window.addEventListener('storage', handleStorageEvent);
        validateToken(); // Validate token on mount

        return () => {
            window.removeEventListener('storage', handleStorageEvent);
        };
    }, [logoutIfInvalid]);

    return [token, setToken];
};

export default useAuth;
