import React, { useState } from 'react';
import './Collapsible.css'; // Import the CSS file for styling

const Collapsible = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="collapsible">
      <div className="collapsible-header" onClick={toggleOpen}>
        <h2>{title}</h2>
        <button className="toggle-button">{isOpen ? '-' : '+'}</button>
      </div>
      <div className={`collapsible-content ${isOpen ? 'open' : 'collapsed'}`}>
        {children}
      </div>
    </div>
  );
};

export default Collapsible;
