import { useState, useEffect } from 'react';
import axiosInstance from '../../../axiosInstance';

const useRoofTypes = () => {
  const [roofTypes, setRoofTypes] = useState([]);
  const [newRoofType, setNewRoofType] = useState({ type_name: '' });
  const [updateSuccess, setUpdateSuccess] = useState('');

  useEffect(() => {
    fetchRoofTypes();
  }, []);

  const fetchRoofTypes = async () => {
    try {
      const response = await axiosInstance.get('/api/roof-types');
      setRoofTypes(response.data);
    } catch (error) {
      console.error('Error fetching roof types:', error);
    }
  };

  const handleAddRoofType = async () => {
    try {
      const response = await axiosInstance.post('/api/roof-types', newRoofType);
      if (response.data.success) {
        setRoofTypes([...roofTypes, { ...newRoofType, id: response.data.id }]);
        setNewRoofType({ type_name: '' });
      }
    } catch (error) {
      console.error('Error adding roof type:', error);
    }
  };

  const handleUpdateRoofType = async (id, updatedRoofType) => {
    try {
      const response = await axiosInstance.put(`/api/roof-types/${id}`, updatedRoofType);
      if (response.data.success) {
        setRoofTypes(roofTypes.map(type => (type.id === id ? updatedRoofType : type)));
        setUpdateSuccess('Roof type updated successfully!');
        setTimeout(() => setUpdateSuccess(''), 3000);
      }
    } catch (error) {
      console.error('Error updating roof type:', error);
    }
  };

  const handleDeleteRoofType = async (id) => {
    try {
      const response = await axiosInstance.delete(`/api/roof-types/${id}`);
      if (response.data.success) {
        setRoofTypes(roofTypes.filter(type => type.id !== id));
      }
    } catch (error) {
      console.error('Error deleting roof type:', error);
    }
  };

  const handleRoofTypeInputChange = (index, field, value) => {
    const updatedRoofTypes = [...roofTypes];
    updatedRoofTypes[index][field] = value;
    setRoofTypes(updatedRoofTypes);
  };

  return {
    roofTypes,
    newRoofType,
    setNewRoofType,
    handleAddRoofType,
    handleUpdateRoofType,
    handleDeleteRoofType,
    handleRoofTypeInputChange,
    updateSuccess
  };
};

export default useRoofTypes;