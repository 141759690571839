import React, { useState } from 'react';
import './Modal.css';

const AddInspectionModal = ({ isOpen, onClose, onSave }) => {
  const [inspectionData, setInspectionData] = useState({
    date: '',
    description: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInspectionData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(inspectionData);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Add Inspection</h2>
        <form onSubmit={handleSubmit}>
          <input
            type="date"
            name="date"
            value={inspectionData.date}
            onChange={handleChange}
            required
          />
          <textarea
            name="description"
            value={inspectionData.description}
            onChange={handleChange}
            placeholder="Description"
            required
          />
          <button type="submit">Save</button>
          <button type="button" onClick={onClose}>Cancel</button>
        </form>
      </div>
    </div>
  );
};

export default AddInspectionModal;