import React, { useState } from 'react';
import './Modal.css';

const AddSaleModal = ({ isOpen, onClose, onSave }) => {
  const [saleData, setSaleData] = useState({
    date: '',
    price: '',
    type: '',
    financing: '',
    validity: '',
    verified: false
  });

  const saleTypes = [
    'Arms Length Sale',
    'Short Sale',
    'Foreclosure',
    'Bank-Owned Sale',
    'Family Sale',
    'Tax Sale',
    'Auction Sale'
  ];

  const financingTypes = [
    'Cash Sale',
    'Conventional Mortgage',
    'FHA Loan',
    'VA Loan',
    'Assumed Mortgage',
    'Owner Financing',
    'USDA Loan',
    'Hard Money Loan'
  ];

  const validityTypes = [
    'Public Record',
    'MLS Listing',
    'Seller Confirmed',
    'Buyer Confirmed',
    'Real Estate Agent Confirmed',
    'Appraiser Verified',
    'Court Record',
    'Unverified'
  ];

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setSaleData(prevData => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(saleData);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Add Sale</h2>
        <form onSubmit={handleSubmit}>
          <input
            type="date"
            name="date"
            value={saleData.date}
            onChange={handleChange}
            required
          />
          <input
            type="number"
            name="price"
            value={saleData.price}
            onChange={handleChange}
            placeholder="Price"
            required
          />
          <select
            name="type"
            value={saleData.type}
            onChange={handleChange}
            required
          >
            <option value="">Select Sale Type</option>
            {saleTypes.map((type, index) => (
              <option key={index} value={type}>{type}</option>
            ))}
          </select>
          <select
            name="financing"
            value={saleData.financing}
            onChange={handleChange}
            required
          >
            <option value="">Select Financing Type</option>
            {financingTypes.map((type, index) => (
              <option key={index} value={type}>{type}</option>
            ))}
          </select>
          <select
            name="validity"
            value={saleData.validity}
            onChange={handleChange}
            required
          >
            <option value="">Select Validity</option>
            {validityTypes.map((type, index) => (
              <option key={index} value={type}>{type}</option>
            ))}
          </select>
          <div className="checkbox-container">
            <label htmlFor="verified">Verified</label>
            <input
              type="checkbox"
              id="verified"
              name="verified"
              checked={saleData.verified}
              onChange={handleChange}
            />
          </div>
          <div className="button-container">
            <button type="submit">Save</button>
            <button type="button" onClick={onClose}>Cancel</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddSaleModal;