import { useState, useEffect } from 'react';
import axiosInstance from '../../../axiosInstance';

const useExemptions = () => {
  // State to store the list of exemptions
  const [exemptions, setExemptions] = useState([]);

  // State to store the new exemption being added
  const [newExemption, setNewExemption] = useState({
    exemption_name: '',
    amount_type: 'dollar',
    apply_order: 'building_first',
    adjust_by_ratio: false,
    exemption_amount: ''
  });

  // State to store success message after updating an exemption
  const [updateSuccess, setUpdateSuccess] = useState('');

  // Effect hook to fetch exemptions when the component mounts
  useEffect(() => {
    fetchExemptions();
  }, []);

  // Function to fetch all exemptions from the API
  const fetchExemptions = async () => {
    try {
      const response = await axiosInstance.get('/api/exemptions');
      setExemptions(response.data);
    } catch (error) {
      console.error('Error fetching exemptions:', error);
    }
  };

  // Function to add a new exemption
  const handleAddExemption = async () => {
    try {
      const response = await axiosInstance.post('/api/exemptions', newExemption);
      if (response.data.success) {
        // Add the new exemption to the list and reset the newExemption state
        setExemptions([...exemptions, { ...newExemption, id: response.data.id }]);
        setNewExemption({
          exemption_name: '',
          amount_type: 'dollar',
          apply_order: 'building_first',
          adjust_by_ratio: false,
          exemption_amount: ''
        });
      }
    } catch (error) {
      console.error('Error adding exemption:', error);
    }
  };

  // Function to update an existing exemption
  const handleUpdateExemption = async (id, updatedExemption) => {
    try {
      const response = await axiosInstance.put(`/api/exemptions/${id}`, updatedExemption);
      if (response.data.success) {
        // Update the exemption in the list
        setExemptions(exemptions.map(ex => (ex.id === id ? updatedExemption : ex)));
        // Set and clear the success message
        setUpdateSuccess('Exemption updated successfully!');
        setTimeout(() => setUpdateSuccess(''), 3000);
      }
    } catch (error) {
      console.error('Error updating exemption:', error);
    }
  };

  // Function to delete an exemption
  const handleDeleteExemption = async (id) => {
    try {
      const response = await axiosInstance.delete(`/api/exemptions/${id}`);
      if (response.data.success) {
        // Remove the deleted exemption from the list
        setExemptions(exemptions.filter(ex => ex.id !== id));
      }
    } catch (error) {
      console.error('Error deleting exemption:', error);
    }
  };

  // Function to handle input changes for existing exemptions
  const handleExemptionInputChange = (index, field, value) => {
    const updatedExemptions = [...exemptions];
    updatedExemptions[index][field] = value;
    setExemptions(updatedExemptions);
  };

  // Return the state and functions to be used by the component
  return {
    exemptions,
    newExemption,
    setNewExemption,
    handleAddExemption,
    handleUpdateExemption,
    handleDeleteExemption,
    handleExemptionInputChange,
    updateSuccess
  };
};

export default useExemptions;