// src/pages/PropertyTaxCard/components/components/OwnerSearch.js

import React from 'react';

const OwnerSearch = ({ searchTerm, onSearchChange, owners, selectedOwnerIds, onSelectOwner }) => {
    return (
        <div className="owner-search">
            <input
                type="text"
                placeholder="Search Owners..."
                value={searchTerm}
                onChange={onSearchChange}
                className="search-input"
            />
            <div className="owners-list">
                {owners.length > 0 ? (
                    <ul>
                        {owners.map(owner => (
                            <li key={owner.ownerId}>
                                <label>
                                    <input
                                        type="checkbox"
                                        checked={selectedOwnerIds.includes(owner.ownerId)}
                                        onChange={() => onSelectOwner(owner.ownerId)}
                                    />
                                    {owner.firstName} {owner.lastName}
                                </label>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p>No owners found.</p>
                )}
            </div>
        </div>
    );
};

export default OwnerSearch;
