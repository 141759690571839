import { useState, useEffect } from 'react';
import axiosInstance from '../../../axiosInstance';

const useLandUse = () => {
  const [landUses, setLandUses] = useState([]);
  const [newLandUse, setNewLandUse] = useState({ land_use_type: '' });
  const [updateSuccess, setUpdateSuccess] = useState('');

  useEffect(() => {
    fetchLandUses();
  }, []);

  const fetchLandUses = async () => {
    try {
      const response = await axiosInstance.get('/api/land-uses');
      setLandUses(response.data);
    } catch (error) {
      console.error('Error fetching land uses:', error);
    }
  };

  const handleAddLandUse = async () => {
    try {
      const response = await axiosInstance.post('/api/land-uses', newLandUse);
      if (response.data.success) {
        setLandUses([...landUses, { ...newLandUse, id: response.data.id }]);
        setNewLandUse({ land_use_type: '' });
      }
    } catch (error) {
      console.error('Error adding land use:', error);
    }
  };

  const handleUpdateLandUse = async (id, updatedLandUse) => {
    try {
      const response = await axiosInstance.put(`/api/land-uses/${id}`, updatedLandUse);
      if (response.data.success) {
        setLandUses(landUses.map(landUse => (landUse.id === id ? updatedLandUse : landUse)));
        setUpdateSuccess('Land use updated successfully!');
        setTimeout(() => setUpdateSuccess(''), 3000);
      }
    } catch (error) {
      console.error('Error updating land use:', error);
    }
  };

  const handleDeleteLandUse = async (id) => {
    try {
      const response = await axiosInstance.delete(`/api/land-uses/${id}`);
      if (response.data.success) {
        setLandUses(landUses.filter(landUse => landUse.id !== id));
      }
    } catch (error) {
      console.error('Error deleting land use:', error);
    }
  };

  const handleLandUseInputChange = (index, field, value) => {
    const updatedLandUses = [...landUses];
    updatedLandUses[index][field] = value;
    setLandUses(updatedLandUses);
  };

  return {
    landUses,
    newLandUse,
    setNewLandUse,
    handleAddLandUse,
    handleUpdateLandUse,
    handleDeleteLandUse,
    handleLandUseInputChange,
    updateSuccess
  };
};

export default useLandUse;