import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faUser, faSearch } from '@fortawesome/free-solid-svg-icons';
import { jwtDecode } from "jwt-decode";
import './Navbar.css';

const Navbar = ({ toggleSidebar }) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [role, setRole] = useState(null);
    const navigate = useNavigate();
    const dropdownRef = useRef(null);
    const timeoutRef = useRef(null);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            const decodedToken = jwtDecode(token);
            console.log(decodedToken);
            setRole(decodedToken.role); // Assuming the token contains the role
        }
    }, []);

    const handleLogout = () => {
        localStorage.removeItem('token');
        setDropdownOpen(false);
        navigate('/login');
        window.location.reload(); // Force a refresh of the page
    };

    const handleMouseEnter = () => {
        clearTimeout(timeoutRef.current);
        setDropdownOpen(true);
    };

    const handleMouseLeave = () => {
        timeoutRef.current = setTimeout(() => {
            setDropdownOpen(false);
        }, 300); // 300ms delay before closing
    };

    return (
        <div className="navbar">
            <div className="left-section">
                <button
                    className="menu-icon md:hidden"
                    onClick={() => toggleSidebar()}
                >
                    <FontAwesomeIcon icon={faBars} />
                </button>
                <Link to="/account-search" className="search-link">
                    <FontAwesomeIcon icon={faSearch} className="search-icon" />
                </Link>
            </div>
            <div className="title">Property Records</div>
            <div className="right-section">
                <div 
                    className="user-icon-container"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    ref={dropdownRef}
                >
                    <button className="user-icon-button">
                        <FontAwesomeIcon icon={faUser} className="user-icon" />
                    </button>
                    {dropdownOpen && (
                        <div 
                            className="dropdown-menu"
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                        >
                            <Link to="/account-settings" className="dropdown-item">
                                Account Settings
                            </Link>
                            {role === 'admin' && (
                                <Link to="/usermanagement" className="dropdown-item">
                                    User Management
                                </Link>
                            )}
                            <button className="dropdown-item" onClick={handleLogout}>
                                Logout
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Navbar;