import React, { useState, useEffect } from 'react';
import './Modal.css';

const EditInspectionModal = ({ isOpen, onClose, onSave, inspectionData }) => {
  const [editedInspectionData, setEditedInspectionData] = useState({
    date: '',
    description: ''
  });

  useEffect(() => {
    if (inspectionData) {
      setEditedInspectionData(inspectionData);
    }
  }, [inspectionData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedInspectionData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(editedInspectionData);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Edit Inspection</h2>
        <form onSubmit={handleSubmit}>
          <input
            type="date"
            name="date"
            value={editedInspectionData.date || ''}
            onChange={handleChange}
            required
          />
          <textarea
            name="description"
            value={editedInspectionData.description || ''}
            onChange={handleChange}
            placeholder="Description"
            required
          />
          <button type="submit">Save</button>
          <button type="button" onClick={onClose}>Cancel</button>
        </form>
      </div>
    </div>
  );
};

export default EditInspectionModal;