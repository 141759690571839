import { useState, useEffect } from 'react';
import axiosInstance from '../../../axiosInstance';

const useBuildingDataGeneral = (accountNumber) => {
  const [generalInfo, setGeneralInfo] = useState({});
  const [styleOptions, setStyleOptions] = useState([]);
  const [storyHeightOptions, setStoryHeightOptions] = useState([]);
  const [gradeOptions, setGradeOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [stylesResponse, storyHeightsResponse, gradesResponse, generalInfoResponse] = await Promise.all([
          axiosInstance.get('/api/building-data/styles'),
          axiosInstance.get('/api/building-data/story-heights'),
          axiosInstance.get('/api/building-data/grades'),
          axiosInstance.get(`/api/building-data/account/${accountNumber}/building-general-info`),
        ]);
    
        setStyleOptions(stylesResponse.data);
        setStoryHeightOptions(storyHeightsResponse.data);
        setGradeOptions(gradesResponse.data);
        
        const generalInfo = generalInfoResponse.data;
        if (generalInfo && generalInfo.sfArea) {
          const buildingValue = await calculateBuildingValue(generalInfo.sfArea);
          setGeneralInfo({ ...generalInfo, buildingValue });
        } else {
          setGeneralInfo(generalInfo);
        }
        
        setLoading(false);
      } catch (err) {
        console.error('Error fetching building data:', err);
        setError('Error fetching building data');
        setLoading(false);
      }
    };

    fetchData();
  }, [accountNumber]);

  const calculateBuildingValue = async (sfArea) => {
    try {
      const response = await axiosInstance.post(`/api/building-data/account/${accountNumber}/calculate-building-value`, { sfArea });
      const newBuildingValue = parseFloat(response.data.buildingValue);
      setGeneralInfo(prevInfo => ({ ...prevInfo, buildingValue: newBuildingValue }));
      return newBuildingValue;
    } catch (err) {
      console.error('Error calculating building value:', err);
      throw err;
    }
  };

  const saveGeneralInfo = async (data) => {
    try {
      console.log('Saving general info for account:', accountNumber);
      console.log('Data being saved:', data);
      
      const response = await axiosInstance.post(`/api/building-data/account/${accountNumber}/building-general-info`, {
        ...data,
        accountNumber
      });
      
      console.log('Save response:', response.data);
      
      let updatedData = response.data;
      
      // Calculate and update building value if sfArea has changed
      if (data.sfArea !== generalInfo.sfArea) {
        const buildingValue = await calculateBuildingValue(data.sfArea);
        updatedData = { ...updatedData, buildingValue };
      }
      
      setGeneralInfo(updatedData);
      
      return updatedData;
    } catch (err) {
      console.error('Error saving building general info:', err);
      setError('Error saving building general info');
      throw err;
    }
  };

  return {
    generalInfo,
    styleOptions,
    storyHeightOptions,
    gradeOptions,
    loading,
    error,
    saveGeneralInfo,
    calculateBuildingValue
  };
};

export default useBuildingDataGeneral;