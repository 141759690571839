import React from 'react';
import './Interior.css';

const Interior = ({ data, onUpdate }) => {
  const handleInputChange = (field, value) => {
    onUpdate({ ...data, [field]: value });
  };

  const renderDropdown = (field, label, options) => (
    <div className="form-group">
      <label htmlFor={field}>{label}:</label>
      <select
        id={field}
        value={data[field] || ''}
        onChange={(e) => handleInputChange(field, e.target.value)}
      >
        <option value="">Select {label}</option>
        {options.map(option => (
          <option key={option} value={option}>{option}</option>
        ))}
      </select>
    </div>
  );

  const renderInput = (field, label, type = "text") => (
    <div className="form-group">
      <label htmlFor={field}>{label}:</label>
      <input
        type={type}
        id={field}
        value={data[field] || ''}
        onChange={(e) => handleInputChange(field, e.target.value)}
      />
    </div>
  );

  const stories = ['Basement', '1st', '2nd', '3rd', '4th'];
  const finishOptions = ['Unfinished', 'Semi-Finished', 'Finished'];
  const conditionOptions = ['Poor', 'Fair', 'Average', 'Good', 'Excellent'];

  return (
    <div className="interior">
      <h3>Interior Information</h3>
      
      <div className="interior-grid">
        <div className="interior-section floors-finish">
          <h4>Floors & Finish</h4>
          <table>
            <thead>
              <tr>
                <th>Story</th>
                <th>Floor Type</th>
                <th>Wall Type</th>
                <th>Ceiling Type</th>
                <th>Finish</th>
              </tr>
            </thead>
            <tbody>
              {stories.map(story => (
                <tr key={story}>
                  <td>{story}</td>
                  <td>{renderDropdown(`${story.toLowerCase()}FloorType`, 'Floor Type', ['Hardwood', 'Carpet', 'Tile', 'Concrete'])}</td>
                  <td>{renderDropdown(`${story.toLowerCase()}WallType`, 'Wall Type', ['Drywall', 'Plaster', 'Paneling'])}</td>
                  <td>{renderDropdown(`${story.toLowerCase()}CeilingType`, 'Ceiling Type', ['Flat', 'Vaulted', 'Drop'])}</td>
                  <td>{renderDropdown(`${story.toLowerCase()}Finish`, 'Finish', finishOptions)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="interior-section condition">
          <h4>Interior Condition</h4>
          {stories.map(story => (
            <div key={story} className="condition-row">
              <span className="story-label">{story}:</span>
              {renderDropdown(`${story.toLowerCase()}Condition`, 'Condition', conditionOptions)}
            </div>
          ))}
        </div>

        <div className="interior-section fireplaces">
          <h4>Fireplaces</h4>
          {renderInput('numFireplaces', 'Number of Fireplaces', 'number')}
          {renderDropdown('fireplaceType', 'Fireplace Type', ['Wood Burning', 'Gas', 'Electric', 'None'])}
        </div>

        <div className="interior-section hearths-prefabs">
          <h4>Hearths & Prefabs</h4>
          {renderDropdown('hearthType', 'Hearth Type', ['Brick', 'Stone', 'Tile', 'None'])}
          {renderDropdown('prefabType', 'Prefab Type', ['Metal', 'Brick', 'Stone', 'None'])}
        </div>
      </div>
    </div>
  );
};

export default Interior;