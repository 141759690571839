import React, { useEffect, useState } from 'react';
import './GeneralInfo.css';
import useBuildingDataGeneral from '../../hooks/useBuildingDataGeneral';

const GeneralInfo = ({ accountNumber, onUpdate }) => {
  const {
    generalInfo,
    styleOptions,
    storyHeightOptions,
    gradeOptions,
    loading,
    error,
    saveGeneralInfo,
    calculateBuildingValue
  } = useBuildingDataGeneral(accountNumber);

  const [localData, setLocalData] = useState({});

  useEffect(() => {
    if (generalInfo && Object.keys(generalInfo).length > 0) {
      setLocalData(generalInfo);
    }
  }, [generalInfo]);

  const handleInputChange = (field, value) => {
    setLocalData(prevData => ({ ...prevData, [field]: value }));
  };

  const handleGradeChange = (value) => {
    const selectedGrade = gradeOptions.find(option => option.id === parseInt(value));
    const factor = selectedGrade ? selectedGrade.factor : '';
    setLocalData(prevData => ({ ...prevData, grade: value, factor }));
  };

  const handleSave = async () => {
    try {
      console.log('Saving data for account:', accountNumber);
      const savedData = await saveGeneralInfo({ ...localData, accountNumber });
      
      if (savedData.sfArea !== localData.sfArea) {
        const newBuildingValue = await calculateBuildingValue(savedData.sfArea);
        console.log('New building value:', newBuildingValue);
        onUpdate({ ...savedData, buildingValue: newBuildingValue });
      } else {
        onUpdate(savedData);
      }
      
      alert('Building General Info saved successfully.');
    } catch (err) {
      console.error('Error saving Building General Info:', err);
      alert('Error saving Building General Info.');
    }
  };

  const renderDropdown = (field, label, options, displayValue) => (
    <div className="form-group">
      <label htmlFor={field}>{label}:</label>
      <select
        id={field}
        value={localData[field] || ''}
        onChange={(e) => {
          if (field === 'grade') {
            handleGradeChange(e.target.value);
          } else {
            handleInputChange(field, e.target.value);
          }
        }}
      >
        <option value="">Select {label}</option>
        {options.map(option => (
          <option key={option.id} value={option.id}>
            {displayValue(option)}
          </option>
        ))}
      </select>
    </div>
  );

  const renderInput = (field, label, type = 'text', readOnly = false) => (
    <div className="form-group">
      <label htmlFor={field}>{label}:</label>
      <input
        type={type}
        id={field}
        value={localData[field] || ''}
        onChange={(e) => handleInputChange(field, e.target.value)}
        readOnly={readOnly}
      />
    </div>
  );

  if (loading) return <div>Loading General Information...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="general-info">
      <h3>General Building Information for Account: {accountNumber}</h3>

      <div className="general-info-grid">
        <div className="general-info-section basic-info">
          <h4>Basic Information</h4>
          {renderDropdown('style', 'Style', styleOptions, option => option.style_name)}
          {renderInput('yearBuilt', 'Year Built', 'number')}
          {renderInput('yearRemodeled', 'Year Remodeled', 'number')}
        </div>

        <div className="general-info-section size-info">
          <h4>Size Information</h4>
          {renderInput('sfArea', 'SF Area', 'number')}
          {renderDropdown('storyHeight', 'Story Height', storyHeightOptions, option => `${option.story_height} Story`)}
        </div>

        <div className="general-info-section room-info">
          <h4>Room Information</h4>
          {renderInput('numRooms', 'Number of Rooms (Excluding Baths)', 'number')}
          {renderInput('numBaths', 'Number of Baths', 'number')}
        </div>

        <div className="general-info-section grade-info">
          <h4>Grade and Factor</h4>
          {renderDropdown('grade', 'Grade', gradeOptions, option => `${option.grade} ${option.grade_modifier}`)}
          {renderInput('factor', 'Factor', 'text', true)}
        </div>
      </div>
      
      <button onClick={handleSave}>Save</button>
    </div>
  );
};

export default GeneralInfo;
