import React, { useState, useEffect } from 'react';
import './Structure.css';
import useBuildingDataStructure from '../../hooks/useBuildingDataStructure';

const Structure = ({ accountNumber }) => {
  const {
    structureData,
    foundationOptions,
    conditionOptions,
    basementOptions,
    exteriorWallOptions,
    roofingOptions,
    roofTypeOptions,
    loading,
    error,
    saveStructureData
  } = useBuildingDataStructure(accountNumber);

  const [data, setData] = useState({});
  const [saveError, setSaveError] = useState(null);

  useEffect(() => {
    if (structureData && Object.keys(structureData).length > 0) {
      setData(structureData);
    }
  }, [structureData]);

  const handleInputChange = (field, value) => {
    setData(prevData => ({ ...prevData, [field]: value }));
  };

  const handleSave = async () => {
    try {
      const savedData = await saveStructureData(data);
      setData(savedData);
      setSaveError(null);
      alert('Building Structure saved successfully.');
    } catch (err) {
      setSaveError('Failed to save structure data. Please try again.');
    }
  };

  const renderDropdown = (field, label, options, valueKey = 'id', displayKey = 'type_name') => (
    <div className="form-group">
      <label htmlFor={field}>{label}:</label>
      <select
        id={field}
        value={data[field] || ''}
        onChange={(e) => handleInputChange(field, e.target.value)}
      >
        <option value="">Select {label}</option>
        {console.log(`Rendering options for ${field}:`, options)}
        {options && options.length > 0 ? (
          options.map(option => (
            <option key={option[valueKey]} value={option[valueKey]}>
              {option[displayKey] || option.name || option.type_name || 'Unknown'}
            </option>
          ))
        ) : (
          <option value="">No options available</option>
        )}
      </select>
    </div>
  );

  const renderInput = (field, label) => (
    <div className="form-group">
      <label htmlFor={field}>{label}:</label>
      <input
        type="text"
        id={field}
        value={data[field] || ''}
        onChange={(e) => handleInputChange(field, e.target.value)}
      />
    </div>
  );

  if (loading) return <div>Loading Structure Data...</div>;
  if (error) return <div>Error loading structure data: {error}</div>;

  return (
    <div className="structure">
      <h3>Structure Information</h3>

      {saveError && <div className="error-message">{saveError}</div>}

      <div className="structure-grid">
      <div className="structure-section foundation-section">
        <h4>Foundation & Basement</h4>
        {renderDropdown('foundation', 'Foundation', foundationOptions)}
        {renderDropdown('foundationCondition', 'Foundation Condition', conditionOptions, 'id', 'name')}
        {renderDropdown('basement', 'Basement', basementOptions)}
        {renderDropdown('basementCondition', 'Basement Condition', conditionOptions, 'id', 'name')}
        {renderDropdown('basementGarage', 'Basement Garage', [{ id: 'Yes', name: 'Yes' }, { id: 'No', name: 'No' }], 'id', 'name')}
      </div>

        <div className="structure-section exterior-section">
          <h4>Exterior</h4>
          {renderDropdown('exteriorWalls', 'Exterior Walls', exteriorWallOptions)}
          {renderDropdown('exteriorWallCondition', 'Exterior Wall Condition', conditionOptions)}
          {renderDropdown('facebrickOnFrame', 'Facebrick on Frame', [{ id: 'Yes', name: 'Yes' }, { id: 'No', name: 'No' }])}
        </div>

        <div className="structure-section roof-section">
          <h4>Roof</h4>
          {renderDropdown('roofingType', 'Roofing Type', roofingOptions)}
          {renderDropdown('roofingCondition', 'Roofing Condition', conditionOptions)}
          {renderDropdown('roofType', 'Roof Type', roofTypeOptions)}
        </div>

        <div className="structure-section framing-section">
          <h4>Framing</h4>
          {renderInput('joistFraming', 'Joist Framing')}
          {renderInput('wallFraming', 'Wall Framing')}
        </div>

        <div className="structure-section other-section">
          <h4>Other</h4>
          {renderDropdown('atticFloorStairs', 'Attic Floor & Stairs', [{ id: 'Finished', name: 'Finished' }, { id: 'Unfinished', name: 'Unfinished' }, { id: 'No Attic', name: 'No Attic' }])}
          {renderDropdown('insulation', 'Insulation', [{ id: 'Excellent', name: 'Excellent' }, { id: 'Good', name: 'Good' }, { id: 'Fair', name: 'Fair' }, { id: 'Poor', name: 'Poor' }, { id: 'None', name: 'None' }])}
        </div>
      </div>

      <button onClick={handleSave} disabled={loading || error}>
        {loading ? 'Saving...' : 'Save'}
      </button>
    </div>
  );
};

export default Structure;