import axiosInstance from '../axiosInstance';
import getApiUrl from '../config';

const apiUrl = getApiUrl();

export const isTokenValid = async () => {
    const token = localStorage.getItem('token');
    if (!token) return false;

    try {
        const response = await axiosInstance.get(`${apiUrl}/auth/validate-token`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return response.status === 200;
    } catch (error) {
        console.error('Token validation error:', error);
        return false;
    }
};

export const login = async (username, password) => {
    try {
      console.log('Attempting to login with:', { username, password });
      console.log('API URL:', `${getApiUrl()}/auth/login`);
      const response = await axiosInstance.post(`${getApiUrl()}/auth/login`, {
        username,
        password
      });
      console.log('Login response:', response);
        if (response.status === 200 && response.data.accessToken) {
            localStorage.setItem('token', response.data.accessToken);
            console.log('Token set:', response.data.accessToken);
            return true;
        } else {
            console.error('Login failed:', response.data.message);
            return false;
        }
    } catch (error) {
        console.error('Login error:', error.response ? error.response.data : error.message);
        return false;
      }
    };
    
export const logout = () => {
    localStorage.removeItem('token');
};
