import React from 'react';
import './DeleteConfirmationModal.css';

const DeleteConfirmationModal = ({ isOpen, onClose, onConfirm, itemName }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Confirm Deletion</h2>
        <p>Are you sure you want to delete this {itemName}?</p>
        <div className="form-group button-group">
          <button type="button" onClick={onClose} className="close-button">Cancel</button>
          <button type="button" onClick={onConfirm} className="delete-button">Delete</button>
        </div>
      </div>
    </div>
  );
};

export default DeleteConfirmationModal;