import React, { useState, useEffect, useMemo } from 'react';
import { Rnd } from 'react-rnd';
import './PropertyOverview.css';
import AddCurrentOwnerModal from '../modals/AddCurrentOwnerModal';
import EditCurrentOwnerModal from '../modals/EditCurrentOwnerModal';
import ConfirmationModal from '../components/ConfirmationModal';
import AddSaleModal from '../modals/AddSaleModal'; // Import the AddSaleModal
import axiosInstance from '../../../axiosInstance';
import getApiUrl from '../../../config';
import { toast } from 'react-toastify';
import AddPreviousOwnerModal from '../modals/AddPreviousOwnerModal';
import EditPreviousOwnerModal from '../modals/EditPreviousOwnerModal';

const PropertyOverview = ({ accountNumber }) => {
  
  const [isEditPreviousOwnerModalOpen, setIsEditPreviousOwnerModalOpen] = useState(false);
  const [selectedPreviousOwner, setSelectedPreviousOwner] = useState(null);
  const [isAddSaleModalOpen, setIsAddSaleModalOpen] = useState(false);

  // State for Add Current Owner Modal
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);

  // State for Add Previous Owner Modal
  const [isAddPreviousOwnerModalOpen, setIsAddPreviousOwnerModalOpen] = useState(false);

  // State for Edit Current Owner Modal
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  // State to control Confirmation Modal
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  // State to indicate if we're replacing owners
  const [isReplacingOwners, setIsReplacingOwners] = useState(false);

  // Existing states...
  const [owners, setOwners] = useState([]);
  const [currentOwners, setCurrentOwners] = useState([]);
  const [previousOwners, setPreviousOwners] = useState([]);
  const [assessmentHistory, setAssessmentHistory] = useState([]);
  const [exemptions, setExemptions] = useState([]);

  // Utility function to format date to MM/DD/YYYY
  const formatDate = (dateString) => {
    const options = { month: '2-digit', day: '2-digit', year: 'numeric' };
    const date = new Date(dateString);
    return isNaN(date) ? dateString : date.toLocaleDateString(undefined, options);
  };

  // Fetch functions...
  const fetchAllOwners = async () => {
    const apiUrl = getApiUrl();
    try {
      const response = await axiosInstance.get(`${apiUrl}/api/properties/${accountNumber}/current-owners`);
      setOwners(response.data);
      const current = response.data.filter(owner => owner.is_current);
      const previous = response.data.filter(owner => !owner.is_current);
      setCurrentOwners(current);
      setPreviousOwners(previous);
    } catch (error) {
      console.error('Error fetching owners:', error);
      toast.error('Failed to fetch owners.');
    }
  };

  const fetchAssessmentHistory = async () => {
    const apiUrl = getApiUrl();
    try {
      const response = await axiosInstance.get(`${apiUrl}/api/properties/${accountNumber}/assessment-history`);
      setAssessmentHistory(response.data);
    } catch (error) {
      console.error('Error fetching assessment history:', error);
      toast.error('Failed to fetch assessment history.');
    }
  };

  const fetchExemptions = async () => {
    const apiUrl = getApiUrl();
    try {
      const response = await axiosInstance.get(`${apiUrl}/api/properties/${accountNumber}/exemptions`);
      setExemptions(response.data);
    } catch (error) {
      console.error('Error fetching exemptions:', error);
      toast.error('Failed to fetch exemptions.');
    }
  };

  // Fetch data on mount or when accountNumber changes
  useEffect(() => {
    fetchAllOwners();
    fetchAssessmentHistory();
    fetchExemptions();
  }, [accountNumber]);

  // Handle owner addition or removal
  const handleOwnerUpdated = () => {
    fetchAllOwners();
  };

  // Memoize existing deed information
  const existingDeedInfo = useMemo(() => {
    if (currentOwners.length > 0) {
      return {
        deedBook: currentOwners[0].deedBook,
        deedPage: currentOwners[0].deedPage,
        deedDate: currentOwners[0].deedDate,
      };
    } else {
      return {
        deedBook: '',
        deedPage: '',
        deedDate: '',
      };
    }
  }, [currentOwners]);

  // Existing box states and functions...
  const [boxes, setBoxes] = useState({
    currentOwners: { x: 8, y: 8, width: 804, height: 437 },
    exemptions: { x: 821, y: 8, width: 680, height: 300 },
    previousOwners: { x: 8, y: 453, width: 805, height: 437 },
    assessmentRecord: { x: 821, y: 316, width: 680, height: 574 },
  });

  const updateBox = (id, newPos) => {
    setBoxes(prev => ({
      ...prev,
      [id]: { ...prev[id], ...newPos }
    }));
  };

  const renderBox = (id, content) => (
    <Rnd
      key={id}
      size={{ width: boxes[id].width, height: boxes[id].height }}
      position={{ x: boxes[id].x, y: boxes[id].y }}
      onDragStop={(e, d) => updateBox(id, { x: d.x, y: d.y })}
      onResizeStop={(e, direction, ref, delta, position) => {
        updateBox(id, {
          width: ref.offsetWidth,
          height: ref.offsetHeight,
          ...position,
        });
      }}
      bounds="parent"
    >
      {content}
    </Rnd>
  );

  // Handlers for Add and Edit modals
  const handleAddCurrentOwner = () => {
    setIsAddModalOpen(true);
  };

  // Open Add Previous Owner Modal
  const handleAddPreviousOwner = () => {
    setIsAddPreviousOwnerModalOpen(true);
  };

  const handleEditPreviousOwner = (owner) => {
    setSelectedPreviousOwner(owner); // Store the selected owner to edit
    setIsEditPreviousOwnerModalOpen(true);
  };

  const handleDeletePreviousOwner = async (owner) => {
    if (window.confirm(`Are you sure you want to delete the previous owner ${owner.firstName} ${owner.lastName}?`)) {
      try {
        const apiUrl = getApiUrl();
        const response = await axiosInstance.delete(`${apiUrl}/api/properties/${accountNumber}/previous-owners/${owner.ownerId}`);
        
        if (response.data.success) {
          toast.success('Previous owner deleted successfully.');
          fetchAllOwners(); // Refresh the owners list
        } else {
          toast.error(response.data.error || 'Failed to delete previous owner.');
        }
      } catch (error) {
        console.error('Error deleting previous owner:', error);
        toast.error('Failed to delete previous owner. Please try again.');
      }
    }
  };

  const handleEditOwners = () => {
    setIsEditModalOpen(true);
  };

  // Handler for "New Owner (Property Sold)" button
  const handleNewOwnerClick = () => {
    setIsConfirmationModalOpen(true);
  };

  // Function to handle confirmation
  const handleConfirmSale = () => {
    setIsConfirmationModalOpen(false);
    setIsAddSaleModalOpen(true); // Open the AddSaleModal
  };

    // Function to handle sale data submission
    const handleSaleSubmit = async (saleData) => {
      const apiUrl = getApiUrl();
      try {
        console.log('Submitting sale data:', saleData);
        
        // Update this line to match your backend route
        const response = await axiosInstance.post(`${apiUrl}/api/property-data/account/${accountNumber}/sales`, saleData);
        
        console.log('Sale data submission response:', response.data);
        
        if (response.data.success) {
          toast.success('Sale data added successfully.');
          setIsAddSaleModalOpen(false);
          
          // Continue with the process of adding new owners
          setIsReplacingOwners(true);
          setIsEditModalOpen(true);
        } else {
          console.error('Server responded with an error:', response.data);
          toast.error(response.data.error || 'Failed to add sale data. Please check the console for more details.');
        }
      } catch (error) {
        console.error('Error adding sale data:', error);
        if (error.response) {
          console.error('Error response data:', error.response.data);
          console.error('Error response status:', error.response.status);
          console.error('Error response headers:', error.response.headers);
          toast.error(`Failed to add sale data: ${error.response.data.error || error.response.statusText}`);
        } else if (error.request) {
          console.error('Error request:', error.request);
          toast.error('No response received from server. Please check your network connection.');
        } else {
          console.error('Error message:', error.message);
          toast.error(`Error setting up request: ${error.message}`);
        }
      }
    };

  // Function to handle cancellation
  const handleCancelSale = () => {
    setIsConfirmationModalOpen(false);
  };

  return (
    <div className="property-overview">
      {renderBox('currentOwners',
        <div className="current-owners">
          <h3>Current Owner(s)</h3>
          {currentOwners.length > 0 ? (
            <>
              <div className="owners-list">
                <table className="owners-table">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Address</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentOwners.map((owner) => (
                      <tr key={owner.ownerId}>
                        <td>{owner.firstName} {owner.lastName}</td>
                        <td>
                          {owner.address && owner.city && owner.state_province && owner.zip_postal_code
                            ? `${owner.address}, ${owner.city}, ${owner.state_province} ${owner.zip_postal_code}`
                            : owner.address || 'Address not available'}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="deed-info">
                <p>
                  <strong>Deed Book:</strong> {currentOwners[0].deedBook} &nbsp;
                  <strong>Deed Page:</strong> {currentOwners[0].deedPage} &nbsp;
                  <strong>Deed Date:</strong> {formatDate(currentOwners[0].deedDate)}
                </p>
              </div>
            </>
          ) : (
            <p>No current owners available.</p>
          )}
          {/* Add the buttons */}
          <div className="button-group">
            <button onClick={handleAddCurrentOwner}>Add Current Owner</button>
            <button onClick={handleEditOwners}>Edit Current Owner/Deed Info</button>
            <button onClick={handleNewOwnerClick}>New Owner (Property Sold)</button>
          </div>
        </div>
      )}

      {renderBox('exemptions',
        <div className="exemptions">
          <h3>Exemptions</h3>
          {exemptions && exemptions.length > 0 ? (
            <table className="exemptions-table">
              <thead>
                <tr>
                  <th>Type</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {exemptions.map((exemption, index) => (
                  <tr key={index}>
                    <td>{exemption.type}</td>
                    <td>${exemption.amount.toLocaleString()}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>No exemptions available.</p>
          )}
        </div>
      )}

{renderBox('previousOwners',
        <div className="previous-owners">
          <h3>Previous Owners</h3>
          {previousOwners.length > 0 ? (
            <table className="previous-owners-table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Deed Book</th>
                  <th>Deed Page</th>
                  <th>Deed Date</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {previousOwners.map((owner) => (
                  <tr key={owner.ownerId}>
                    <td>{owner.firstName} {owner.lastName}</td>
                    <td>{owner.deedBook}</td>
                    <td>{owner.deedPage}</td>
                    <td>{formatDate(owner.deedDate)}</td>
                    <td>
                      <button onClick={() => handleEditPreviousOwner(owner)}>
                        Edit
                      </button>
                      <button onClick={() => handleDeletePreviousOwner(owner)} style={{marginLeft: '5px'}}>
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>No previous owners available.</p>
          )}
          <button onClick={handleAddPreviousOwner}>Add Previous Owner</button>
        </div>
      )}

      {renderBox('assessmentRecord',
        <div className="assessment-record">
          <h3>Assessment Record</h3>
          <div className="table-container">
            <table className="assessment-record-table">
              <thead>
                <tr>
                  <th>Year</th>
                  <th>Land Value</th>
                  <th>Building Value</th>
                  <th>Exemptions</th>
                  <th>Total Value</th>
                </tr>
              </thead>
              <tbody>
                {assessmentHistory.map((record, index) => (
                  <tr key={index}>
                    <td>{record.year}</td>
                    <td>${record.landValue.toLocaleString()}</td>
                    <td>${record.buildingValue.toLocaleString()}</td>
                    <td>${record.exemptions.toLocaleString()}</td>
                    <td>${record.totalValue.toLocaleString()}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}

      {/* Add and Edit Modals */}
      <AddCurrentOwnerModal
        isOpen={isAddModalOpen}
        onClose={() => setIsAddModalOpen(false)}
        accountNumber={accountNumber}
        onOwnerAdded={handleOwnerUpdated}
        replaceOwners={false} // Keep as is for Add Current Owner functionality
        existingDeedInfo={existingDeedInfo}
      />

      {/* Add Sale Modal */}
      <AddSaleModal
        isOpen={isAddSaleModalOpen}
        onClose={() => setIsAddSaleModalOpen(false)}
        onSave={handleSaleSubmit}
      />

      {/* Edit Current Owner Modal */}
      <EditCurrentOwnerModal
        isOpen={isEditModalOpen}
        onClose={() => {
          setIsEditModalOpen(false);
          setIsReplacingOwners(false);
        }}
        accountNumber={accountNumber}
        onOwnerUpdated={handleOwnerUpdated}
        existingOwners={currentOwners}
        existingDeedInfo={existingDeedInfo}
        isReplacingOwners={isReplacingOwners}
      />

<EditPreviousOwnerModal
        isOpen={isEditPreviousOwnerModalOpen}
        onClose={() => {
          setIsEditPreviousOwnerModalOpen(false);
          setSelectedPreviousOwner(null);
        }}
        accountNumber={accountNumber}
        owner={selectedPreviousOwner}
        onOwnerUpdated={handleOwnerUpdated}
        originalDeedInfo={selectedPreviousOwner ? {
          deedBook: selectedPreviousOwner.deedBook,
          deedPage: selectedPreviousOwner.deedPage,
          deedDate: selectedPreviousOwner.deedDate,
        } : null}
      />

      {/* Confirmation Modal */}
      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        onClose={handleCancelSale}
        onConfirm={handleConfirmSale}
        title="Confirm Property Sale"
        message="Has the property been sold?"
      />

      {/* Add Previous Owner Modal */}
      <AddPreviousOwnerModal
        isOpen={isAddPreviousOwnerModalOpen}
        onClose={() => setIsAddPreviousOwnerModalOpen(false)}
        accountNumber={accountNumber}
        onOwnerAdded={handleOwnerUpdated}
      />
    </div>
  );
};

export default PropertyOverview;
