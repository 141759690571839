import React from 'react';
import './Outbuildings.css';

const Outbuildings = ({ data, onUpdate }) => {
  const handleInputChange = (index, field, value) => {
    const updatedOutbuildings = [...data];
    updatedOutbuildings[index][field] = value;
    onUpdate(updatedOutbuildings);
  };

  const addOutbuilding = () => {
    const newOutbuilding = {
      type: '',
      construction: '',
      floorType: '',
      age: '',
      size: '',
      condition: '',
      grade: '',
      replacementValue: '',
      obsolescence: '',
      soundValue: ''
    };
    onUpdate([...data, newOutbuilding]);
  };

  const removeOutbuilding = (index) => {
    const updatedOutbuildings = data.filter((_, i) => i !== index);
    onUpdate(updatedOutbuildings);
  };

  const renderDropdown = (index, field, label, options) => (
    <select
      className={`outbuilding-select outbuilding-${field}`}
      value={data[index][field] || ''}
      onChange={(e) => handleInputChange(index, field, e.target.value)}
    >
      <option value="">Select {label}</option>
      {options.map(option => (
        <option key={option} value={option}>{option}</option>
      ))}
    </select>
  );

  const renderInput = (index, field, type = "text") => (
    <input
      className={`outbuilding-input outbuilding-${field}`}
      type={type}
      value={data[index][field] || ''}
      onChange={(e) => handleInputChange(index, field, e.target.value)}
    />
  );

  return (
    <div className="outbuildings">
      <h3>Outbuildings</h3>
      <div className="outbuildings-table-container">
        <table className="outbuildings-table">
          <thead>
            <tr>
              <th className="outbuilding-type">Type</th>
              <th className="outbuilding-construction">Construction</th>
              <th className="outbuilding-floorType">Floor Type</th>
              <th className="outbuilding-age">Age</th>
              <th className="outbuilding-size">Size</th>
              <th className="outbuilding-condition">Condition</th>
              <th className="outbuilding-grade">Grade</th>
              <th className="outbuilding-replacementValue">Replacement Value</th>
              <th className="outbuilding-obsolescence">Obsolescence %</th>
              <th className="outbuilding-soundValue">Sound Value</th>
              <th className="outbuilding-action">Action</th>
            </tr>
          </thead>
          <tbody>
            {data.map((outbuilding, index) => (
              <tr key={index}>
                <td>{renderDropdown(index, 'type', 'Type', ['Garage', 'Shed', 'Barn', 'Pool'])}</td>
                <td>{renderDropdown(index, 'construction', 'Construction', ['Wood', 'Brick', 'Metal', 'Concrete'])}</td>
                <td>{renderDropdown(index, 'floorType', 'Floor Type', ['Concrete', 'Wood', 'Dirt', 'Gravel'])}</td>
                <td>{renderInput(index, 'age', 'number')}</td>
                <td>{renderInput(index, 'size', 'number')}</td>
                <td>{renderDropdown(index, 'condition', 'Condition', ['Excellent', 'Good', 'Average', 'Fair', 'Poor'])}</td>
                <td>{renderDropdown(index, 'grade', 'Grade', ['A', 'B', 'C', 'D', 'E'])}</td>
                <td>{renderInput(index, 'replacementValue', 'number')}</td>
                <td>{renderInput(index, 'obsolescence', 'number')}</td>
                <td>{renderInput(index, 'soundValue', 'number')}</td>
                <td>
                  <button onClick={() => removeOutbuilding(index)} className="remove-btn">Remove</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <button onClick={addOutbuilding} className="add-btn">Add Outbuilding</button>
    </div>
  );
};

export default Outbuildings;