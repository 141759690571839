import { useState, useEffect } from 'react';
import axiosInstance from '../../../axiosInstance';

const useStreetType = () => {
  const [streetTypes, setStreetTypes] = useState([]);
  const [newStreetType, setNewStreetType] = useState({ street_type: '' });
  const [updateSuccess, setUpdateSuccess] = useState('');

  useEffect(() => {
    fetchStreetTypes();
  }, []);

  const fetchStreetTypes = async () => {
    try {
      const response = await axiosInstance.get('/api/street-types');
      setStreetTypes(response.data);
    } catch (error) {
      console.error('Error fetching street types:', error);
    }
  };

  const handleAddStreetType = async () => {
    try {
      const response = await axiosInstance.post('/api/street-types', newStreetType);
      if (response.data.success) {
        setStreetTypes([...streetTypes, { ...newStreetType, id: response.data.id }]);
        setNewStreetType({ street_type: '' });
      }
    } catch (error) {
      console.error('Error adding street type:', error);
    }
  };

  const handleUpdateStreetType = async (id, updatedStreetType) => {
    try {
      const response = await axiosInstance.put(`/api/street-types/${id}`, updatedStreetType);
      if (response.data.success) {
        setStreetTypes(streetTypes.map(type => (type.id === id ? updatedStreetType : type)));
        setUpdateSuccess('Street type updated successfully!');
        setTimeout(() => setUpdateSuccess(''), 3000);
      }
    } catch (error) {
      console.error('Error updating street type:', error);
    }
  };

  const handleDeleteStreetType = async (id) => {
    try {
      const response = await axiosInstance.delete(`/api/street-types/${id}`);
      if (response.data.success) {
        setStreetTypes(streetTypes.filter(type => type.id !== id));
      }
    } catch (error) {
      console.error('Error deleting street type:', error);
    }
  };

  const handleStreetTypeInputChange = (index, field, value) => {
    const updatedStreetTypes = [...streetTypes];
    updatedStreetTypes[index][field] = value;
    setStreetTypes(updatedStreetTypes);
  };

  return {
    streetTypes,
    newStreetType,
    setNewStreetType,
    handleAddStreetType,
    handleUpdateStreetType,
    handleDeleteStreetType,
    handleStreetTypeInputChange,
    updateSuccess
  };
};

export default useStreetType;