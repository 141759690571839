import { useState, useEffect } from 'react';
import axiosInstance from '../../../axiosInstance';

const useBuildingDataStructure = (accountNumber) => {
    const [structureData, setStructureData] = useState({});
    const [foundationOptions, setFoundationOptions] = useState([]);
    const [conditionOptions, setConditionOptions] = useState([]);
    const [basementOptions, setBasementOptions] = useState([]);
    const [exteriorWallOptions, setExteriorWallOptions] = useState([]);
    const [roofingOptions, setRoofingOptions] = useState([]);
    const [roofTypeOptions, setRoofTypeOptions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
  
    useEffect(() => {
        const fetchData = async () => {
          try {
            const [
              foundationResponse,
              conditionResponse,
              basementResponse,
              exteriorWallResponse,
              roofingResponse,
              roofTypeResponse,
              structureResponse
            ] = await Promise.all([
              axiosInstance.get('/api/building-data/foundation-options').catch(err => {
                console.error('Error fetching foundation options:', err);
                return { data: [] };
              }),
              axiosInstance.get('/api/building-data/condition-options').catch(err => {
                console.error('Error fetching condition options:', err);
                return { data: [] };
              }),
              axiosInstance.get('/api/building-data/basement-types').catch(err => {
                console.error('Error fetching basement types:', err);
                return { data: [] };
              }),
              axiosInstance.get('/api/building-data/exterior-wall-types').catch(err => {
                console.error('Error fetching exterior wall types:', err);
                return { data: [] };
              }),
              axiosInstance.get('/api/building-data/roofing-types').catch(err => {
                console.error('Error fetching roofing types:', err);
                return { data: [] };
              }),
              axiosInstance.get('/api/building-data/roof-types').catch(err => {
                console.error('Error fetching roof types:', err);
                return { data: [] };
              }),
              axiosInstance.get(`/api/building-data/account/${accountNumber}/building-structure`).catch(err => {
                console.error('Error fetching building structure:', err);
                return { data: {} };
              })
            ]);
      
            console.log('Foundation Options:', foundationResponse.data);
            console.log('Condition Options:', conditionResponse.data);
            console.log('Basement Options:', basementResponse.data);
            console.log('Exterior Wall Options:', exteriorWallResponse.data);
            console.log('Roofing Options:', roofingResponse.data);
            console.log('Roof Type Options:', roofTypeResponse.data);
            console.log('Structure Data:', structureResponse.data);
      
            setFoundationOptions(foundationResponse.data);
            setConditionOptions(conditionResponse.data);
            setBasementOptions(basementResponse.data);
            setExteriorWallOptions(exteriorWallResponse.data);
            setRoofingOptions(roofingResponse.data);
            setRoofTypeOptions(roofTypeResponse.data);
      
            if (structureResponse.data && Object.keys(structureResponse.data).length > 0) {
              setStructureData(structureResponse.data);
            } else {
              setStructureData({});
            }
      
            setLoading(false);
          } catch (err) {
            console.error('Error fetching building structure data:', err);
            setError('Error fetching building structure data');
            setLoading(false);
          }
        };
      
        fetchData();
    }, [accountNumber]);        
  
    const saveStructureData = async (data) => {
        try {
          setLoading(true);
          const response = await axiosInstance.post(`/api/building-data/account/${accountNumber}/building-structure`, data);
          console.log('Structure data saved:', response.data);
          setStructureData(response.data);
          setLoading(false);
          return response.data;
        } catch (err) {
          console.error('Error saving building structure data:', err);
          setError('Error saving building structure data');
          setLoading(false);
          throw err;
        }
    };

    return {
      structureData,
      foundationOptions,
      conditionOptions,
      basementOptions,
      exteriorWallOptions,
      roofingOptions,
      roofTypeOptions,
      loading,
      error,
      saveStructureData
    };
};

export default useBuildingDataStructure;