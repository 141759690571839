import { useState, useEffect } from 'react';
import axiosInstance from '../../../axiosInstance';

const useExteriorWallTypes = () => {
  const [exteriorWallTypes, setExteriorWallTypes] = useState([]);
  const [newExteriorWallType, setNewExteriorWallType] = useState({ type_name: '' });
  const [updateSuccess, setUpdateSuccess] = useState('');

  useEffect(() => {
    fetchExteriorWallTypes();
  }, []);

  const fetchExteriorWallTypes = async () => {
    try {
      const response = await axiosInstance.get('/api/exterior-wall-types');
      setExteriorWallTypes(response.data);
    } catch (error) {
      console.error('Error fetching exterior wall types:', error);
    }
  };

  const handleAddExteriorWallType = async () => {
    try {
      const response = await axiosInstance.post('/api/exterior-wall-types', newExteriorWallType);
      if (response.data.success) {
        setExteriorWallTypes([...exteriorWallTypes, { ...newExteriorWallType, id: response.data.id }]);
        setNewExteriorWallType({ type_name: '' });
      }
    } catch (error) {
      console.error('Error adding exterior wall type:', error);
    }
  };

  const handleUpdateExteriorWallType = async (id, updatedExteriorWallType) => {
    try {
      const response = await axiosInstance.put(`/api/exterior-wall-types/${id}`, updatedExteriorWallType);
      if (response.data.success) {
        setExteriorWallTypes(exteriorWallTypes.map(type => (type.id === id ? updatedExteriorWallType : type)));
        setUpdateSuccess('Exterior wall type updated successfully!');
        setTimeout(() => setUpdateSuccess(''), 3000);
      }
    } catch (error) {
      console.error('Error updating exterior wall type:', error);
    }
  };

  const handleDeleteExteriorWallType = async (id) => {
    try {
      const response = await axiosInstance.delete(`/api/exterior-wall-types/${id}`);
      if (response.data.success) {
        setExteriorWallTypes(exteriorWallTypes.filter(type => type.id !== id));
      }
    } catch (error) {
      console.error('Error deleting exterior wall type:', error);
    }
  };

  const handleExteriorWallTypeInputChange = (index, field, value) => {
    const updatedExteriorWallTypes = [...exteriorWallTypes];
    updatedExteriorWallTypes[index][field] = value;
    setExteriorWallTypes(updatedExteriorWallTypes);
  };

  return {
    exteriorWallTypes,
    newExteriorWallType,
    setNewExteriorWallType,
    handleAddExteriorWallType,
    handleUpdateExteriorWallType,
    handleDeleteExteriorWallType,
    handleExteriorWallTypeInputChange,
    updateSuccess
  };
};

export default useExteriorWallTypes;