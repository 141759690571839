import React, { useState, useEffect } from 'react';
import { Rnd } from 'react-rnd';
import usePropertyData from '../hooks/usePropertyData';
import AddSaleModal from '../modals/AddSaleModal';
import AddInspectionModal from '../modals/AddInspectionModal';
import EditSaleModal from '../modals/EditSaleModal';
import EditInspectionModal from '../modals/EditInspectionModal';
import './PropertyData.css';

// Helper function to format date
const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric'
  });
};

const PropertyData = ({ accountNumber }) => {
  const { 
    propertyData, 
    saleData, 
    inspectionData, 
    loading, 
    error, 
    savePropertyData, 
    addSaleRecord, 
    addInspectionRecord,
    updateSaleRecord,
    updateInspectionRecord,
    neighborhoodOptions,
    zoneOptions,
    landUseOptions,
    topographyOptions,
    utilityOptions,
    streetTypeOptions
  } = usePropertyData(accountNumber);

  const [editingData, setEditingData] = useState({});
  const [isAddSaleModalOpen, setIsAddSaleModalOpen] = useState(false);
  const [isAddInspectionModalOpen, setIsAddInspectionModalOpen] = useState(false);
  const [isEditSaleModalOpen, setIsEditSaleModalOpen] = useState(false);
  const [isEditInspectionModalOpen, setIsEditInspectionModalOpen] = useState(false);
  const [selectedSale, setSelectedSale] = useState(null);
  const [selectedInspection, setSelectedInspection] = useState(null);

  const [boxes, setBoxes] = useState({
    generalInfo: { x: 8, y: 8, width: 400, height: 461 },
    saleData: { x: 417, y: 8, width: 1040, height: 461 },
    inspection: { x: 8, y: 476, width: 630, height: 393 },
    notes: { x: 647, y: 476, width: 810, height: 395 }
  });

  useEffect(() => {
    console.log('Fetched propertyData:', propertyData);
    if (propertyData) {
      setEditingData(propertyData);
    }
  }, [propertyData]);

  const handleInputChange = (field, value) => {
    setEditingData(prev => ({ ...prev, [field]: value }));
  };

  const handleSave = async (e) => {
      e.preventDefault();
      await savePropertyData(editingData);
  }; 

  const updateBox = (id, newPos) => {
    setBoxes(prev => ({
      ...prev,
      [id]: { ...prev[id], ...newPos }
    }));
  };

  const renderBox = (id, content) => (
    <Rnd
      size={{ width: boxes[id].width, height: boxes[id].height }}
      position={{ x: boxes[id].x, y: boxes[id].y }}
      onDragStop={(e, d) => updateBox(id, { x: d.x, y: d.y })}
      onResizeStop={(e, direction, ref, delta, position) => {
        updateBox(id, {
          width: ref.offsetWidth,
          height: ref.offsetHeight,
          ...position,
        });
      }}
      bounds="parent"
    >
      {content}
    </Rnd>
  );

  const handleAddSale = (newSale) => {
    addSaleRecord(newSale);
  };

  const handleAddInspection = (newInspection) => {
    addInspectionRecord(newInspection);
  };

  const handleEditSale = (editedSale) => {
    updateSaleRecord(editedSale);
  };

  const handleEditInspection = (editedInspection) => {
    updateInspectionRecord(editedInspection);
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}. Please check the console for more details.</div>;

  // Only render the component when data is available
  if (!propertyData || !saleData || !inspectionData) return <div>Loading data...</div>;

  return (
    <div className="property-data">
      {renderBox('generalInfo',
        <div className="general-info">
          <h3>General Information</h3>

          <select 
            value={editingData.neighborhood || ''} 
            onChange={(e) => handleInputChange('neighborhood', e.target.value)}
          >
            <option value="">Select Neighborhood</option>
            {Array.isArray(neighborhoodOptions) && neighborhoodOptions.map(option => (
              <option key={option.id} value={option.id}>{option.name}</option>
            ))}
          </select>

          <select 
            value={editingData.zoneUse || ''} 
            onChange={(e) => handleInputChange('zoneUse', e.target.value)}
          >
            <option value="">Select Zone Use</option>
            {Array.isArray(zoneOptions) && zoneOptions.map(option => (
              <option key={option.id} value={option.id}>{option.zone_type}</option>
            ))}
          </select>

          <select 
            value={editingData.landUse || ''} 
            onChange={(e) => handleInputChange('landUse', e.target.value)}
          >
            <option value="">Select Land Use</option>
            {Array.isArray(landUseOptions) && landUseOptions.map(option => (
              <option key={option.id} value={option.id}>{option.land_use_type}</option>
            ))}
          </select>

          <select 
            value={editingData.topography || ''} 
            onChange={(e) => handleInputChange('topography', e.target.value)}
          >
            <option value="">Select Topography</option>
            {Array.isArray(topographyOptions) && topographyOptions.map(option => (
              <option key={option.id} value={option.id}>{option.topography_type}</option>
            ))}
          </select>

          <select 
            value={editingData.utilities || ''} 
            onChange={(e) => handleInputChange('utilities', e.target.value)}
          >
            <option value="">Select Utilities</option>
            {Array.isArray(utilityOptions) && utilityOptions.map(option => (
              <option key={option.id} value={option.id}>{option.utility_type}</option>
            ))}
          </select>

          <select 
            value={editingData.streetType || ''} 
            onChange={(e) => handleInputChange('streetType', e.target.value)}
          >
            <option value="">Select Street Type</option>
            {Array.isArray(streetTypeOptions) && streetTypeOptions.map(option => (
              <option key={option.id} value={option.id}>{option.street_type}</option>
            ))}
          </select>

          <button type="button" onClick={(e) => handleSave(e)}>Save</button>
         </div>
      )}

{renderBox('saleData',
        <div className="sale-data">
          <h3>Sale Data</h3>
          <table>
            <thead>
              <tr>
                <th>Date</th>
                <th>Price</th>
                <th>Type</th>
                <th>Financing</th>
                <th>Validity</th>
                <th>Verified</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {saleData.map((sale, index) => (
                <tr key={index}>
                  <td>{formatDate(sale.date)}</td>
                  <td>${sale.price.toLocaleString()}</td>
                  <td>{sale.type}</td>
                  <td>{sale.financing}</td>
                  <td>{sale.validity}</td>
                  <td>{sale.verified ? 'Yes' : 'No'}</td>
                  <td>
                    <button onClick={() => {
                      setSelectedSale(sale);
                      setIsEditSaleModalOpen(true);
                    }}>Edit</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <button onClick={() => setIsAddSaleModalOpen(true)}>Add Sale</button>
        </div>
      )}

      {renderBox('inspection',
        <div className="inspection">
          <h3>Inspection</h3>
          <table>
            <thead>
              <tr>
                <th>Date</th>
                <th>Description</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {inspectionData.map((inspection, index) => (
                <tr key={index}>
                  <td>{formatDate(inspection.date)}</td>
                  <td>{inspection.description}</td>
                  <td>
                    <button onClick={() => {
                      setSelectedInspection(inspection);
                      setIsEditInspectionModalOpen(true);
                    }}>Edit</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <button onClick={() => setIsAddInspectionModalOpen(true)}>Add Inspection</button>
        </div>
      )}

{renderBox('notes',
        <div className="notes">
          <h3>Notes</h3>
          <textarea
            value={editingData.notes || propertyData.notes || ''}
            onChange={(e) => handleInputChange('notes', e.target.value)}
            placeholder="Enter assessor's notes here..."
          />
          <button onClick={handleSave}>Save Notes</button>
        </div>
      )}

      <div className="position-display">
        <h4>Current Positions and Sizes:</h4>
        {Object.entries(boxes).map(([key, value]) => (
          <p key={key}>{key}: x: {value.x}, y: {value.y}, width: {value.width}px, height: {value.height}px</p>
        ))}
      </div>
      <AddSaleModal
        isOpen={isAddSaleModalOpen}
        onClose={() => setIsAddSaleModalOpen(false)}
        onSave={handleAddSale}
      />

      <AddInspectionModal
        isOpen={isAddInspectionModalOpen}
        onClose={() => setIsAddInspectionModalOpen(false)}
        onSave={handleAddInspection}
      />

      <EditSaleModal
        isOpen={isEditSaleModalOpen}
        onClose={() => setIsEditSaleModalOpen(false)}
        onSave={handleEditSale}
        saleData={selectedSale}
      />

      <EditInspectionModal
        isOpen={isEditInspectionModalOpen}
        onClose={() => setIsEditInspectionModalOpen(false)}
        onSave={handleEditInspection}
        inspectionData={selectedInspection}
      />
    </div>
  );
};

export default PropertyData;