import React, { useState, useEffect } from 'react';
import { Rnd } from 'react-rnd';
import './LandData.css';

const LandData = ({
  accountNumber,
  landTypes,
  accountLandData,
  frontageDepthData,
  loading,
  error,
  saveLandData,
  deleteLandData,
  addLandType,
  saveFrontageDepthData,
}) => {
  const [editingData, setEditingData] = useState([]);
  const [frontage, setFrontage] = useState('');
  const [depth, setDepth] = useState('');

  useEffect(() => {
    setEditingData(accountLandData);
    setFrontage(frontageDepthData.frontage || '');
    setDepth(frontageDepthData.depth || '');
  }, [accountLandData, frontageDepthData]);

  const [boxes, setBoxes] = useState({
    landTypeTable: { x: 8, y: 8, width: 980, height: 810 },
    frontageDepth: { x: 997, y: 8, width: 464, height: 331 }
  });

  const updateBox = (id, newPos) => {
    setBoxes(prev => ({
      ...prev,
      [id]: { ...prev[id], ...newPos }
    }));
  };

  const renderBox = (id, content) => (
    <Rnd
      size={{ width: boxes[id].width, height: boxes[id].height }}
      position={{ x: boxes[id].x, y: boxes[id].y }}
      onDragStop={(e, d) => updateBox(id, { x: d.x, y: d.y })}
      onResizeStop={(e, direction, ref, delta, position) => {
        updateBox(id, {
          width: ref.offsetWidth,
          height: ref.offsetHeight,
          ...position,
        });
      }}
      bounds="parent"
    >
      {content}
    </Rnd>
  );

  const handleLandTypeChange = (index, field, value) => {
    const updatedData = [...editingData];
    updatedData[index] = {
      ...updatedData[index],
      [field]: value
    };

    if (field === 'land_type_id') {
      const selectedLandType = landTypes.find(lt => lt.id === parseInt(value));
      if (selectedLandType) {
        updatedData[index].value_per_acre = selectedLandType.value_per_acre;
        updatedData[index].percentage_factor = updatedData[index].percentage_factor || 100;
      }
    }

    // Recalculate adjustedCost
    const selectedLandType = landTypes.find(lt => lt.id === parseInt(updatedData[index].land_type_id));
    const acres = parseFloat(updatedData[index].acres) || 0;
    const factor = parseFloat(updatedData[index].percentage_factor) || 100;

    if (selectedLandType) {
      const basePrice = selectedLandType.improvement ? selectedLandType.value_per_acre : selectedLandType.value_per_acre * acres;
      updatedData[index].adjustedCost = basePrice * (factor / 100);
    }

    setEditingData(updatedData);
  };

  const handleSave = async (index) => {
    await saveLandData(editingData[index]);
  };

  const handleAddLandType = async () => {
    console.log('Adding new land type for account:', accountNumber);
    await addLandType(accountNumber);
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  const handleFrontageDepthSave = async () => {
    await saveFrontageDepthData({ frontage, depth });
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="land-data">
      {renderBox('landTypeTable',
        <div className="land-type-table">
          <h3>Land Type Table</h3>
          <table>
            <thead>
              <tr>
                <th>Land Type</th>
                <th>Acreage</th>
                <th>Schedule Cost</th>
                <th>Factor %</th>
                <th>Factor Reason</th>
                <th>Adjusted Cost</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {editingData.map((land, index) => (
                <tr key={land.id}>
                  <td>
                    <select
                      value={land.land_type_id || ''}
                      onChange={(e) => handleLandTypeChange(index, 'land_type_id', e.target.value)}
                    >
                      <option value="">Select Land Type</option>
                      {landTypes.map(lt => (
                        <option key={lt.id} value={lt.id}>{lt.type}</option>
                      ))}
                    </select>
                  </td>
                  <td>
                    <input 
                      type="number" 
                      value={land.acres || ''} 
                      onChange={(e) => handleLandTypeChange(index, 'acres', e.target.value)}
                      step="any"
                    />
                  </td>
                  <td>{land.value_per_acre}</td>
                  <td>
                    <input 
                      type="number" 
                      value={land.percentage_factor || ''} 
                      onChange={(e) => handleLandTypeChange(index, 'percentage_factor', e.target.value)}
                      step="any"
                    />
                  </td>
                  <td>
                    <input 
                      type="text" 
                      value={land.factor_reason || ''} 
                      onChange={(e) => handleLandTypeChange(index, 'factor_reason', e.target.value)}
                    />
                  </td>
                  <td>{land.adjustedCost}</td>
                  <td>
  <div className="button-container">
    <button onClick={() => handleSave(index)}>Save</button>
    <button className="delete-button" onClick={() => deleteLandData(land.id)}>Delete</button>
  </div>
</td>
                </tr>
              ))}
            </tbody>
          </table>
          <button onClick={handleAddLandType}>Add Land Type</button>
        </div>
      )}

{renderBox('frontageDepth',
        <div className="frontage-depth">
          <h3>Frontage and Depth</h3>
          <div>
            <label>Frontage +/-:</label>
            <input type="number" value={frontage} onChange={(e) => setFrontage(e.target.value)} step="any" />
          </div>
          <div>
            <label>Depth +/-:</label>
            <input type="number" value={depth} onChange={(e) => setDepth(e.target.value)} step="any" />
          </div>
          <button onClick={handleFrontageDepthSave}>Save Frontage and Depth</button>
        </div>
      )}

      <div className="position-display">
        <h4>Current Positions and Sizes:</h4>
        {Object.entries(boxes).map(([key, value]) => (
          <p key={key}>{key}: x: {value.x}, y: {value.y}, width: {value.width}px, height: {value.height}px</p>
        ))}
      </div>
    </div>
  );
};

export default LandData;