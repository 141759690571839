import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/town_name.jpg';

const Sidebar = ({ isOpen, toggleSidebar }) => {
  return (
    <div className={`fixed inset-y-0 left-0 transform ${isOpen ? 'translate-x-0' : '-translate-x-full'} md:translate-x-0 transition-transform duration-200 ease-in-out w-64 bg-gray-800 text-white h-screen z-50`}>
      <div className="p-4 flex flex-col items-center relative">
        <button
          className="absolute top-4 right-4 md:hidden text-white"
          onClick={() => toggleSidebar(true)}
        >
          X
        </button>
        <h1 className="text-2xl font-bold">Smalltown</h1>
        <img src={logo} alt="Smalltown Logo" className="my-4 w-32 h-auto" />
        <nav className="mt-10 w-full">
          <Link to="/" className="block py-2.5 px-4 rounded transition duration-200 hover:bg-gray-700" onClick={() => toggleSidebar(true)}>
            Dashboard
          </Link>
          <Link to="/account-search" className="block py-2.5 px-4 rounded transition duration-200 hover:bg-gray-700" onClick={() => toggleSidebar(true)}>
            Account Search
          </Link>
          <Link to="/new-account" className="block py-2.5 px-4 rounded transition duration-200 hover:bg-gray-700" onClick={() => toggleSidebar(true)}>
            New Account
          </Link>
          <Link to="/reports" className="block py-2.5 px-4 rounded transition duration-200 hover:bg-gray-700" onClick={() => toggleSidebar(true)}>
            Reports
          </Link>
          <Link to="/help" className="block py-2.5 px-4 rounded transition duration-200 hover:bg-gray-700" onClick={() => toggleSidebar(true)}>
            Help
          </Link>
          <Link to="/global-values" className="block py-2.5 px-4 rounded transition duration-200 hover:bg-gray-700" onClick={() => toggleSidebar(true)}>
            Global Values
          </Link>
          <Link to="/property-tax-card/1" className="block py-2.5 px-4 rounded transition duration-200 hover:bg-gray-700" onClick={() => toggleSidebar(true)}>
            Property Account
          </Link>
          <Link to="/property-owners" className="block py-2.5 px-4 rounded transition duration-200 hover:bg-gray-700" onClick={() => toggleSidebar(true)}>
            Property Owners
          </Link>
          <Link to="/certified-ratio-mvr" className="block py-2.5 px-4 rounded transition duration-200 hover:bg-gray-700" onClick={() => toggleSidebar(true)}>
          Certified Ratio / MVR
          </Link>
          <Link to="/about" className="block py-2.5 px-4 rounded transition duration-200 hover:bg-gray-700" onClick={() => toggleSidebar(true)}>
          About
          </Link>
          </nav>
      </div>
    </div>
  );
};

export default Sidebar;
