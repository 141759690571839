import React from 'react';
import './ExemptionsModal.css';

const ExemptionsModal = ({ isOpen, onClose, onSubmit, newExemption, setNewExemption }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Add New Exemption</h2>
        <form onSubmit={onSubmit}>
          <div className="form-group">
            <label>Exemption Name</label>
            <input
              type="text"
              value={newExemption.exemption_name}
              onChange={(e) =>
                setNewExemption({ ...newExemption, exemption_name: e.target.value })
              }
              placeholder="Exemption Name"
              required
            />
          </div>
          <div className="form-group">
            <label>Amount Type</label>
            <select
              value={newExemption.amount_type}
              onChange={(e) =>
                setNewExemption({ ...newExemption, amount_type: e.target.value })
              }
              required
            >
              <option value="dollar">Dollar Amount</option>
              <option value="percentage">Percentage Amount</option>
            </select>
          </div>
          <div className="form-group">
            <label>Apply Order</label>
            <select
              value={newExemption.apply_order}
              onChange={(e) =>
                setNewExemption({ ...newExemption, apply_order: e.target.value })
              }
              required
            >
              <option value="building_first">Building First</option>
              <option value="land_first">Land First</option>
            </select>
          </div>
          <div className="form-group">
            <label>Exemption Amount</label>
            <input
              type="number"
              value={newExemption.exemption_amount}
              onChange={(e) =>
                setNewExemption({ ...newExemption, exemption_amount: e.target.value })
              }
              placeholder="Exemption Amount"
              required
            />
          </div>
          {/* Add 'form-group' back to 'checkbox-group' */}
          <div className="form-group checkbox-group">
            <label className="checkbox-label">
              <input
                type="checkbox"
                checked={newExemption.adjust_by_ratio}
                onChange={(e) =>
                  setNewExemption({ ...newExemption, adjust_by_ratio: e.target.checked })
                }
              />
              Adjust by Ratio
            </label>
          </div>
          {/* Add 'form-group' back to 'button-group' */}
          <div className="form-group button-group">
            <button type="button" onClick={onClose} className="close-button">
              Cancel
            </button>
            <button type="submit" className="submit-button">
              Add Exemption
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ExemptionsModal;
