import React, { useState, useEffect } from 'react';
import axiosInstance from '../../axiosInstance'; // Use axiosInstance instead of axios
import { useNavigate } from 'react-router-dom';
import './UserManagement.css';

const UserManagement = () => {
    const [users, setUsers] = useState([]);
    const [activityLog, setActivityLog] = useState([]);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        fetchUsers();
        fetchActivityLog();
    }, []);

    const parseResponse = (response, dataType) => {
        console.log(`Raw ${dataType} response:`, JSON.stringify(response, null, 2));
        
        if (Array.isArray(response.data)) {
            return response.data;
        } else if (typeof response.data === 'object' && response.data !== null) {
            // Check if the data is nested in a property
            const possibleArrays = Object.values(response.data).filter(Array.isArray);
            if (possibleArrays.length > 0) {
                return possibleArrays[0];
            }
            // If no arrays found, return the object itself if it has expected properties
            if (dataType === 'users' && response.data.id && response.data.username) {
                return [response.data];
            }
            if (dataType === 'activity log' && response.data.id && response.data.action) {
                return [response.data];
            }
        }
        console.warn(`Unexpected data format for ${dataType}:`, response.data);
        return [];
    };

    const fetchUsers = () => {
        axiosInstance.get('/api/users')
            .then(response => {
                const parsedUsers = parseResponse(response, 'users');
                console.log('Parsed users:', parsedUsers);
                setUsers(parsedUsers);
            })
            .catch(error => {
                console.error('There was an error fetching the users!', error);
                setError(`Failed to fetch users: ${error.message}`);
            });
    };

    const fetchActivityLog = () => {
        axiosInstance.get('/api/user-actions-log')
            .then(response => {
                const parsedLog = parseResponse(response, 'activity log');
                console.log('Parsed activity log:', parsedLog);
                setActivityLog(parsedLog);
            })
            .catch(error => {
                console.error('There was an error fetching the activity log!', error);
                setError(`Failed to fetch activity log: ${error.message}`);
            });
    };

    const handleDelete = (id) => {
        axiosInstance.delete(`/api/users/${id}`)
            .then(() => {
                setUsers(users.filter(user => user.id !== id));
            })
            .catch(error => {
                console.error('There was an error deleting the user!', error);
            });
    };

    const handleEdit = (id) => {
        navigate(`/usermanagement/edit/${id}`); // Navigate to the edit page
    };

    const handleCreate = () => {
        navigate('/usermanagement/create'); // Navigate to the create page
    };

    const handleShowDeleted = () => {
        navigate('/usermanagement/deleted'); // Navigate to the deleted users page
    };

    if (error) {
        return (
            <div className="error-message">
                <h2>Error</h2>
                <p>{error}</p>
                <button onClick={() => { setError(null); fetchUsers(); fetchActivityLog(); }}>Retry</button>
            </div>
        );
    }

    return (
        <div className="user-management-container">
            <div className="user-management-content">
                <h2 className="user-management-title">User Management</h2>
                <div className="user-management-actions">
                    <button className="user-management-button" onClick={handleCreate}>Create New User</button>
                    <button className="user-management-button" onClick={handleShowDeleted}>Show Deleted Users</button>
                </div>
                <div className="user-management-table-container">
                    <table className="user-management-table">
                        <thead>
                            <tr>
                                <th className="user-management-th">ID</th>
                                <th className="user-management-th">Username</th>
                                <th className="user-management-th">Email</th>
                                <th className="user-management-th">Role</th>
                                <th className="user-management-th">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {users.length > 0 ? (
                                users.map(user => (
                                    <tr key={user.id}>
                                        <td className="user-management-td">{user.id}</td>
                                        <td className="user-management-td">{user.username}</td>
                                        <td className="user-management-td">{user.email}</td>
                                        <td className="user-management-td">{user.role}</td>
                                        <td className="user-management-td">
                                            <div className="user-management-actions">
                                                <button className="user-management-button" onClick={() => handleEdit(user.id)}>Edit</button>
                                                <button className="user-management-button" onClick={() => handleDelete(user.id)}>Delete</button>
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="5" className="user-management-td">No users found.</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>

            <div className="user-management-content">
                <h2 className="user-management-title">Activity Log</h2>
                <div className="user-management-table-container">
                    <table className="user-management-table">
                        <thead>
                            <tr>
                                <th className="user-management-th">ID</th>
                                <th className="user-management-th">Username</th>
                                <th className="user-management-th">Action</th>
                                <th className="user-management-th">Details</th>
                                <th className="user-management-th">Timestamp</th>
                            </tr>
                        </thead>
                        <tbody>
                            {activityLog.length > 0 ? (
                                activityLog.map(log => (
                                    <tr key={log.id}>
                                        <td className="user-management-td">{log.id}</td>
                                        <td className="user-management-td">{log.username}</td>
                                        <td className="user-management-td">{log.action}</td>
                                        <td className="user-management-td">{log.details}</td>
                                        <td className="user-management-td">{new Date(log.timestamp).toLocaleString()}</td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="5" className="user-management-td">No activity log found.</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default UserManagement;