import React from 'react';
import useUtilities from '../hooks/useUtilities';
import './Utilities.css';

const Utilities = () => {
  console.log('Rendering Utilities component');
  const {
    utilityTypes,
    newUtilityType,
    setNewUtilityType,
    handleAddUtilityType,
    handleUpdateUtilityType,
    handleDeleteUtilityType,
    handleUtilityTypeInputChange,
    updateSuccess
  } = useUtilities();

  return (
    <>
      {updateSuccess && <div className="update-success">{updateSuccess}</div>}
      <div className="utilities">
        <table className="utilities-table">
          <thead>
            <tr>
              <th>Utility Type</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {utilityTypes.map((utilityType, index) => (
              <tr key={utilityType.id}>
                <td>
                  <input
                    type="text"
                    value={utilityType.utility_type}
                    onChange={(e) =>
                      handleUtilityTypeInputChange(index, 'utility_type', e.target.value)
                    }
                    placeholder="Utility Type"
                  />
                </td>
                <td>
                  {/* Wrap the buttons inside a div with class "button-group" */}
                  <div className="button-group">
                    <button
                      onClick={() => handleUpdateUtilityType(utilityType.id, utilityType)}
                      className="update-button"
                    >
                      Update
                    </button>
                    <button
                      onClick={() => handleDeleteUtilityType(utilityType.id)}
                      className="delete-button"
                    >
                      Delete
                    </button>
                  </div>
                </td>
              </tr>
            ))}
            <tr className="new-utility-type">
              <td>
                <input
                  type="text"
                  value={newUtilityType.utility_type}
                  onChange={(e) =>
                    setNewUtilityType({ ...newUtilityType, utility_type: e.target.value })
                  }
                  placeholder="New Utility Type"
                />
              </td>
              <td>
                {/* Wrap the add button inside a div with class "button-group" */}
                <div className="button-group">
                  <button onClick={handleAddUtilityType} className="add-button">
                    Add Utility Type
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Utilities;
