import React, { useState } from 'react';
import useLandSchedules from '../hooks/useLandSchedules';
import DeleteConfirmationModal from '../modals/DeleteConfirmationModal';
import './LandSchedules.css';

const LandSchedules = () => {
  console.log('Rendering Exemptions component');
  const {
    landTypes,
    newLandType,
    setNewLandType,
    handleAddLandType,
    handleUpdateLandType,
    handleDeleteLandType,
    handleLandTypeInputChange,
    updateSuccess,
    error,
    isLoading,
    clearMessages
  } = useLandSchedules();

  const [deleteConfirmation, setDeleteConfirmation] = useState(null);

  const validateInput = (type, value) => {
    if (type === 'type' && value.trim() === '') {
      return 'Type is required';
    }
    if (type === 'value_per_acre' && (isNaN(value) || value <= 0)) {
      return 'Value per acre must be a positive number';
    }
    if (type === 'improvement' && typeof value !== 'boolean') {
      return 'Improvement must be true or false';
    }
    if (type === 'std_lot' && isNaN(value)) {
      return 'Std Lot must be a number';
    }
    if (type === 'std_acre' && isNaN(value)) {
      return 'Std Acre must be a number';
    }
    return null;
  };  

  const handleConfirmDelete = () => {
    if (deleteConfirmation) {
      handleDeleteLandType(deleteConfirmation);
      setDeleteConfirmation(null);
    }
  };

  return (
    <div className="land-schedules">
      {isLoading && <div className="loading">Loading...</div>}
      {updateSuccess && <div className="update-success">{updateSuccess}</div>}
      {error && <div className="error-message">{error}</div>}
      <table className="land-schedules-table">
        <thead>
          <tr>
            <th>Type</th>
            <th>Value per Acre</th>
            <th>Improvement</th>
            <th>Std Lot</th>
            <th>Std Acre</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
  {landTypes.map((landType, index) => (
    <tr key={landType.id}>
      <td>
        <input
          type="text"
          value={landType.type}
          onChange={(e) => {
            handleLandTypeInputChange(index, 'type', e.target.value);
            clearMessages();
          }}
          placeholder="Type"
        />
      </td>
      <td>
        <input
          type="number"
          value={landType.value_per_acre}
          onChange={(e) => {
            handleLandTypeInputChange(index, 'value_per_acre', e.target.value);
            clearMessages();
          }}
          placeholder="Value per Acre"
        />
      </td>
      <td>
        <input
          type="checkbox"
          checked={landType.improvement || false}  // Ensure it's a boolean
          onChange={(e) => {
            handleLandTypeInputChange(index, 'improvement', e.target.checked);
            clearMessages();
          }}
        />
      </td>
      <td>
        <input
          type="number"
          value={landType.std_lot || ''}  // Handle undefined values
          onChange={(e) => {
            handleLandTypeInputChange(index, 'std_lot', e.target.value);
            clearMessages();
          }}
          placeholder="Std Lot"
        />
      </td>
      <td>
        <input
          type="number"
          value={landType.std_acre || ''}  // Handle undefined values
          onChange={(e) => {
            handleLandTypeInputChange(index, 'std_acre', e.target.value);
            clearMessages();
          }}
          placeholder="Std Acre"
        />
      </td>
      <td>
        <button
          onClick={() => {
            const typeError = validateInput('type', landType.type);
            const valueError = validateInput('value_per_acre', landType.value_per_acre);
            const improvementError = validateInput('improvement', landType.improvement);
            const stdLotError = validateInput('std_lot', landType.std_lot);
            const stdAcreError = validateInput('std_acre', landType.std_acre);
            if (typeError || valueError || improvementError || stdLotError || stdAcreError) {
              clearMessages();
              alert(typeError || valueError || improvementError || stdLotError || stdAcreError);
            } else {
              handleUpdateLandType(landType.id, landType);
            }
          }}
          className="update-button"
          disabled={isLoading}
        >
          Update
        </button>
        <button
          onClick={() => setDeleteConfirmation(landType.id)}
          className="delete-button"
          disabled={isLoading}
        >
          Delete
        </button>
      </td>
    </tr>
  ))}
          <tr className="new-land-type">
            <td>
              <input
                type="text"
                value={newLandType.type}
                onChange={(e) => {
                  setNewLandType({ ...newLandType, type: e.target.value });
                  clearMessages();
                }}
                placeholder="New Type"
              />
            </td>
            <td>
              <input
                type="number"
                value={newLandType.value_per_acre}
                onChange={(e) => {
                  setNewLandType({ ...newLandType, value_per_acre: e.target.value });
                  clearMessages();
                }}
                placeholder="New Value per Acre"
              />
            </td>
            <td>
              <input
                type="checkbox"
                checked={newLandType.improvement}
                onChange={(e) => {
                  setNewLandType({ ...newLandType, improvement: e.target.checked });
                  clearMessages();
                }}
                placeholder="Improvement"
              />
            </td>
            <td>
              <input
                type="number"
                value={newLandType.std_lot}
                onChange={(e) => {
                  setNewLandType({ ...newLandType, std_lot: e.target.value });
                  clearMessages();
                }}
                placeholder="New Std Lot"
              />
            </td>
            <td>
              <input
                type="number"
                value={newLandType.std_acre}
                onChange={(e) => {
                  setNewLandType({ ...newLandType, std_acre: e.target.value });
                  clearMessages();
                }}
                placeholder="New Std Acre"
              />
            </td>
            <td>
              <button
                onClick={() => {
                  const typeError = validateInput('type', newLandType.type);
                  const valueError = validateInput('value_per_acre', newLandType.value_per_acre);
                  const improvementError = validateInput('improvement', newLandType.improvement);
                  const stdLotError = validateInput('std_lot', newLandType.std_lot);
                  const stdAcreError = validateInput('std_acre', newLandType.std_acre);
                  if (typeError || valueError || improvementError || stdLotError || stdAcreError) {
                    clearMessages();
                    alert(typeError || valueError || improvementError || stdLotError || stdAcreError);
                  } else {
                    handleAddLandType();
                  }
                }}
                className="add-button"
                disabled={isLoading}
              >
                Add Land Type
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <DeleteConfirmationModal
        isOpen={!!deleteConfirmation}
        onClose={() => setDeleteConfirmation(null)}
        onConfirm={handleConfirmDelete}
        itemName="land type"
      />
    </div>
  );
};

export default LandSchedules;
