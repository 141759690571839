import React from 'react';
import useTopography from '../hooks/useTopography';
import './Topography.css';

const Topography = () => {
  console.log('Rendering Topography component');
  const {
    topographyTypes,
    newTopographyType,
    setNewTopographyType,
    handleAddTopographyType,
    handleUpdateTopographyType,
    handleDeleteTopographyType,
    handleTopographyTypeInputChange,
    updateSuccess
  } = useTopography();

  return (
    <>
      {updateSuccess && <div className="update-success">{updateSuccess}</div>}
      <div className="topography">
        <table className="topography-table">
          <thead>
            <tr>
              <th>Topography Type</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {topographyTypes.map((topographyType, index) => (
              <tr key={topographyType.id}>
                <td>
                  <input
                    type="text"
                    value={topographyType.topography_type}
                    onChange={(e) =>
                      handleTopographyTypeInputChange(index, 'topography_type', e.target.value)
                    }
                    placeholder="Topography Type"
                  />
                </td>
                <td>
                  {/* Wrap the buttons inside a div with class "button-group" */}
                  <div className="button-group">
                    <button
                      onClick={() => handleUpdateTopographyType(topographyType.id, topographyType)}
                      className="update-button"
                    >
                      Update
                    </button>
                    <button
                      onClick={() => handleDeleteTopographyType(topographyType.id)}
                      className="delete-button"
                    >
                      Delete
                    </button>
                  </div>
                </td>
              </tr>
            ))}
            <tr className="new-topography-type">
              <td>
                <input
                  type="text"
                  value={newTopographyType.topography_type}
                  onChange={(e) =>
                    setNewTopographyType({ ...newTopographyType, topography_type: e.target.value })
                  }
                  placeholder="New Topography Type"
                />
              </td>
              <td>
                {/* Wrap the add button inside a div with class "button-group" */}
                <div className="button-group">
                  <button
                    onClick={handleAddTopographyType}
                    className="add-button"
                  >
                    Add Topography Type
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Topography;
