// PropertyTaxCard.js

import React, { useState, useEffect, useCallback, useMemo } from 'react';
import PropertyOverview from '../PropertyTaxCard/tabs/PropertyOverview'; // Import Property Overview tab component
import PropertyData from '../PropertyTaxCard/tabs/PropertyData'; // Import Property Data tab component
import LandData from '../PropertyTaxCard/tabs/LandData'; // Import Land Data tab component
import BuildingData from './tabs/BuildingData'; // Import Building Data tab component
import Sketch from './tabs/Sketch'; // Import Sketch tab component
import './PropertyTaxCard.css'; // Import CSS for PropertyTaxCard
import { useParams } from 'react-router-dom'; // Import hook to get URL parameters
import axiosInstance from '../../axiosInstance'; // Axios instance for API requests
import getApiUrl from '../../config'; // Get the API URL from config
import ErrorBoundary from './ErrorBoundary'; // Error boundary to handle errors gracefully
import useLandData from './hooks/useLandData'; // Custom hook for land data management
import useBuildingDataGeneral from './hooks/useBuildingDataGeneral'; // Custom hook for building data management
import { debounce } from 'lodash'; // Import lodash debounce for rate-limiting API requests

// Main component for the Property Tax Card
const PropertyTaxCard = () => {
  // State for the active tab (default is 'overview')
  const [activeTab, setActiveTab] = useState('overview');

  // State for holding the property information
  const [propertyInfo, setPropertyInfo] = useState({});

  // Get the account number from the URL params
  const { accountNumber } = useParams();

  // State to store detailed property data
  const [propertyData, setPropertyData] = useState({});

  // Extract building data and related methods from the custom hook
  const {
    generalInfo: buildingGeneralInfo,
    calculateBuildingValue,
    loading: buildingDataLoading,
  } = useBuildingDataGeneral(accountNumber);

  // Extract land data and related methods from the custom hook
  const {
    landTypes,
    accountLandData,
    frontageDepthData,
    loading: landDataLoading,
    error: landDataError,
    saveLandData,
    deleteLandData,
    addLandType,
    saveFrontageDepthData,
    calculateTotalLandValue,
  } = useLandData(accountNumber);

  // Debounced function to update total property value in the backend database
  const debouncedUpdateTotalValue = useCallback(
    debounce((accountNumber, totalValue) => {
      const apiUrl = getApiUrl();
      axiosInstance.post(`${apiUrl}/api/property-info/${accountNumber}/update-total-value`, {
        totalValue
      }).catch(error => {
        console.error('Error updating total property value in database:', error);
      });
    }, 1000),
    []
  );

  // Memoized calculation for total value (land + building)
  const calculateTotalValue = useMemo(() => {
    const landValue = parseFloat(propertyInfo.landValue) || 0;
    const buildingValue = parseFloat(propertyInfo.buildingValue) || 0;
    return landValue + buildingValue;
  }, [propertyInfo.landValue, propertyInfo.buildingValue]);

  // Function to update the property values in state, triggered when data changes
  const updatePropertyValues = useCallback((updates) => {
    setPropertyInfo(prevInfo => {
      const newInfo = { ...prevInfo, ...updates };
      const newTotalValue = calculateTotalValue;

      // Only update if total property value has changed
      if (newTotalValue !== prevInfo.totalPropertyValue) {
        console.log('Updating property values:', {
          landValue: newInfo.landValue,
          buildingValue: newInfo.buildingValue,
          newTotalValue
        });

        return {
          ...newInfo,
          totalPropertyValue: newTotalValue,
        };
      }

      return newInfo;
    });
  }, [calculateTotalValue]);

  // Effect hook to update the total value in the database when it changes
  useEffect(() => {
    if (propertyInfo.totalPropertyValue !== calculateTotalValue) {
      debouncedUpdateTotalValue(accountNumber, calculateTotalValue);
    }
  }, [accountNumber, calculateTotalValue, propertyInfo.totalPropertyValue, debouncedUpdateTotalValue]);

  // Fetch property info when account number or building info changes
  useEffect(() => {
    const fetchPropertyInfo = async () => {
      if (!accountNumber) {
        console.error('No account number provided');
        return;
      }
      try {
        const apiUrl = getApiUrl();
        const response = await axiosInstance.get(`${apiUrl}/api/property-info/${accountNumber}`);
        setPropertyInfo(prevInfo => ({
          ...prevInfo,
          ...response.data,
          buildingValue: buildingGeneralInfo.buildingValue || response.data.buildingValue,
        }));
      } catch (error) {
        console.error('Error fetching property info:', error);
      }
    };

    fetchPropertyInfo();
  }, [accountNumber, buildingGeneralInfo.buildingValue]);

  // Update property values once land and building data are fully loaded
  useEffect(() => {
    if (!landDataLoading && !landDataError && !buildingDataLoading) {
      const totalLandValue = calculateTotalLandValue(accountLandData);
      const totalBuildingValue = buildingGeneralInfo.buildingValue || 0;
      updatePropertyValues({ 
        landValue: totalLandValue, 
        buildingValue: totalBuildingValue,
      });
    }
  }, [accountLandData, landDataLoading, landDataError, buildingDataLoading, buildingGeneralInfo.buildingValue, calculateTotalLandValue, updatePropertyValues]);

  // State for managing building data
  const [buildingData, setBuildingData] = useState({
    generalInfo: {},
    structure: {},
    interior: {},
    systems: {},
    outbuildings: [],
  });

  // State for managing sketch data (e.g., drawing lines or labels)
  const [sketchData, setSketchData] = useState({
    lines: [],
    labels: []
  });

  // State for current owners
  const [currentOwners, setCurrentOwners] = useState([]);

  // Function to fetch current owners
  const fetchCurrentOwners = useCallback(async () => {
    const apiUrl = getApiUrl();
    try {
      const response = await axiosInstance.get(`${apiUrl}/api/properties/${accountNumber}/current-owners`);
      setCurrentOwners(response.data);
    } catch (error) {
      console.error('Error fetching current owners:', error);
    }
  }, [accountNumber]);

  // Fetch current owners when the component mounts or accountNumber changes
  useEffect(() => {
    if (accountNumber) {
      fetchCurrentOwners();
    }
  }, [accountNumber, fetchCurrentOwners]);

  // Mock data for previous owners
  const previousOwners = [
    { name: 'Alice Smith', deedBook: 'DB100', deedPage: '30', deedDate: '2010-03-20' },
    { name: 'Bob Johnson', deedBook: 'DB095', deedPage: '15', deedDate: '2005-11-10' }
  ];

  // Mock data for assessment history
  const assessmentHistory = [
    { year: 2023, landValue: 100000, buildingValue: 150000, exemptions: 5000, totalValue: 245000 },
    { year: 2022, landValue: 95000, buildingValue: 145000, exemptions: 5000, totalValue: 235000 },
    { year: 2021, landValue: 90000, buildingValue: 140000, exemptions: 5000, totalValue: 225000 },
  ];

  // Tab information (each tab in the UI)
  const tabs = [
    { id: 'overview', label: 'Property Overview' },
    { id: 'propertyData', label: 'Property Data' },
    { id: 'land', label: 'Land Data' },
    { id: 'building', label: 'Building Data' },
    { id: 'pictures', label: 'Pictures' },
    { id: 'sketch', label: 'Sketch' },
    { id: 'documents', label: 'Documents' },
  ];

  // Return the main JSX structure for rendering the property tax card
  return (
    <div className="property-tax-card">
      {/* Header section */}
      <div className="top-bar">
        <h1>Property Information</h1>
      </div>
      
      {/* Information bar showing property details */}
      <div className="info-bar">
        <div className="info-row">
          <div className="info-item">
            <span className="info-label">Account Number:</span>
            <span className="info-value">{propertyInfo.accountNumber || 'Loading...'}</span>
          </div>
          <div className="info-item">
            <span className="info-label">Map Number:</span>
            <span className="info-value">{propertyInfo.map || 'Loading...'}</span>
          </div>
          <div className="info-item">
            <span className="info-label">Lot Number:</span>
            <span className="info-value">{propertyInfo.lot || 'Loading...'}</span>
          </div>
          <div className="info-item">
            <span className="info-label">Location:</span>
            <span className="info-value">{propertyInfo.address || 'Loading...'}</span>
          </div>
          <div className="info-item">
            <span className="info-label">Card:</span>
            <span className="info-value">1 of {propertyInfo.totalCards || 'Loading...'}</span>
          </div>
        </div>
        <div className="info-row">
          <div className="info-item">
            <span className="info-label">Land Value:</span>
            <span className="info-value">${propertyInfo.landValue?.toLocaleString() || 'Loading...'}</span>
          </div>
          <div className="info-item">
            <span className="info-label">Building Value:</span>
            <span className="info-value">${propertyInfo.buildingValue?.toLocaleString() || 'Loading...'}</span>
          </div>
          <div className="info-item">
            <span className="info-label">Exemption Value:</span>
            <span className="info-value">${propertyInfo.exemptionValue?.toLocaleString() || 'Loading...'}</span>
          </div>
          <div className="info-item">
            <span className="info-label">Total Property Value:</span>
            <span className="info-value">${propertyInfo.totalPropertyValue?.toLocaleString() || 'Loading...'}</span>
          </div>
        </div>
      </div>
      
      {/* Tabs section */}
      <div className="tab-container">
        <div className="tabs">
          {tabs.map((tab) => (
            <button
              key={tab.id}
              className={activeTab === tab.id ? 'active' : ''}
              onClick={() => setActiveTab(tab.id)}
            >
              {tab.label}
            </button>
          ))}
        </div>
        
        {/* Tab content */}
        <div className="tab-content">          
          {activeTab === 'overview' && (
            <PropertyOverview
              accountNumber={accountNumber}
              onOwnerAdded={fetchCurrentOwners}
            />
          )}          
          {activeTab === 'propertyData' && (
            <ErrorBoundary>
              <PropertyData
                accountNumber={accountNumber}
                propertyData={propertyData}
                onUpdate={setPropertyData}
              />
            </ErrorBoundary>
          )}
          {activeTab === 'land' && (
            <ErrorBoundary>
              <LandData
                accountNumber={accountNumber}
                landTypes={landTypes}
                accountLandData={accountLandData}
                frontageDepthData={frontageDepthData}
                loading={landDataLoading}
                error={landDataError}
                saveLandData={saveLandData}
                deleteLandData={deleteLandData}
                addLandType={addLandType}
                saveFrontageDepthData={saveFrontageDepthData}
              />
            </ErrorBoundary>
          )}
          {activeTab === 'building' && (
            <ErrorBoundary>
              <BuildingData
                accountNumber={accountNumber}
                buildingData={buildingData}
                onUpdate={setBuildingData}
                calculateBuildingValue={calculateBuildingValue}
                onUpdatePropertyInfo={(updatedInfo) => {
                  updatePropertyValues(updatedInfo);
                  setBuildingData(prev => ({
                    ...prev,
                    generalInfo: updatedInfo.generalInfo || prev.generalInfo
                  }));
                }}
              />
            </ErrorBoundary>
          )}
          {activeTab === 'sketch' && (
            <Sketch
              data={sketchData}
              onUpdate={setSketchData}
            />
          )}
          {activeTab !== 'overview' && activeTab !== 'propertyData' && activeTab !== 'land' && activeTab !== 'building' && activeTab !== 'sketch' && (
            <p>Content for the {activeTab} tab will be displayed here.</p>
          )}
        </div>
      </div>
    </div>
  );
};

// Export the component
export default PropertyTaxCard;
