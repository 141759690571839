import React from 'react';
import useStoryHeights from '../hooks/useStoryHeights';
import './StoryHeights.css';

const StoryHeights = () => {
  console.log('Rendering Exemptions component');
  const {
    storyHeights,
    newStoryHeight,
    setNewStoryHeight,
    handleAddStoryHeight,
    handleUpdateStoryHeight,
    handleDeleteStoryHeight,
    handleStoryHeightInputChange,
    updateSuccess // Destructure the success message
  } = useStoryHeights();

  return (
    <div className="story-heights">
      {updateSuccess && <div className="update-success">{updateSuccess}</div>} {/* Display success message */}
      <table className="story-heights-table">
        <thead>
          <tr>
            <th>Story Height</th>
            <th>Adjustment Factor</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {storyHeights.map((storyHeight, index) => (
            <tr key={storyHeight.id}>
              <td>
                <input
                  type="text"
                  value={storyHeight.story_height}
                  onChange={(e) => handleStoryHeightInputChange(index, 'story_height', e.target.value)}
                  placeholder="Story Height"
                />
              </td>
              <td>
                <input
                  type="number"
                  value={storyHeight.adjustment_factor}
                  onChange={(e) => handleStoryHeightInputChange(index, 'adjustment_factor', e.target.value)}
                  placeholder="Adjustment Factor"
                />
              </td>
              <td>
                <button
                  onClick={() => handleUpdateStoryHeight(storyHeight.id, storyHeight)}
                  className="update-button"
                >
                  Update
                </button>
                <button
                  onClick={() => handleDeleteStoryHeight(storyHeight.id)}
                  className="delete-button"
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
          <tr className="new-story-height">
            <td>
              <input
                type="text"
                value={newStoryHeight.story_height}
                onChange={(e) => setNewStoryHeight({ ...newStoryHeight, story_height: e.target.value })}
                placeholder="New Story Height"
              />
            </td>
            <td>
              <input
                type="number"
                value={newStoryHeight.adjustment_factor}
                onChange={(e) => setNewStoryHeight({ ...newStoryHeight, adjustment_factor: e.target.value })}
                placeholder="New Adjustment Factor"
              />
            </td>
            <td>
              <button
                onClick={handleAddStoryHeight}
                className="add-button"
              >
                Add Story Height
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default StoryHeights;
