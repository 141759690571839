import { useState, useEffect } from 'react';
import axiosInstance from '../../../axiosInstance';

const useBasementTypes = () => {
  const [basementTypes, setBasementTypes] = useState([]);
  const [newBasementType, setNewBasementType] = useState({ type_name: '' });
  const [updateSuccess, setUpdateSuccess] = useState('');

  useEffect(() => {
    fetchBasementTypes();
  }, []);

  const fetchBasementTypes = async () => {
    try {
      const response = await axiosInstance.get('/api/basement-types');
      setBasementTypes(response.data);
    } catch (error) {
      console.error('Error fetching basement types:', error);
    }
  };

  const handleAddBasementType = async () => {
    try {
      const response = await axiosInstance.post('/api/basement-types', newBasementType);
      if (response.data.success) {
        setBasementTypes([...basementTypes, { ...newBasementType, id: response.data.id }]);
        setNewBasementType({ type_name: '' });
      }
    } catch (error) {
      console.error('Error adding basement type:', error);
    }
  };

  const handleUpdateBasementType = async (id, updatedBasementType) => {
    try {
      const response = await axiosInstance.put(`/api/basement-types/${id}`, updatedBasementType);
      if (response.data.success) {
        setBasementTypes(basementTypes.map(type => (type.id === id ? updatedBasementType : type)));
        setUpdateSuccess('Basement type updated successfully!');
        setTimeout(() => setUpdateSuccess(''), 3000);
      }
    } catch (error) {
      console.error('Error updating basement type:', error);
    }
  };

  const handleDeleteBasementType = async (id) => {
    try {
      const response = await axiosInstance.delete(`/api/basement-types/${id}`);
      if (response.data.success) {
        setBasementTypes(basementTypes.filter(type => type.id !== id));
      }
    } catch (error) {
      console.error('Error deleting basement type:', error);
    }
  };

  const handleBasementTypeInputChange = (index, field, value) => {
    const updatedBasementTypes = [...basementTypes];
    updatedBasementTypes[index][field] = value;
    setBasementTypes(updatedBasementTypes);
  };

  return {
    basementTypes,
    newBasementType,
    setNewBasementType,
    handleAddBasementType,
    handleUpdateBasementType,
    handleDeleteBasementType,
    handleBasementTypeInputChange,
    updateSuccess
  };
};

export default useBasementTypes;