import React, { useState, useEffect } from 'react';
import axiosInstance from '../../axiosInstance';
import { useParams, useNavigate } from 'react-router-dom';
import getApiUrl from '../../config';
import './PropertyOwnerDetail.css';

const PropertyOwnerDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [owner, setOwner] = useState({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    emailAddress: '',
    address: '',
    city: '',
    state_province: '',
    zip_postal_code: ''
  });

  useEffect(() => {
    const fetchOwner = async () => {
      const apiUrl = getApiUrl();
      try {
        const response = await axiosInstance.get(`${apiUrl}/api/property-owners/${id}`);
        console.log('Fetched owner:', response.data);
        setOwner(response.data);
      } catch (error) {
        console.error('Error fetching property owner', error);
      }
    };
    fetchOwner();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setOwner({ ...owner, [name]: value });
  };

  const handleUpdate = async () => {
    const apiUrl = getApiUrl();
    try {
      const response = await axiosInstance.put(`${apiUrl}/api/property-owners/${id}`, owner);
      console.log('Update response:', response);
      if (response.status === 200) {
        alert('Update successful');
      } else {
        alert('Update failed');
      }
    } catch (error) {
      console.error('Error updating property owner', error);
      alert('Update failed');
    }
  };

  const handleDelete = async () => {
    const apiUrl = getApiUrl();
    try {
      await axiosInstance.delete(`${apiUrl}/api/property-owners/${id}`);
      alert('Owner deleted successfully');
      navigate('/property-owners');
    } catch (error) {
      console.error('Error deleting property owner', error);
      alert('Error deleting property owner');
    }
  };

  return (
    <div className="property-owner-detail-container">
      <div className="property-owner-detail-content">
        <h1 className="property-owner-detail-title">Property Owner Details</h1>
        <form className="property-owner-detail-form">
          <div className="property-owner-detail-grid">
            <div className="property-owner-detail-field">
              <label htmlFor="firstName" className="property-owner-detail-label">First Name</label>
              <input
                type="text"
                id="firstName"
                name="firstName"
                value={owner.firstName}
                onChange={handleChange}
                className="property-owner-detail-input"
              />
            </div>
            <div className="property-owner-detail-field">
              <label htmlFor="lastName" className="property-owner-detail-label">Last Name</label>
              <input
                type="text"
                id="lastName"
                name="lastName"
                value={owner.lastName}
                onChange={handleChange}
                className="property-owner-detail-input"
              />
            </div>
            <div className="property-owner-detail-field">
              <label htmlFor="phoneNumber" className="property-owner-detail-label">Phone Number</label>
              <input
                type="text"
                id="phoneNumber"
                name="phoneNumber"
                value={owner.phoneNumber}
                onChange={handleChange}
                className="property-owner-detail-input"
              />
            </div>
            <div className="property-owner-detail-field">
              <label htmlFor="emailAddress" className="property-owner-detail-label">Email Address</label>
              <input
                type="email"
                id="emailAddress"
                name="emailAddress"
                value={owner.emailAddress}
                onChange={handleChange}
                className="property-owner-detail-input"
              />
            </div>
            <div className="property-owner-detail-field">
              <label htmlFor="address" className="property-owner-detail-label">Address</label>
              <input
                type="text"
                id="address"
                name="address"
                value={owner.address}
                onChange={handleChange}
                className="property-owner-detail-input"
              />
            </div>
            <div className="property-owner-detail-field">
              <label htmlFor="city" className="property-owner-detail-label">City</label>
              <input
                type="text"
                id="city"
                name="city"
                value={owner.city}
                onChange={handleChange}
                className="property-owner-detail-input"
              />
            </div>
            <div className="property-owner-detail-field">
              <label htmlFor="state_province" className="property-owner-detail-label">State/Province</label>
              <input
                type="text"
                id="state_province"
                name="state_province"
                value={owner.state_province}
                onChange={handleChange}
                className="property-owner-detail-input"
              />
            </div>
            <div className="property-owner-detail-field">
              <label htmlFor="zip_postal_code" className="property-owner-detail-label">Zip/Postal Code</label>
              <input
                type="text"
                id="zip_postal_code"
                name="zip_postal_code"
                value={owner.zip_postal_code}
                onChange={handleChange}
                className="property-owner-detail-input"
              />
            </div>
          </div>
          <div className="property-owner-detail-button-container">
            <button type="button" onClick={handleUpdate} className="property-owner-detail-button">Update</button>
            <button type="button" onClick={handleDelete} className="property-owner-detail-button delete">Delete</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PropertyOwnerDetail;