import React, { useState, useEffect } from 'react';
import axiosInstance from '../../axiosInstance';
import './NewAccount.css';

const NewAccount = () => {
  const [accountNumber, setAccountNumber] = useState('');
  const [formData, setFormData] = useState({
    map: '',
    lot: '',
    streetNumber: '',
    streetName: '',
    book: '',
    page: '',
  });

  useEffect(() => {
    const fetchLastAccountNumber = async () => {
      try {
        const response = await axiosInstance.get('/accounts/last-account-number');
        console.log('Last account number response:', response.data);
        const lastAccountNumber = response.data.lastAccountNumber;
        if (lastAccountNumber) {
          const nextAccountNumber = (parseInt(lastAccountNumber, 10) + 1).toString().padStart(4, '0');
          console.log('Next account number:', nextAccountNumber);
          setAccountNumber(nextAccountNumber);
        } else {
          console.error('Invalid lastAccountNumber:', lastAccountNumber);
        }
      } catch (error) {
        console.error('Error fetching last account number', error);
      }
    };

    fetchLastAccountNumber();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSave = async () => {
    const confirmed = window.confirm('Are you sure you want to save this new account?');
    if (!confirmed) return;

    try {
      const newAccount = { accountNumber, ...formData };
      console.log('Sending new account data:', newAccount);
      await axiosInstance.post('/accounts/new-account', newAccount);
      alert('New account saved successfully');
    } catch (error) {
      console.error('Error saving new account', error);
      alert('Failed to save new account');
    }
  };

  return (
    <div className="new-account-container">
      <div className="new-account-content">
        <h1 className="new-account-title">New Account</h1>
        <form className="new-account-form">
          <div className="new-account-grid">
            <div className="new-account-field full-width">
              <label htmlFor="accountNumber" className="new-account-label">Account Number</label>
              <input
                type="text"
                id="accountNumber"
                name="accountNumber"
                value={accountNumber}
                readOnly
                className="new-account-input"
              />
            </div>
            <div className="new-account-field">
              <label htmlFor="map" className="new-account-label">Map</label>
              <input
                type="text"
                id="map"
                name="map"
                value={formData.map}
                onChange={handleChange}
                className="new-account-input"
              />
            </div>
            <div className="new-account-field">
              <label htmlFor="lot" className="new-account-label">Lot</label>
              <input
                type="text"
                id="lot"
                name="lot"
                value={formData.lot}
                onChange={handleChange}
                className="new-account-input"
              />
            </div>
            <div className="new-account-field">
              <label htmlFor="streetNumber" className="new-account-label">Street Number</label>
              <input
                type="text"
                id="streetNumber"
                name="streetNumber"
                value={formData.streetNumber}
                onChange={handleChange}
                className="new-account-input"
              />
            </div>
            <div className="new-account-field">
              <label htmlFor="streetName" className="new-account-label">Street Name</label>
              <input
                type="text"
                id="streetName"
                name="streetName"
                value={formData.streetName}
                onChange={handleChange}
                className="new-account-input"
              />
            </div>
            <div className="new-account-field">
              <label htmlFor="book" className="new-account-label">Book</label>
              <input
                type="text"
                id="book"
                name="book"
                value={formData.book}
                onChange={handleChange}
                className="new-account-input"
              />
            </div>
            <div className="new-account-field">
              <label htmlFor="page" className="new-account-label">Page</label>
              <input
                type="text"
                id="page"
                name="page"
                value={formData.page}
                onChange={handleChange}
                className="new-account-input"
              />
            </div>
          </div>
          <div className="new-account-button-container">
            <button type="button" onClick={handleSave} className="new-account-button">Save New Account</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default NewAccount;