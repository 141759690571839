import { useState, useEffect } from 'react';
import axiosInstance from '../../../axiosInstance';

const useStoryHeights = () => {
  const [storyHeights, setStoryHeights] = useState([]);
  const [newStoryHeight, setNewStoryHeight] = useState({ story_height: '', adjustment_factor: '' });
  const [updateSuccess, setUpdateSuccess] = useState(''); // New state for success message

  useEffect(() => {
    fetchStoryHeights();
  }, []);

  const fetchStoryHeights = async () => {
    try {
      const response = await axiosInstance.get('/api/story-heights');
      setStoryHeights(response.data);
    } catch (error) {
      console.error('Error fetching story heights:', error);
    }
  };

  const handleAddStoryHeight = async () => {
    try {
      const response = await axiosInstance.post('/api/story-heights', newStoryHeight);
      if (response.data.success) {
        setStoryHeights([...storyHeights, { ...newStoryHeight, id: response.data.id }]);
        setNewStoryHeight({ story_height: '', adjustment_factor: '' });
      }
    } catch (error) {
      console.error('Error adding story height:', error);
    }
  };

  const handleUpdateStoryHeight = async (id, updatedStoryHeight) => {
    try {
      const response = await axiosInstance.put(`/api/story-heights/${id}`, updatedStoryHeight);
      if (response.data.success) {
        setStoryHeights(storyHeights.map(sh => (sh.id === id ? updatedStoryHeight : sh)));
        setUpdateSuccess('Story height updated successfully!'); // Set success message
        setTimeout(() => setUpdateSuccess(''), 3000); // Clear message after 3 seconds
      }
    } catch (error) {
      console.error('Error updating story height:', error);
    }
  };

  const handleDeleteStoryHeight = async (id) => {
    try {
      const response = await axiosInstance.delete(`/api/story-heights/${id}`);
      if (response.data.success) {
        setStoryHeights(storyHeights.filter(sh => sh.id !== id));
      }
    } catch (error) {
      console.error('Error deleting story height:', error);
    }
  };

  const handleStoryHeightInputChange = (index, field, value) => {
    const updatedStoryHeights = [...storyHeights];
    updatedStoryHeights[index][field] = value;
    setStoryHeights(updatedStoryHeights);
  };

  return {
    storyHeights,
    newStoryHeight,
    setNewStoryHeight,
    handleAddStoryHeight,
    handleUpdateStoryHeight,
    handleDeleteStoryHeight,
    handleStoryHeightInputChange,
    updateSuccess // Return the success message
  };
};

export default useStoryHeights;
