// src/pages/PropertyTaxCard/components/components/DeedInfoForm.js

import React from 'react';

const DeedInfoForm = ({ deedInfo, onChange }) => {
    return (
        <div className="deed-info-form">
            <h3>Deed Information</h3>
            <form>
                <div className="form-group">
                    <label htmlFor="deedBook">Deed Book:</label>
                    <input
                        type="text"
                        id="deedBook"
                        name="deedBook"
                        value={deedInfo.deedBook}
                        onChange={onChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="deedPage">Deed Page:</label>
                    <input
                        type="text"
                        id="deedPage"
                        name="deedPage"
                        value={deedInfo.deedPage}
                        onChange={onChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="deedDate">Deed Date:</label>
                    <input
                        type="date"
                        id="deedDate"
                        name="deedDate"
                        value={deedInfo.deedDate}
                        onChange={onChange}
                        required
                    />
                </div>
            </form>
        </div>
    );
};

export default DeedInfoForm;
