// src/pages/PropertyTaxCard/components/modals/AddCurrentOwnerModal.js

import React, { useState, useEffect, useMemo } from 'react';
import axiosInstance from '../../../axiosInstance';
import getApiUrl from '../../../config';
import './Modal.css'; // Ensure your CSS accommodates multi-select
import { toast } from 'react-toastify';

const AddCurrentOwnerModal = ({
    isOpen,
    onClose,
    accountNumber,
    onOwnerAdded,
    replaceOwners, // Boolean indicating if replacing owners
    existingDeedInfo, // Object with existing deed info
}) => {
    const [allOwners, setAllOwners] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedOwners, setSelectedOwners] = useState([]); // Array of owner IDs to add
    const [ownersToRemove, setOwnersToRemove] = useState([]); // Array of owner IDs to remove
    const [showCreateOwnerForm, setShowCreateOwnerForm] = useState(false);
    const [newOwner, setNewOwner] = useState({
        firstName: '',
        lastName: '',
        phoneNumber: '',
        emailAddress: '',
        address: '',
        city: '',
        state_province: '',
        zip_postal_code: '',
    });
    const [deedInfo, setDeedInfo] = useState({
        deedBook: '',
        deedPage: '',
        deedDate: '',
    });
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    // Format date for input[type="date"]
    const formatDateForInput = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        if (isNaN(date)) return '';
        const yyyy = date.getFullYear();
        const mm = String(date.getMonth() + 1).padStart(2, '0');
        const dd = String(date.getDate()).padStart(2, '0');
        return `${yyyy}-${mm}-${dd}`;
    };

    useEffect(() => {
        if (isOpen) {
            console.log('Modal opened');
            fetchAllOwners();
            // Initialize deedInfo with existingDeedInfo
            if (existingDeedInfo) {
                setDeedInfo({
                    ...existingDeedInfo,
                    deedDate: formatDateForInput(existingDeedInfo.deedDate),
                });
                console.log('Deed info initialized:', {
                    ...existingDeedInfo,
                    deedDate: formatDateForInput(existingDeedInfo.deedDate),
                });
            } else {
                setDeedInfo({
                    deedBook: '',
                    deedPage: '',
                    deedDate: '',
                });
                console.log('Deed info initialized with empty fields');
            }

            // If replacing owners, populate ownersToRemove
            if (replaceOwners && existingDeedInfo) {
                // Assuming existingOwners are passed as existingDeedInfo's owners
                // You might need to adjust based on your data structure
                const ownersToRemoveIds = existingDeedInfo.ownerIds || []; // Adjust based on actual data
                setOwnersToRemove(ownersToRemoveIds);
                console.log('Owners to remove:', ownersToRemoveIds);
            } else {
                setOwnersToRemove([]);
            }

            setSelectedOwners([]); // Reset selected owners when modal opens
        } else {
            console.log('Modal closed');
            // Reset form states when modal closes
            setSearchTerm('');
            setSelectedOwners([]);
            setOwnersToRemove([]);
            setShowCreateOwnerForm(false);
            setNewOwner({
                firstName: '',
                lastName: '',
                phoneNumber: '',
                emailAddress: '',
                address: '',
                city: '',
                state_province: '',
                zip_postal_code: '',
            });
            setDeedInfo({
                deedBook: '',
                deedPage: '',
                deedDate: '',
            });
        }
    }, [isOpen, replaceOwners, existingDeedInfo]);

    const fetchAllOwners = async () => {
        setIsLoading(true);
        console.log('Fetching all owners...');
        const apiUrl = getApiUrl();
        try {
            const response = await axiosInstance.get(`${apiUrl}/api/property-owners`);
            setAllOwners(response.data);
            console.log('Owners fetched:', response.data);
        } catch (error) {
            console.error('Error fetching all owners:', error);
            toast.error('Failed to fetch owners.');
        } finally {
            setIsLoading(false);
            console.log('Loading complete');
        }
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    // Filter owners based on search term
    const filteredOwners = useMemo(() => {
        return allOwners.filter(owner => {
            return owner.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
                   owner.lastName.toLowerCase().includes(searchTerm.toLowerCase());
        });
    }, [allOwners, searchTerm]);

    const handleOwnerSelect = (ownerId) => {
        setSelectedOwners(prev => {
            if (prev.includes(ownerId)) {
                return prev.filter(id => id !== ownerId);
            } else {
                return [...prev, ownerId];
            }
        });
    };

    const handleAddOwner = async () => {
        // Confirm replacing owners if applicable
        if (replaceOwners && ownersToRemove.length > 0) {
            const confirmReplace = window.confirm('Adding new owners will mark all existing current owners as previous owners. Do you wish to proceed?');
            if (!confirmReplace) return;
        }

        const apiUrl = getApiUrl();
        setIsSubmitting(true);
        try {
            let ownerIdsToAdd = [...selectedOwners]; // Clone the array

            // Handle creating a new owner if the form is visible
            if (showCreateOwnerForm) {
                // Validate new owner fields
                const { firstName, lastName, address, city, state_province, zip_postal_code } = newOwner;
                if (!firstName || !lastName || !address || !city || !state_province || !zip_postal_code) {
                    toast.error('Please fill in all required fields for the new owner.');
                    setIsSubmitting(false);
                    return;
                }

                const createOwnerResponse = await axiosInstance.post(`${apiUrl}/api/property-owners`, newOwner);
                const createdOwner = createOwnerResponse.data;
                ownerIdsToAdd.push(createdOwner.ownerId);
                console.log('New owner created:', createdOwner);
            }

            // Validate deed information
            if (!deedInfo.deedBook || !deedInfo.deedPage || !deedInfo.deedDate) {
                toast.error('Please fill in all deed information fields.');
                setIsSubmitting(false);
                return;
            }

            // Prepare payload
            const payload = {
                ownerIdsToAdd,
                ownerIdsToRemove: replaceOwners ? ownersToRemove : [],
                deedBook: deedInfo.deedBook,
                deedPage: deedInfo.deedPage,
                deedDate: deedInfo.deedDate,
                isReplacingOwners: replaceOwners,
            };

            // Send request to /edit-owners
            const editOwnersResponse = await axiosInstance.put(`${apiUrl}/api/properties/${accountNumber}/edit-owners`, payload);

            if (editOwnersResponse.data.success) {
                toast.success(editOwnersResponse.data.message);
                onOwnerAdded(); // Refresh owners in parent component
                onClose(); // Close the modal
                // Reset form states
                setSelectedOwners([]);
                setOwnersToRemove([]);
                setShowCreateOwnerForm(false);
                setNewOwner({
                    firstName: '',
                    lastName: '',
                    phoneNumber: '',
                    emailAddress: '',
                    address: '',
                    city: '',
                    state_province: '',
                    zip_postal_code: '',
                });
                setDeedInfo({
                    deedBook: '',
                    deedPage: '',
                    deedDate: '',
                });
                console.log('Form reset successfully.');
            } else {
                toast.error(editOwnersResponse.data.error || 'Failed to update owners.');
            }
        } catch (error) {
            console.error('Error updating owners:', error);
            if (error.response && error.response.data && error.response.data.error) {
                toast.error(`Failed to update owners: ${error.response.data.error}`);
            } else {
                toast.error('Failed to update owners. Please try again.');
            }
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleNewOwnerChange = (e) => {
        const { name, value } = e.target;
        setNewOwner({ ...newOwner, [name]: value });
    };

    const handleDeedInfoChange = (e) => {
        const { name, value } = e.target;
        setDeedInfo({ ...deedInfo, [name]: value });
    };

    if (!isOpen) return null;

    return (
        <div className="modal-overlay" role="dialog" aria-modal="true" aria-labelledby="add-modal-title">
            <div className="modal-content">
                <h2 id="add-modal-title">{replaceOwners ? 'Replace Owners' : 'Add Current Owner(s)'}</h2>
                <button className="close-button" onClick={onClose} aria-label="Close Modal">×</button>
                {isLoading ? (
                    <div className="loader"></div>
                ) : showCreateOwnerForm ? (
                    <div className="create-owner-form">
                        <h3>Create New Owner</h3>
                        <input
                            type="text"
                            name="firstName"
                            placeholder="First Name"
                            value={newOwner.firstName}
                            onChange={handleNewOwnerChange}
                            required
                        />
                        <input
                            type="text"
                            name="lastName"
                            placeholder="Last Name"
                            value={newOwner.lastName}
                            onChange={handleNewOwnerChange}
                            required
                        />
                        <input
                            type="text"
                            name="phoneNumber"
                            placeholder="Phone Number"
                            value={newOwner.phoneNumber}
                            onChange={handleNewOwnerChange}
                        />
                        <input
                            type="email"
                            name="emailAddress"
                            placeholder="Email Address"
                            value={newOwner.emailAddress}
                            onChange={handleNewOwnerChange}
                        />
                        <input
                            type="text"
                            name="address"
                            placeholder="Address"
                            value={newOwner.address}
                            onChange={handleNewOwnerChange}
                        />
                        <input
                            type="text"
                            name="city"
                            placeholder="City"
                            value={newOwner.city}
                            onChange={handleNewOwnerChange}
                        />
                        <input
                            type="text"
                            name="state_province"
                            placeholder="State/Province"
                            value={newOwner.state_province}
                            onChange={handleNewOwnerChange}
                        />
                        <input
                            type="text"
                            name="zip_postal_code"
                            placeholder="Zip/Postal Code"
                            value={newOwner.zip_postal_code}
                            onChange={handleNewOwnerChange}
                        />
                        {/* Deed Information */}
                        <h4>Deed Information</h4>
                        <input
                            type="text"
                            name="deedBook"
                            placeholder="Deed Book"
                            value={deedInfo.deedBook}
                            onChange={handleDeedInfoChange}
                            required
                        />
                        <input
                            type="text"
                            name="deedPage"
                            placeholder="Deed Page"
                            value={deedInfo.deedPage}
                            onChange={handleDeedInfoChange}
                            required
                        />
                        <input
                            type="date"
                            name="deedDate"
                            placeholder="Deed Date"
                            value={deedInfo.deedDate}
                            onChange={handleDeedInfoChange}
                            required
                        />
                        <div className="button-container">
                            <button type="button" onClick={handleAddOwner} disabled={isSubmitting}>
                                {isSubmitting ? 'Adding...' : 'Add Owner(s)'}
                            </button>
                            <button type="button" onClick={() => setShowCreateOwnerForm(false)}>
                                Back to Search
                            </button>
                        </div>
                    </div>
                ) : (
                    <div className="owner-search">
                        <input
                            type="text"
                            placeholder="Search Owners"
                            value={searchTerm}
                            onChange={handleSearchChange}
                        />
                        <ul className="owners-list">
                            {filteredOwners.map((owner) => (
                                <li key={owner.ownerId}>
                                    <label>
                                        <input
                                            type="checkbox"
                                            checked={selectedOwners.includes(owner.ownerId)}
                                            onChange={() => handleOwnerSelect(owner.ownerId)}
                                        />
                                        {owner.firstName} {owner.lastName}
                                    </label>
                                </li>
                            ))}
                        </ul>
                        {/* Deed Information */}
                        <h4>Deed Information</h4>
                        <input
                            type="text"
                            name="deedBook"
                            placeholder="Deed Book"
                            value={deedInfo.deedBook}
                            onChange={handleDeedInfoChange}
                            required
                        />
                        <input
                            type="text"
                            name="deedPage"
                            placeholder="Deed Page"
                            value={deedInfo.deedPage}
                            onChange={handleDeedInfoChange}
                            required
                        />
                        <input
                            type="date"
                            name="deedDate"
                            placeholder="Deed Date"
                            value={deedInfo.deedDate}
                            onChange={handleDeedInfoChange}
                            required
                        />
                        <div className="button-container">
                            <button
                                type="button"
                                onClick={handleAddOwner}
                                className="primary-button"
                                disabled={(selectedOwners.length === 0 && !showCreateOwnerForm) || isSubmitting}
                            >
                                {replaceOwners ? 'Replace Owners' : 'Add Selected Owner(s)'}
                            </button>
                            <button
                                type="button"
                                onClick={() => setShowCreateOwnerForm(true)}
                                className="secondary-button"
                            >
                                Add New Owner
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default AddCurrentOwnerModal;
