import { useState, useEffect } from 'react';
import axiosInstance from '../../../axiosInstance';

const useZones = () => {
  const [zones, setZones] = useState([]);
  const [newZone, setNewZone] = useState({ zone_type: '' });
  const [updateSuccess, setUpdateSuccess] = useState('');

  useEffect(() => {
    fetchZones();
  }, []);

  const fetchZones = async () => {
    try {
      const response = await axiosInstance.get('/api/zones');
      setZones(response.data);
    } catch (error) {
      console.error('Error fetching zones:', error);
    }
  };

  const handleAddZone = async () => {
    try {
      const response = await axiosInstance.post('/api/zones', newZone);
      if (response.data.success) {
        setZones([...zones, { ...newZone, id: response.data.id }]);
        setNewZone({ zone_type: '' });
      }
    } catch (error) {
      console.error('Error adding zone:', error);
    }
  };

  const handleUpdateZone = async (id, updatedZone) => {
    try {
      const response = await axiosInstance.put(`/api/zones/${id}`, updatedZone);
      if (response.data.success) {
        setZones(zones.map(zone => (zone.id === id ? updatedZone : zone)));
        setUpdateSuccess('Zone updated successfully!');
        setTimeout(() => setUpdateSuccess(''), 3000);
      }
    } catch (error) {
      console.error('Error updating zone:', error);
    }
  };

  const handleDeleteZone = async (id) => {
    try {
      const response = await axiosInstance.delete(`/api/zones/${id}`);
      if (response.data.success) {
        setZones(zones.filter(zone => zone.id !== id));
      }
    } catch (error) {
      console.error('Error deleting zone:', error);
    }
  };

  const handleZoneInputChange = (index, field, value) => {
    const updatedZones = [...zones];
    updatedZones[index][field] = value;
    setZones(updatedZones);
  };

  return {
    zones,
    newZone,
    setNewZone,
    handleAddZone,
    handleUpdateZone,
    handleDeleteZone,
    handleZoneInputChange,
    updateSuccess
  };
};

export default useZones;