import React, { useState } from 'react';
import './BuildingData.css';

// Import sub-components for each subtab
import GeneralInfo from './BuildingSubtabs/GeneralInfo';
import Structure from './BuildingSubtabs/Structure';
import Interior from './BuildingSubtabs/Interior';
import Systems from './BuildingSubtabs/Systems';
import Outbuildings from './BuildingSubtabs/Outbuildings';
import Computations from './BuildingSubtabs/Computations';

const BuildingData = ({ buildingData = {}, onUpdate, accountNumber, calculateBuildingValue, onUpdatePropertyInfo }) => {
  const [activeSubtab, setActiveSubtab] = useState('generalInfo');

  const subtabs = [
    { id: 'generalInfo', label: 'General Info' },
    { id: 'structure', label: 'Structure' },
    { id: 'interior', label: 'Interior' },
    { id: 'systems', label: 'Systems' },
    { id: 'outbuildings', label: 'Outbuildings' },
    { id: 'computations', label: 'Building Computations' },
  ];

  const renderSubtabContent = () => {
    switch (activeSubtab) {
      case 'generalInfo':
        return <GeneralInfo
          accountNumber={accountNumber}
          data={buildingData.generalInfo}
          onUpdate={async (data) => {
            const updatedData = { ...buildingData, generalInfo: data };
            onUpdate(updatedData);
            if (data.sfArea !== buildingData.generalInfo.sfArea) {
              const newBuildingValue = await calculateBuildingValue(data.sfArea);
              onUpdatePropertyInfo({ 
                sfArea: data.sfArea, 
                buildingValue: newBuildingValue,
                generalInfo: { ...data, buildingValue: newBuildingValue }
              });
            }
          }}
          calculateBuildingValue={calculateBuildingValue}
        />;
      case 'structure':
        return <Structure accountNumber={accountNumber} data={buildingData.structure} onUpdate={(data) => onUpdate({ ...buildingData, structure: data })} />;
      case 'interior':
        return <Interior accountNumber={accountNumber} data={buildingData.interior} onUpdate={(data) => onUpdate({ ...buildingData, interior: data })} />;
      case 'systems':
        return <Systems accountNumber={accountNumber} data={buildingData.systems} onUpdate={(data) => onUpdate({ ...buildingData, systems: data })} />;
      case 'outbuildings':
        return <Outbuildings accountNumber={accountNumber} data={buildingData.outbuildings} onUpdate={(data) => onUpdate({ ...buildingData, outbuildings: data })} />;
      case 'computations':
        return <Computations accountNumber={accountNumber} data={buildingData} />;
      default:
        return null;
    }
  };

  return (
    <div className="building-data">
      <div className="subtabs">
        {subtabs.map((subtab) => (
          <button
            key={subtab.id}
            className={activeSubtab === subtab.id ? 'active' : ''}
            onClick={() => setActiveSubtab(subtab.id)}
          >
            {subtab.label}
          </button>
        ))}
      </div>
      <div className="subtab-content">
        {renderSubtabContent()}
      </div>
    </div>
  );
};

export default BuildingData;
