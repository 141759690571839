import React, { useState, useEffect } from 'react';
import axiosInstance from '../../axiosInstance';
import './CertifiedRatioMVR.css';

const CertifiedRatioMVR = () => {
  const [counties, setCounties] = useState([]);
  const [allMunicipalities, setAllMunicipalities] = useState([]);
  const [filteredMunicipalities, setFilteredMunicipalities] = useState([]);
  const [selectedCounty, setSelectedCounty] = useState('');
  const [selectedMunicipality, setSelectedMunicipality] = useState('');
  const [municipalityName, setMunicipalityName] = useState('');
  const [certifiedRatio, setCertifiedRatio] = useState('');
  const [year, setYear] = useState(new Date().getFullYear());
  const [message, setMessage] = useState('');
  const [isDefaultSet, setIsDefaultSet] = useState(false);

  useEffect(() => {
    fetchDefaultMunicipality();
    fetchCounties();
    fetchAllMunicipalities();
  }, []);

  const setMessageWithTimeout = (msg, duration = 3000) => {
    setMessage(msg);
    setTimeout(() => {
      setMessage('');
    }, duration);
  };

  const fetchDefaultMunicipality = async () => {
    try {
      const response = await axiosInstance.get('/api/mvr/default-municipality');
      if (response.data) {
        setSelectedCounty(response.data.county_id.toString());
        setSelectedMunicipality(response.data.id.toString());
        setMunicipalityName(response.data.name); // Set the municipality name
        setIsDefaultSet(true);
        fetchCertifiedRatioForCurrentYear(response.data.id);
      } else {
        setIsDefaultSet(false);
        setMunicipalityName(''); // Clear the municipality name
        setMessageWithTimeout(
          'No default municipality set. Please select a municipality, enter a year and certified ratio, then click "Save Default Municipality" to set the default municipality.',
          6000
        );
      }
    } catch (error) {
      console.error('Error fetching default municipality:', error);
      setMessageWithTimeout(
        'Error fetching default municipality. Please try selecting a municipality and saving it as default.',
        6000
      );
    }
  };

  const fetchCertifiedRatioForCurrentYear = async (municipalityId) => {
    const currentYear = new Date().getFullYear();
    try {
      const response = await axiosInstance.get(`/api/mvr/certified-ratio/${municipalityId}/${currentYear}`);
      if (response.data) {
        setCertifiedRatio(response.data.certified_ratio);
        setYear(currentYear);
      } else {
        setCertifiedRatio('');
        setMessageWithTimeout(`No certified ratio found for ${currentYear}. You can enter a new ratio.`, 4000);
      }
    } catch (error) {
      console.error('Error fetching certified ratio for current year:', error);
      setMessageWithTimeout('Error loading data for the current year. You can enter a new ratio.', 4000);
    }
  };

  const fetchCounties = async () => {
    try {
      const response = await axiosInstance.get('/api/mvr/counties');
      setCounties(response.data);
    } catch (error) {
      console.error('Error fetching counties:', error);
      setMessageWithTimeout('Error fetching counties. Please try again.', 3000);
    }
  };

  const fetchAllMunicipalities = async () => {
    try {
      const response = await axiosInstance.get('/api/mvr/municipalities');
      setAllMunicipalities(response.data);
      setFilteredMunicipalities(response.data);
    } catch (error) {
      console.error('Error fetching municipalities:', error);
      setMessageWithTimeout('Error fetching municipalities. Please try again.', 3000);
    }
  };

  const filterMunicipalitiesByCounty = (countyId) => {
    if (countyId) {
      const filtered = allMunicipalities.filter(m => m.county_id && m.county_id.toString() === countyId);
      setFilteredMunicipalities(filtered);
    } else {
      setFilteredMunicipalities(allMunicipalities);
    }
  };

  const handleCountyChange = (e) => {
    const countyId = e.target.value;
    setSelectedCounty(countyId);
    setSelectedMunicipality('');
    setCertifiedRatio('');
    filterMunicipalitiesByCounty(countyId);
  };

  const handleMunicipalityChange = (e) => {
    const municipalityId = e.target.value;
    setSelectedMunicipality(municipalityId);
    setCertifiedRatio('');
    if (municipalityId) {
      const municipality = allMunicipalities.find(m => m.id.toString() === municipalityId);
      if (municipality && municipality.county_id) {
        setSelectedCounty(municipality.county_id.toString());
        setMunicipalityName(municipality.name); // Update municipality name when changed
        filterMunicipalitiesByCounty(municipality.county_id.toString());
      }
      fetchCertifiedRatioForCurrentYear(municipalityId);
    } else {
      setMunicipalityName(''); // Clear municipality name if none selected
    }
  };

  const handleLoadData = async () => {
    if (!selectedMunicipality || !year) {
      setMessageWithTimeout('Please select a municipality and enter a year.', 3000);
      return;
    }

    try {
      const response = await axiosInstance.get(`/api/mvr/certified-ratio/${selectedMunicipality}/${year}`);
      if (response.data) {
        setCertifiedRatio(response.data.certified_ratio);
        setMessageWithTimeout('Data loaded successfully.', 3000);
      } else {
        setCertifiedRatio('');
        setMessageWithTimeout('No existing ratio found. Enter a certified ratio and click "Update Certified Ratio" to save a new entry for this municipality and year.', 6000);
      }
    } catch (error) {
      console.error('Error loading certified ratio:', error);
      setMessageWithTimeout('Error loading data. Please try again.', 3000);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedMunicipality || !year || !certifiedRatio) {
      setMessageWithTimeout('Please fill in all fields.', 3000);
      return;
    }
  
    try {
      if (!isDefaultSet) {
        console.log('Attempting to set default municipality...');
        const defaultResponse = await axiosInstance.post('/api/mvr/default-municipality', { municipalityId: selectedMunicipality });
        console.log('Default municipality response:', defaultResponse);
        setIsDefaultSet(true);
      }
  
      console.log('Attempting to update/create certified ratio...');
      const response = await axiosInstance.post('/api/mvr/certified-ratio', {
        municipalityId: selectedMunicipality,
        year,
        certifiedRatio
      });
      console.log('Certified ratio response:', response);
  
      setMessageWithTimeout(isDefaultSet ? response.data.message : 'Default municipality set and certified ratio updated successfully.', 4000);
    } catch (error) {
      console.error('Full error object:', error);
      console.error('Error response data:', error.response?.data);
      console.error('Error message:', error.message);
      setMessageWithTimeout(`Error: ${error.response?.data?.message || error.message || 'An unexpected error occurred.'}`, 5000);
    }
  };

  return (
    <div className="certified-ratio-mvr-container">
      <div className="certified-ratio-mvr-content">
        <h1 className="certified-ratio-mvr-title">
          Certified Ratio / MVR Information
          {isDefaultSet && municipalityName && ` - ${municipalityName}`}
        </h1>
        {message && <div className="certified-ratio-mvr-message">{message}</div>}
        <form onSubmit={handleSubmit} className="certified-ratio-mvr-form">
          <div className="certified-ratio-mvr-grid">
            <div className="certified-ratio-mvr-field">
              <label htmlFor="county" className="certified-ratio-mvr-label">County:</label>
              <select
                id="county"
                value={selectedCounty}
                onChange={handleCountyChange}
                className="certified-ratio-mvr-input"
                disabled={isDefaultSet}
              >
                <option value="">Select a county</option>
                {counties.map(county => (
                  <option key={county.id} value={county.id}>{county.name}</option>
                ))}
              </select>
            </div>
            <div className="certified-ratio-mvr-field">
              <label htmlFor="municipality" className="certified-ratio-mvr-label">Municipality:</label>
              <select
                id="municipality"
                value={selectedMunicipality}
                onChange={handleMunicipalityChange}
                className="certified-ratio-mvr-input"
                disabled={isDefaultSet}
              >
                <option value="">Select a municipality</option>
                {filteredMunicipalities.map(municipality => (
                  <option key={municipality.id} value={municipality.id}>{municipality.name}</option>
                ))}
              </select>
            </div>
            <div className="certified-ratio-mvr-field">
              <label htmlFor="year" className="certified-ratio-mvr-label">Year:</label>
              <input
                type="number"
                id="year"
                value={year}
                onChange={(e) => {
                  setYear(e.target.value);
                  setCertifiedRatio('');
                }}
                className="certified-ratio-mvr-input"
              />
            </div>
            <div className="certified-ratio-mvr-field">
              <label htmlFor="certifiedRatio" className="certified-ratio-mvr-label">Certified Ratio %:</label>
              <input
                type="number"
                id="certifiedRatio"
                step="0.01"
                value={certifiedRatio}
                onChange={(e) => setCertifiedRatio(e.target.value)}
                className="certified-ratio-mvr-input"
              />
            </div>
          </div>
          <div className="certified-ratio-mvr-button-container">
            <button type="button" onClick={handleLoadData} className="certified-ratio-mvr-button">Load Data</button>
            <button type="submit" className="certified-ratio-mvr-button">
              {isDefaultSet ? 'Update Certified Ratio' : 'Save Default Municipality'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CertifiedRatioMVR;