import { useState, useEffect } from 'react';
import axiosInstance from '../../../axiosInstance'; // Use axiosInstance for consistent API calls

const useLandData = (accountNumber) => {
  const [landTypes, setLandTypes] = useState([]);
  const [accountLandData, setAccountLandData] = useState([]);
  const [frontageDepthData, setFrontageDepthData] = useState({ frontage: '', depth: '' });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    console.log('useLandData hook - accountNumber:', accountNumber);
    const fetchData = async () => {
      if (!accountNumber) {
        console.error('No account number provided');
        setError('No account number provided');
        setLoading(false);
        return;
      }
      try {
        const [landTypesResponse, accountLandDataResponse, frontageDepthResponse] = await Promise.all([
          axiosInstance.get('/api/land-data/land-types'),
          axiosInstance.get(`/api/land-data/account/${accountNumber}`),
          axiosInstance.get(`/api/land-data/account/${accountNumber}/frontage-depth`),
        ]);

        setLandTypes(landTypesResponse.data || []);
        setAccountLandData(accountLandDataResponse.data || []);
        setFrontageDepthData(frontageDepthResponse.data || { frontage: '', depth: '' });
        setLoading(false);
      } catch (err) {
        console.error('Error fetching land data:', err);
        setError('Error fetching land data');
        setLoading(false);
      }
    };

    fetchData();
  }, [accountNumber]);

  const calculateTotalLandValue = (landData) => {
    return landData.reduce((total, land) => {
      return total + (parseFloat(land.adjustedCost) || 0);
    }, 0);
  };

  // Modify saveLandData to refetch accountLandData from the backend
  const saveLandData = async (data) => {
    try {
      console.log('Saving land data:', data);
      const sanitizedData = {
        ...data,
        acres: data.acres === '' ? null : data.acres,
        percentage_factor: data.percentage_factor === '' ? null : data.percentage_factor,
        adjustedCost: data.adjustedCost === '' ? null : data.adjustedCost,
        value_per_acre: data.value_per_acre === '' ? null : data.value_per_acre,
      };
      await axiosInstance.put(`/api/land-data/account/${accountNumber}/${data.id}`, sanitizedData);

      // Refetch the updated land data from the backend
      const updatedLandDataResponse = await axiosInstance.get(`/api/land-data/account/${accountNumber}`);
      setAccountLandData(updatedLandDataResponse.data || []);

      // Update the currentTotalLandValue in the accounts table
      await axiosInstance.post(`/api/land-data/account/${accountNumber}/update-total-land-value`);

    } catch (err) {
      console.error('Error saving land data:', err);
      setError('Error saving land data');
      throw err;
    }
  };

  // Modify deleteLandData to refetch accountLandData from the backend
  const deleteLandData = async (id) => {
    try {
      await axiosInstance.delete(`/api/land-data/account/${accountNumber}/${id}`);
  
      // Refetch the updated land data from the backend
      const updatedLandDataResponse = await axiosInstance.get(`/api/land-data/account/${accountNumber}`);
      setAccountLandData(updatedLandDataResponse.data || []);
  
      // Calculate the new total land value
      const newTotalLandValue = calculateTotalLandValue(updatedLandDataResponse.data || []);
  
      // Update the currentTotalLandValue in the accounts table
      await axiosInstance.post(`/api/land-data/account/${accountNumber}/update-total-land-value`, {
        totalLandValue: newTotalLandValue
      });
  
      // If you have a function to update the property info in the parent component, call it here
      // For example: updatePropertyInfo({ landValue: newTotalLandValue });
  
    } catch (err) {
      console.error('Error deleting land data:', err);
      setError('Error deleting land data');
      throw err;
    }
  };

  // Modify addLandType to refetch accountLandData from the backend
  const addLandType = async (accountNum) => {
    try {
      console.log('Adding new land type for account:', accountNum);
      const newLandData = {
        accountNumber: accountNum,
        land_type_id: null,
        acres: null,
        percentage_factor: 100,
        factor_reason: '',
        adjustedCost: null,
        value_per_acre: null,
      };
      console.log('New land data to be sent:', newLandData);
      await axiosInstance.post(`/api/land-data/account/${accountNum}`, newLandData);

      // Refetch the updated land data from the backend
      const updatedLandDataResponse = await axiosInstance.get(`/api/land-data/account/${accountNumber}`);
      setAccountLandData(updatedLandDataResponse.data || []);
    } catch (err) {
      console.error('Error adding new land type:', err);
      setError('Error adding new land type');
      throw err;
    }
  };

  const saveFrontageDepthData = async (data) => {
    try {
      console.log('Saving frontage and depth data:', data);
      const response = await axiosInstance.post(`/api/land-data/account/${accountNumber}/frontage-depth`, data);
      setFrontageDepthData(response.data);
      return response.data;
    } catch (err) {
      console.error('Error saving frontage and depth data:', err);
      setError('Error saving frontage and depth data');
      throw err;
    }
  };

  return {
    landTypes,
    accountLandData,
    frontageDepthData,
    loading,
    error,
    saveLandData,
    deleteLandData,
    addLandType,
    saveFrontageDepthData,
    calculateTotalLandValue,
  };
};

export default useLandData;
