import React, { useState } from 'react';
import './Help.css';

const HelpSection = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="help-section">
      <div className="help-section-header" onClick={() => setIsOpen(!isOpen)}>
        {title}
        <span>{isOpen ? '▲' : '▼'}</span>
      </div>
      {isOpen && <div className="help-section-content">{children}</div>}
    </div>
  );
};

const HelpSubSection = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="help-subsection">
      <div className="help-subsection-header" onClick={() => setIsOpen(!isOpen)}>
        {title}
        <span>{isOpen ? '▲' : '▼'}</span>
      </div>
      {isOpen && <div className="help-subsection-content">{children}</div>}
    </div>
  );
};

const Help = () => {
  return (
    <div className="help-container">
      <div className="help-content">
        <h1 className="help-title">Assessor Help Guide</h1>
        
        <p>Welcome to the Municipal Property Assessment Web Application. This guide will help you navigate and use the various features of the system effectively. Click on each section to expand and view more details.</p>

        <HelpSection title="Getting Started">
          <HelpSubSection title="Logging In">
            <p>To access the system, use your provided credentials. If you're having trouble logging in, contact your system administrator.</p>
          </HelpSubSection>

          <HelpSubSection title="Navigation">
            <p>Use the sidebar menu to access different sections of the application. Each section is designed to help you manage various aspects of property assessment.</p>
          </HelpSubSection>
        </HelpSection>

        <HelpSection title="Application Features">
          <HelpSubSection title="Home">
            <p>The Home page provides an overview of key information and quick access to frequently used features. You can view recent updates, pending tasks, and important notices here.</p>
          </HelpSubSection>

          <HelpSubSection title="Account Search">
            <p>Use the Account Search page to find property accounts. You can search by account number, address, owner name, or other criteria. Advanced search options are available for more specific queries.</p>
          </HelpSubSection>

          <HelpSubSection title="New Account">
            <p>Create new property accounts using the New Account page. Ensure all required fields are filled out accurately. The system will guide you through the process of entering property details, owner information, and initial assessed values.</p>
          </HelpSubSection>

          <HelpSubSection title="Property Account">
            <p>Update existing property accounts on the Property Account page. You can modify property characteristics, update owner information, and adjust assessed values. Remember to save your changes before navigating away from the page.</p>
          </HelpSubSection>

          <HelpSubSection title="Reports">
            <p>Generate various reports using the Reports page. You can create standard reports or customize reports based on specific criteria. Reports can be exported in different formats for further analysis or presentation.</p>
          </HelpSubSection>

          <HelpSubSection title="Global Values">
            <p>Manage system-wide parameters and values used in calculations and assessments on the Global Values page. Only authorized users should modify these values, as they affect all property assessments in the system.</p>
          </HelpSubSection>

          <HelpSubSection title="Property Owners">
            <p>The Property Owners page allows you to manage owner information. You can view all property owners, and update their details.</p>
          </HelpSubSection>
        </HelpSection>

        <HelpSection title="Assessment Process">
          <HelpSubSection title="Valuation Methods">
            <p>This system supports multiple valuation methods including cost approach, sales comparison, and income approach. Select the appropriate method based on the property type and available data.</p>
          </HelpSubSection>

          <HelpSubSection title="Data Entry Tips">
            <p>Ensure accuracy in data entry. Double-check all inputs, especially numerical values. Use the notes fields to document any unusual circumstances or considerations for each property.</p>
          </HelpSubSection>

          <HelpSubSection title="Bulk Operations">
            <p>For efficiency, you can perform bulk operations on multiple properties. Use caution when applying bulk changes and always review the results.</p>
          </HelpSubSection>
        </HelpSection>

        <HelpSection title="Best Practices">
          <ul>
            <li>Regularly save your work to prevent data loss.</li>
            <li>Use consistent naming conventions for properties and owners.</li>
            <li>Review generated reports for accuracy before finalizing.</li>
            <li>Keep detailed notes on property assessments for future reference.</li>
            <li>Stay updated on local and state property tax regulations.</li>
          </ul>
        </HelpSection>

        <HelpSection title="Keyboard Shortcuts">
          <p>Use these keyboard shortcuts to navigate the application more efficiently:</p>
          <ul>
            <li><strong>Ctrl + S</strong>: Save current changes</li>
            <li><strong>Ctrl + F</strong>: Open search function</li>
            <li><strong>Ctrl + N</strong>: Create new account</li>
            <li><strong>Esc</strong>: Close current popup or return to previous screen</li>
          </ul>
        </HelpSection>

        <HelpSection title="Troubleshooting">
          <HelpSubSection title="Common Issues">
            <p>If you encounter issues like slow performance or calculation errors, try clearing your browser cache or refreshing the page. For persistent issues, contact technical support.</p>
          </HelpSubSection>

          <HelpSubSection title="Data Discrepancies">
            <p>If you notice data discrepancies, document the issue and report it to your supervisor or the system administrator for investigation.</p>
          </HelpSubSection>
        </HelpSection>

        <HelpSection title="Resources">
          <HelpSubSection title="MRS Municipal Assistance">
            <p>Access Maine Revenue Services resources for additional guidance:</p>
            <HelpSubSection title="Tax Bulletins">
              <p>Access Maine Revenue Services tax bulletins for detailed information on various tax topics.</p>
              <a href="https://www.maine.gov/revenue/taxes/property-tax/bulletins" target="_blank" rel="noopener noreferrer" className="help-link">
                View Tax Bulletins
              </a>
            </HelpSubSection>

            <HelpSubSection title="Guidance Documents and Publications">
              <p>Find comprehensive guides and publications on Maine tax laws and regulations.</p>
              <a href="https://www.maine.gov/revenue/taxes/property-tax/guidance-documents-publications" target="_blank" rel="noopener noreferrer" className="help-link">
                View Guidance Documents and Publications
              </a>
            </HelpSubSection>

            <HelpSubSection title="Property Tax Exemptions">
              <p>Learn about various property tax exemptions available in Maine.</p>
              <a href="https://www.maine.gov/revenue/taxes/tax-relief-credits-programs/property-tax-relief-programs/property-tax-exemptions" target="_blank" rel="noopener noreferrer" className="help-link">
                Explore Property Tax Exemptions
              </a>
            </HelpSubSection>

            <HelpSubSection title="Property Tax Law">
              <p>Access information on Maine's property tax laws and regulations.</p>
              <a href="https://www.maine.gov/revenue/taxes/property-tax/law" target="_blank" rel="noopener noreferrer" className="help-link">
                Read Property Tax Law
              </a>
            </HelpSubSection>

            <HelpSubSection title="State Valuation">
              <p>Understand the process and importance of state valuation in Maine.</p>
              <a href="https://www.maine.gov/revenue/taxes/property-tax/state-valuation" target="_blank" rel="noopener noreferrer" className="help-link">
                Learn about State Valuation
              </a>
            </HelpSubSection>

            <HelpSubSection title="Contact Information">
              <p>For additional assistance, please contact Maine Revenue Services:</p>
              <p>Phone: (207) 624-5600</p>
              <p>Email: prop.tax@maine.gov</p>
              <a href="https://www.maine.gov/revenue/taxes/property-tax/contact-information" target="_blank" rel="noopener noreferrer" className="help-link">
                Visit Contact Page
              </a>
            </HelpSubSection>
          </HelpSubSection>

          <HelpSubSection title="Contact Support">
            <p>For technical support or questions about using this application, contact:</p>
            <p>Email: [Support Email]</p>
            <p>Phone: [Support Phone Number]</p>
          </HelpSubSection>
        </HelpSection>

        <HelpSection title="Glossary">
          <p>Quick reference for common terms used in property assessment:</p>
          <ul>
            <li><strong>Assessed Value</strong>: The dollar value assigned to a property for purposes of measuring applicable taxes.</li>
            <li><strong>Mill Rate</strong>: The amount of tax payable per dollar of the assessed value of a property.</li>
            <li><strong>Abatement</strong>: A reduction in the assessed value of a property after the owner has proven that the original assessment was incorrect.</li>
            <li><strong>CAMA</strong>: Computer Assisted Mass Appraisal, a system used for property valuation.</li>
            <li><strong>FMV</strong>: Fair Market Value, the estimated sale price of a property in the open market.</li>
          </ul>
        </HelpSection>
        
      </div>
    </div>
  );
};

export default Help;
