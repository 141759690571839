import { useState, useEffect } from 'react';
import axiosInstance from '../../../axiosInstance';

const useTreeGrowthRates = () => {
  const [treeGrowthRates, setTreeGrowthRates] = useState({
    id: null,
    softwoodvalue: '',
    mixedwoodvalue: '',
    hardwoodvalue: ''
  });
  const [updateSuccess, setUpdateSuccess] = useState('');
  const [dataExists, setDataExists] = useState(false);

  useEffect(() => {
    fetchTreeGrowthRates();
  }, []);

  const fetchTreeGrowthRates = async () => {
    try {
      const response = await axiosInstance.get('/api/tree-growth-rates');
      setDataExists(response.data.dataExists);
      if (response.data.dataExists) {
        setTreeGrowthRates(response.data.data);
      }
    } catch (error) {
      console.error('Error fetching tree growth rates:', error);
    }
  };

  const handleTreeGrowthRatesChange = (field, value) => {
    setTreeGrowthRates({ ...treeGrowthRates, [field]: value });
  };

  const handleUpdateTreeGrowthRates = async () => {
    try {
      const response = await axiosInstance.put('/api/tree-growth-rates', treeGrowthRates);
      
      if (response.data.success) {
        setUpdateSuccess(response.data.message);
        setDataExists(true);
        setTreeGrowthRates(prevState => ({ ...prevState, id: response.data.id }));
        setTimeout(() => setUpdateSuccess(''), 3000);
      }
    } catch (error) {
      console.error('Error updating tree growth rates:', error);
    }
  };

  return {
    treeGrowthRates,
    handleTreeGrowthRatesChange,
    handleUpdateTreeGrowthRates,
    updateSuccess,
    dataExists
  };
};

export default useTreeGrowthRates;