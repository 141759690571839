import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import axiosInstance from '../../../axiosInstance';
import getApiUrl from '../../../config';
import './Modal.css';
import { toast } from 'react-toastify';
import DeedInfoForm from '../components/DeedInfoForm';

const EditPreviousOwnerModal = ({ isOpen, onClose, accountNumber, owner, onOwnerUpdated, originalDeedInfo }) => {
    const [deedInfo, setDeedInfo] = useState({
        deedBook: '',
        deedPage: '',
        deedDate: '',
    });
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        if (isOpen && owner) {
            setDeedInfo({
                deedBook: owner.deedBook || '',
                deedPage: owner.deedPage || '',
                deedDate: owner.deedDate ? new Date(owner.deedDate).toISOString().split('T')[0] : '',
            });
        } else {
            setDeedInfo({
                deedBook: '',
                deedPage: '',
                deedDate: '',
            });
        }
    }, [isOpen, owner]);

    const handleDeedInfoChange = useCallback((e) => {
        const { name, value } = e.target;
        setDeedInfo(prevInfo => ({ ...prevInfo, [name]: value }));
    }, []);

    const handleSaveChanges = async () => {
        if (!deedInfo.deedBook || !deedInfo.deedPage || !deedInfo.deedDate) {
            toast.error('Please fill in all deed information fields.');
            return;
        }

        if (!window.confirm('Are you sure you want to update the deed information? This will affect all linked owners.')) {
            return;
        }

        setIsSubmitting(true);

        try {
            if (!originalDeedInfo || !originalDeedInfo.deedBook || !originalDeedInfo.deedPage || !originalDeedInfo.deedDate) {
                throw new Error('Original deed info is incomplete or missing.');
            }

            const apiUrl = getApiUrl();
            const response = await axiosInstance.put(`${apiUrl}/api/properties/${accountNumber}/previous-owners/update-all`, {
                originalDeedBook: originalDeedInfo.deedBook,
                originalDeedPage: originalDeedInfo.deedPage,
                originalDeedDate: originalDeedInfo.deedDate ? new Date(originalDeedInfo.deedDate).toISOString().split('T')[0] : '',
                deedBook: deedInfo.deedBook,
                deedPage: deedInfo.deedPage,
                deedDate: deedInfo.deedDate,
            });

            if (response.data.success) {
                toast.success('Deed info updated successfully for all linked owners.');
                onOwnerUpdated();
                onClose();
            } else {
                console.error('Server responded with an error:', response.data);
                toast.error(response.data.error || 'Failed to update deed info. Please check the console for more details.');
            }
        } catch (error) {
            console.error('Error updating deed info:', error);
            console.error('Error response:', error.response?.data);
            toast.error(`Failed to update deed info: ${error.message}. Please check the console for more details.`);
        } finally {
            setIsSubmitting(false);
        }
    };

    if (!isOpen || !owner) return null;

    // Add a check for originalDeedInfo
    if (!originalDeedInfo || !originalDeedInfo.deedBook || !originalDeedInfo.deedPage || !originalDeedInfo.deedDate) {
        return (
            <div className="modal-overlay" role="dialog" aria-modal="true">
                <div className="modal-content">
                    <h2>Error</h2>
                    <p>Original deed information is missing or incomplete. Unable to edit.</p>
                    <button onClick={onClose}>Close</button>
                </div>
            </div>
        );
    }

    return (
        <div className="modal-overlay" role="dialog" aria-modal="true" aria-labelledby="edit-previous-owner-modal-title">
            <div className="modal-content">
                <h2 id="edit-previous-owner-modal-title">Edit Previous Owner/Deed Info</h2>
                <button className="close-button" onClick={onClose} aria-label="Close Modal">×</button>
                <DeedInfoForm deedInfo={deedInfo} onChange={handleDeedInfoChange} />
                <div className="button-container">
                    <button type="button" onClick={handleSaveChanges} disabled={isSubmitting}>
                        {isSubmitting ? 'Saving...' : 'Save Changes'}
                    </button>
                </div>
            </div>
        </div>
    );
};

EditPreviousOwnerModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    accountNumber: PropTypes.string.isRequired,
    owner: PropTypes.object,
    onOwnerUpdated: PropTypes.func.isRequired,
    originalDeedInfo: PropTypes.shape({
        deedBook: PropTypes.string,
        deedPage: PropTypes.string,
        deedDate: PropTypes.string,
    }),
};

export default EditPreviousOwnerModal;