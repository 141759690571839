import { useState, useEffect, useCallback } from 'react';
import axiosInstance from '../../../axiosInstance';
import getApiUrl from '../../../config'; // Adjust the path as needed

const useNeighborhoodFactor = () => {
  const [neighborhoodFactors, setNeighborhoodFactors] = useState([]);
  const [newFactor, setNewFactor] = useState({
    name: '',
    impact_type: 'Both',
    application_method: 'Percentage',
    factor_value: '',
    description: ''
  });
  const [error, setError] = useState(null);
  const [updateSuccess, setUpdateSuccess] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchFactors = useCallback(async () => {
    try {
      setLoading(true);
      const apiUrl = getApiUrl();
      const response = await axiosInstance.get(`${apiUrl}/api/neighborhood-factors`);
      console.log('Fetched neighborhood factors:', response.data);
      setNeighborhoodFactors(Array.isArray(response.data) ? response.data : []);
      setError(null);
    } catch (error) {
      const errorMessage = error.response?.data?.error || 'Error fetching neighborhood factors';
      const errorDetails = error.response?.data?.details || error.message;
      setError(`${errorMessage}: ${errorDetails}`);
      console.error('Error fetching neighborhood factors:', error);
      setNeighborhoodFactors([]);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchFactors();
  }, [fetchFactors]);

  const handleAddFactor = async () => {
    try {
      const apiUrl = getApiUrl();
      const response = await axiosInstance.post(`${apiUrl}/api/neighborhood-factors`, newFactor);
      setNeighborhoodFactors(prevFactors => [...prevFactors, { ...newFactor, id: response.data.id }]);
      setNewFactor({
        name: '',
        impact_type: 'Both',
        application_method: 'Percentage',
        factor_value: '',
        description: ''
      });
      setUpdateSuccess('Neighborhood factor added successfully');
      setError(null);
    } catch (error) {
      handleError(error, 'Error adding neighborhood factor');
    }
  };

  const handleUpdateFactor = async (id, updatedFactor) => {
    try {
      const apiUrl = getApiUrl();
      await axiosInstance.put(`${apiUrl}/api/neighborhood-factors/${id}`, updatedFactor);
      setNeighborhoodFactors(prevFactors => 
        prevFactors.map(factor => factor.id === id ? { ...factor, ...updatedFactor } : factor)
      );
      setUpdateSuccess('Neighborhood factor updated successfully');
      setError(null);
    } catch (error) {
      handleError(error, 'Error updating neighborhood factor');
    }
  };

  const handleDeleteFactor = async (id) => {
    try {
      const apiUrl = getApiUrl();
      await axiosInstance.delete(`${apiUrl}/api/neighborhood-factors/${id}`);
      setNeighborhoodFactors(prevFactors => prevFactors.filter(factor => factor.id !== id));
      setUpdateSuccess('Neighborhood factor deleted successfully');
      setError(null);
    } catch (error) {
      handleError(error, 'Error deleting neighborhood factor');
    }
  };

  const handleFactorInputChange = (index, field, value) => {
    setNeighborhoodFactors(prevFactors => {
      const updatedFactors = [...prevFactors];
      updatedFactors[index] = { ...updatedFactors[index], [field]: value };
      return updatedFactors;
    });
  };

  const handleError = (error, defaultMessage) => {
    const errorMessage = error.response?.data?.error || defaultMessage;
    const errorDetails = error.response?.data?.details || error.message;
    setError(`${errorMessage}: ${errorDetails}`);
    console.error(`${defaultMessage}:`, error);
  };

  const clearMessages = () => {
    setError(null);
    setUpdateSuccess(null);
  };

  return {
    neighborhoodFactors,
    newFactor,
    setNewFactor,
    handleAddFactor,
    handleUpdateFactor,
    handleDeleteFactor,
    handleFactorInputChange,
    error,
    updateSuccess,
    clearMessages,
    loading
  };
};

export default useNeighborhoodFactor;