import React, { useState, useEffect } from 'react';
import axiosInstance from '../../axiosInstance';
import { useNavigate, useLocation } from 'react-router-dom';
import getApiUrl from '../../config';
import './AccountSearch.css';

const AccountSearch = () => {
    const location = useLocation();
    const [searchParams, setSearchParams] = useState({
        map: '',
        lot: '',
        streetNumber: '',
        streetName: '',
        accountNumber: '',
        book: '',
        page: '',
    });

    const [results, setResults] = useState([]);
    const [disableFields, setDisableFields] = useState({
        map: false,
        lot: false,
        streetNumber: false,
        streetName: false,
        accountNumber: false,
        book: false,
        page: false,
    });

    const navigate = useNavigate();

    useEffect(() => {
        if (location.state) {
            setSearchParams(location.state.searchParams);
            setResults(location.state.results);
            updateDisableFields(location.state.searchParams);
        }
    }, [location.state]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        const newSearchParams = { ...searchParams, [name]: value };
        setSearchParams(newSearchParams);
        updateDisableFields(newSearchParams);
    };

    const updateDisableFields = (params) => {
        const { map, lot, streetNumber, streetName, accountNumber, book, page } = params;
        const isMapOrLotFilled = map || lot;
        const isStreetNumberOrNameFilled = streetNumber || streetName;
        const isBookOrPageFilled = book || page;
        const isAccountNumberFilled = accountNumber;

        const disableAllOthers = isMapOrLotFilled || isStreetNumberOrNameFilled || isBookOrPageFilled || isAccountNumberFilled;

        setDisableFields({
            map: disableAllOthers && !isMapOrLotFilled,
            lot: disableAllOthers && !isMapOrLotFilled,
            streetNumber: disableAllOthers && !isStreetNumberOrNameFilled,
            streetName: disableAllOthers && !isStreetNumberOrNameFilled,
            accountNumber: disableAllOthers && !isAccountNumberFilled,
            book: disableAllOthers && !isBookOrPageFilled,
            page: disableAllOthers && !isBookOrPageFilled,
        });
    };

    const handleSearch = async (e) => {
        e.preventDefault();
        const apiUrl = getApiUrl();
        try {
            console.log('API URL:', apiUrl);
            console.log('Sending search request with params:', searchParams);
            const response = await axiosInstance.post(`${apiUrl}/api/search`, searchParams);
            console.log('Search response:', response.data);
            setResults(response.data);
        } catch (error) {
            console.error('Error searching accounts', error);
        }
    };

    const handleResultClick = (accountNumber) => {
        navigate(`/property-tax-card/${accountNumber}`, { state: { searchParams, results } });
    };

    return (
        <div className="account-search-container">
            <div className="account-search-content">
                <h1 className="account-search-title">Account Search</h1>
                <form onSubmit={handleSearch} className="account-search-form">
                    <div className="account-search-grid">
                        <div className="account-search-field full-width">
                            <label htmlFor="accountNumber" className="account-search-label">Account Number</label>
                            <input
                                type="text"
                                id="accountNumber"
                                name="accountNumber"
                                value={searchParams.accountNumber}
                                onChange={handleChange}
                                className="account-search-input"
                                disabled={disableFields.accountNumber}
                            />
                        </div>
                        <div className="account-search-field">
                            <label htmlFor="map" className="account-search-label">Map</label>
                            <input
                                type="text"
                                id="map"
                                name="map"
                                value={searchParams.map}
                                onChange={handleChange}
                                className="account-search-input"
                                disabled={disableFields.map}
                            />
                        </div>
                        <div className="account-search-field">
                            <label htmlFor="lot" className="account-search-label">Lot</label>
                            <input
                                type="text"
                                id="lot"
                                name="lot"
                                value={searchParams.lot}
                                onChange={handleChange}
                                className="account-search-input"
                                disabled={disableFields.lot}
                            />
                        </div>
                        <div className="account-search-field">
                            <label htmlFor="streetNumber" className="account-search-label">Street Number</label>
                            <input
                                type="text"
                                id="streetNumber"
                                name="streetNumber"
                                value={searchParams.streetNumber}
                                onChange={handleChange}
                                className="account-search-input"
                                disabled={disableFields.streetNumber}
                            />
                        </div>
                        <div className="account-search-field">
                            <label htmlFor="streetName" className="account-search-label">Street Name</label>
                            <input
                                type="text"
                                id="streetName"
                                name="streetName"
                                value={searchParams.streetName}
                                onChange={handleChange}
                                className="account-search-input"
                                disabled={disableFields.streetName}
                            />
                        </div>
                        <div className="account-search-field">
                            <label htmlFor="book" className="account-search-label">Book</label>
                            <input
                                type="text"
                                id="book"
                                name="book"
                                value={searchParams.book}
                                onChange={handleChange}
                                className="account-search-input"
                                disabled={disableFields.book}
                            />
                        </div>
                        <div className="account-search-field">
                            <label htmlFor="page" className="account-search-label">Page</label>
                            <input
                                type="text"
                                id="page"
                                name="page"
                                value={searchParams.page}
                                onChange={handleChange}
                                className="account-search-input"
                                disabled={disableFields.page}
                            />
                        </div>
                        <div className="account-search-button-container">
                            <button type="submit" className="account-search-button">Search</button>
                        </div>
                    </div>
                </form>
                {results.length > 0 && (
                    <div className="account-search-results">
                        <h2 className="account-search-results-title">Search Results</h2>
                        <div className="account-search-results-table-container">
                            <table className="account-search-results-table">
                                <thead>
                                    <tr>
                                        <th className="account-search-results-th">Account</th>
                                        <th className="account-search-results-th">Map</th>
                                        <th className="account-search-results-th">Lot</th>
                                        <th className="account-search-results-th">Address</th>
                                        <th className="account-search-results-th">Book</th>
                                        <th className="account-search-results-th">Page</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {results.map(result => (
                                        <tr key={result.accountNumber} onClick={() => handleResultClick(result.accountNumber)}>
                                            <td className="account-search-results-td">{result.accountNumber}</td>
                                            <td className="account-search-results-td">{result.map}</td>
                                            <td className="account-search-results-td">{result.lot}</td>
                                            <td className="account-search-results-td">{result.address}</td>
                                            <td className="account-search-results-td">{result.book}</td>
                                            <td className="account-search-results-td">{result.page}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default AccountSearch;
