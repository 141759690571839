import { useState, useEffect } from 'react';
import axiosInstance from '../../../axiosInstance';

const useGradingFactors = () => {
  const [gradingFactors, setGradingFactors] = useState([]);
  const [newGradingFactor, setNewGradingFactor] = useState({ grade: '', grade_modifier: '', factor: '' });
  const [updateSuccess, setUpdateSuccess] = useState('');

  useEffect(() => {
    fetchGradingFactors();
  }, []);

  const sortGradingFactors = (factors) => {
    return factors.sort((a, b) => parseFloat(b.factor) - parseFloat(a.factor));
  };

  const fetchGradingFactors = async () => {
    try {
      const response = await axiosInstance.get('/api/grading-factors');
      const sortedData = sortGradingFactors(response.data);
      setGradingFactors(sortedData);
    } catch (error) {
      console.error('Error fetching grading factors:', error);
    }
  };

  const handleAddGradingFactor = async () => {
    try {
      const response = await axiosInstance.post('/api/grading-factors', newGradingFactor);
      if (response.data.success) {
        const updatedGradingFactors = [
          ...gradingFactors,
          { ...newGradingFactor, id: response.data.id },
        ];
        const sortedGradingFactors = sortGradingFactors(updatedGradingFactors);
        setGradingFactors(sortedGradingFactors);
        setNewGradingFactor({ grade: '', grade_modifier: '', factor: '' });
      }
    } catch (error) {
      console.error('Error adding grading factor:', error);
    }
  };

  const handleUpdateGradingFactor = async (id, updatedGradingFactor) => {
    try {
      const response = await axiosInstance.put(`/api/grading-factors/${id}`, updatedGradingFactor);
      if (response.data.success) {
        const updatedGradingFactors = gradingFactors.map((gf) =>
          gf.id === id ? updatedGradingFactor : gf
        );
        const sortedGradingFactors = sortGradingFactors(updatedGradingFactors);
        setGradingFactors(sortedGradingFactors);
        setUpdateSuccess('Grading factor updated successfully!');
        setTimeout(() => setUpdateSuccess(''), 3000);
      }
    } catch (error) {
      console.error('Error updating grading factor:', error);
    }
  };

  const handleDeleteGradingFactor = async (id) => {
    try {
      const response = await axiosInstance.delete(`/api/grading-factors/${id}`);
      if (response.data.success) {
        const updatedGradingFactors = gradingFactors.filter((gf) => gf.id !== id);
        const sortedGradingFactors = sortGradingFactors(updatedGradingFactors);
        setGradingFactors(sortedGradingFactors);
      }
    } catch (error) {
      console.error('Error deleting grading factor:', error);
    }
  };

  const handleGradingFactorInputChange = (index, field, value) => {
    const updatedGradingFactors = [...gradingFactors];
    updatedGradingFactors[index][field] = value;
    setGradingFactors(updatedGradingFactors);
  };

  return {
    gradingFactors,
    newGradingFactor,
    setNewGradingFactor,
    handleAddGradingFactor,
    handleUpdateGradingFactor,
    handleDeleteGradingFactor,
    handleGradingFactorInputChange,
    updateSuccess,
  };
};

export default useGradingFactors;
