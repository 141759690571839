import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { login } from '../../utils/auth';
import './Login.css';

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const handleLogin = async (e) => {
        e.preventDefault();
        setLoading(true); // Set loading state to true
        console.log('Login form submitted with:', { username, password });

        const success = await login(username, password);
        if (success) {
            console.log('Login successful, token set:', localStorage.getItem('token'));
            setTimeout(() => {
                navigate('/dashboard', { replace: true });
                window.location.reload(); // Force a refresh of the page
            }, 200); // Delay of 200 milliseconds
        } else {
            console.log('Login failed');
            alert('Login failed');
        }
        setLoading(false); // Set loading state to false
    };

    return (
        <div className="login-container">
            <div className="login-box">
                <h2>Login</h2>
                <form onSubmit={handleLogin}>
                    <input
                        type="text"
                        placeholder="Username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        disabled={loading} // Disable input while loading
                    />
                    <input
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        disabled={loading} // Disable input while loading
                    />
                    <button type="submit" disabled={loading}>
                        {loading ? 'Logging in...' : 'Login'}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default Login;
