import React, { useState, useEffect } from 'react';
import axiosInstance from '../../axiosInstance'; // Use axiosInstance instead of axios
import { useParams, useNavigate } from 'react-router-dom';
import './UserManagementEdit.css';

const UserManagementEdit = () => {
    const { id } = useParams(); // Get the user ID from the URL
    const navigate = useNavigate(); // Hook to navigate programmatically
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [role, setRole] = useState('');

    useEffect(() => {
        axiosInstance.get(`/api/users/${id}`)
            .then(response => {
                console.log('Fetched user:', response.data); // Log the fetched user data
                const user = response.data;
                setUsername(user.username);
                setEmail(user.email);
                setRole(user.role);
            })
            .catch(error => {
                console.error('There was an error fetching the user data!', error);
            });
    }, [id]);

    const handleSubmit = (e) => {
        e.preventDefault();
        axiosInstance.put(`/api/users/${id}`, { username, email, role })
            .then(() => {
                navigate('/usermanagement'); // Use navigate to redirect
            })
            .catch(error => {
                console.error('There was an error updating the user!', error);
            });
    };

    const handleChangePassword = () => {
        axiosInstance.post('/api/send-reset-link', { email })
            .then(() => {
                alert('Password reset link sent to the user\'s email.');
            })
            .catch(error => {
                console.error('There was an error sending the password reset link!', error);
            });
    };

    return (
        <div className="user-management-edit-container">
            <div className="user-management-edit-content">
                <h2 className="user-management-edit-title">Edit User</h2>
                <form className="user-management-edit-form" onSubmit={handleSubmit}>
                    <div>
                        <label>Username</label>
                        <input
                            type="text"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            required
                        />
                    </div>
                    <div>
                        <label>Email</label>
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <div>
                        <label>Role</label>
                        <select value={role} onChange={(e) => setRole(e.target.value)}>
                            <option value="admin">Admin</option>
                            <option value="read">Read</option>
                            <option value="read/write">Read/Write</option>
                        </select>
                    </div>
                    <button className="user-management-edit-button" type="submit">Save Changes</button>
                </form>
                <button className="user-management-edit-button" onClick={handleChangePassword}>Change Password</button>
            </div>
        </div>
    );
};

export default UserManagementEdit;
