import React from 'react';
import useGradingFactors from '../hooks/useGradingFactors';
import './GradingFactors.css';

const GradingFactors = () => {
  console.log('Rendering GradingFactors component');
  const {
    gradingFactors,
    newGradingFactor,
    setNewGradingFactor,
    handleAddGradingFactor,
    handleUpdateGradingFactor,
    handleDeleteGradingFactor,
    handleGradingFactorInputChange,
    updateSuccess
  } = useGradingFactors();

  return (
    <>
      {updateSuccess && <div className="update-success">{updateSuccess}</div>}
      <div className="grading-factors">
        <table className="grading-factors-table">
          <thead>
            <tr>
              <th>Grade</th>
              <th>+/-</th>
              <th>Factor</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {gradingFactors.map((gradingFactor, index) => (
              <tr key={gradingFactor.id}>
                <td>
                  <input
                    type="text"
                    value={gradingFactor.grade}
                    onChange={(e) => handleGradingFactorInputChange(index, 'grade', e.target.value)}
                    placeholder="Grade"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={gradingFactor.grade_modifier}
                    onChange={(e) => handleGradingFactorInputChange(index, 'grade_modifier', e.target.value)}
                    placeholder="+/-"
                  />
                </td>
                <td>
                  <input
                    type="number"
                    step="0.01"
                    value={gradingFactor.factor}
                    onChange={(e) => handleGradingFactorInputChange(index, 'factor', e.target.value)}
                    placeholder="Factor"
                  />
                </td>
                <td>
                  {/* Wrap the buttons inside a div with class "button-group" */}
                  <div className="button-group">
                    <button
                      onClick={() => handleUpdateGradingFactor(gradingFactor.id, gradingFactor)}
                      className="update-button"
                    >
                      Update
                    </button>
                    <button
                      onClick={() => handleDeleteGradingFactor(gradingFactor.id)}
                      className="delete-button"
                    >
                      Delete
                    </button>
                  </div>
                </td>
              </tr>
            ))}
            <tr className="new-grading-factor">
              <td>
                <input
                  type="text"
                  value={newGradingFactor.grade}
                  onChange={(e) => setNewGradingFactor({ ...newGradingFactor, grade: e.target.value })}
                  placeholder="New Grade"
                />
              </td>
              <td>
                <input
                  type="text"
                  value={newGradingFactor.grade_modifier}
                  onChange={(e) => setNewGradingFactor({ ...newGradingFactor, grade_modifier: e.target.value })}
                  placeholder="New +/-"
                />
              </td>
              <td>
                <input
                  type="number"
                  step="0.01"
                  value={newGradingFactor.factor}
                  onChange={(e) => setNewGradingFactor({ ...newGradingFactor, factor: e.target.value })}
                  placeholder="New Factor"
                />
              </td>
              <td>
                {/* Wrap the add button inside a div with class "button-group" */}
                <div className="button-group">
                  <button
                    onClick={handleAddGradingFactor}
                    className="add-button"
                  >
                    Add Grading Factor
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default GradingFactors;
