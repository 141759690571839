import React, { useEffect, useState } from 'react';
import useBasementTypes from '../hooks/useBasementTypes';
import './BasementTypes.css';
import axiosInstance from '../../../axiosInstance';

const BasementTypes = () => {
  console.log('Rendering BasementTypes component');
  const {
    basementTypes,
    newBasementType,
    setNewBasementType,
    handleAddBasementType,
    handleUpdateBasementType,
    handleDeleteBasementType,
    handleBasementTypeInputChange,
    updateSuccess
  } = useBasementTypes();

  const [mappings, setMappings] = useState({});

  useEffect(() => {
    fetchMappings();
  }, []);

  const fetchMappings = async () => {
    try {
      const response = await axiosInstance.get('/api/schema/mappings');
      setMappings(response.data.BasementTypes || {});
    } catch (error) {
      console.error('Error fetching mappings:', error);
    }
  };

  const getColumnName = (operation) => mappings[operation]?.column || 'type_name';

  return (
    <>
      {updateSuccess && <div className="update-success">{updateSuccess}</div>}
      <div className="basement-types">
        <table className="basement-types-table">
          <thead>
            <tr>
              <th>Basement Type</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {basementTypes.map((basementType, index) => (
              <tr key={basementType.id}>
                <td>
                  <input
                    type="text"
                    value={basementType[getColumnName('get')]}
                    onChange={(e) =>
                      handleBasementTypeInputChange(index, getColumnName('put'), e.target.value)
                    }
                    placeholder="Basement Type"
                  />
                </td>
                <td>
                  {/* Wrap the buttons inside a div with class "button-group" */}
                  <div className="button-group">
                    <button
                      onClick={() =>
                        handleUpdateBasementType(basementType.id, {
                          ...basementType,
                          [getColumnName('put')]: basementType[getColumnName('get')]
                        })
                      }
                      className="update-button"
                    >
                      Update
                    </button>
                    <button
                      onClick={() => handleDeleteBasementType(basementType.id)}
                      className="delete-button"
                    >
                      Delete
                    </button>
                  </div>
                </td>
              </tr>
            ))}
            <tr className="new-basement-type">
              <td>
                <input
                  type="text"
                  value={newBasementType[getColumnName('post')]}
                  onChange={(e) =>
                    setNewBasementType({
                      ...newBasementType,
                      [getColumnName('post')]: e.target.value
                    })
                  }
                  placeholder="New Basement Type"
                />
              </td>
              <td>
                {/* Wrap the add button inside a div with class "button-group" */}
                <div className="button-group">
                  <button onClick={handleAddBasementType} className="add-button">
                    Add Basement Type
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default BasementTypes;
