import React from 'react';
import Collapsible from './Collapsible';
import Exemptions from './components/Exemptions';
import LandSchedules from './components/LandSchedules';
import BuildingSchedules from './components/BuildingSchedules';
import StoryHeights from './components/StoryHeights';
import TreeGrowthRates from './components/TreeGrowthRates';
import GradingFactors from './components/GradingFactors';
import NeighborhoodFactor from './components/NeighborhoodFactor';
import Zones from './components/Zones';
import LandUse from './components/LandUse';
import Topography from './components/Topography';
import Utilities from './components/Utilities';
import StreetType from './components/StreetType';
import BuildingStyles from './components/BuildingStyles';
import FoundationTypes from './components/FoundationTypes';
import BasementTypes from './components/BasementTypes';
import ExteriorWallTypes from './components/ExteriorWallTypes';
import RoofingTypes from './components/RoofingTypes';
import RoofTypes from './components/RoofTypes';
import ErrorBoundary from './ErrorBoundary';

import './GlobalValues.css';

const GlobalValuesNew = () => {
  return (
    <div className="global-values-wrapper">
      <div className="global-values">
        <h1 className="global-values-title">Global Values</h1>

        <ErrorBoundary>
        <Collapsible title="Exemptions">
          <Exemptions />
        </Collapsible>
        </ErrorBoundary>

        <ErrorBoundary>
        <Collapsible title="Land Schedules">
          <LandSchedules />
        </Collapsible>
        </ErrorBoundary>

        <ErrorBoundary>
        <Collapsible title="Building Schedules">
          <BuildingSchedules />
        </Collapsible>
        </ErrorBoundary>

        <ErrorBoundary>
        <Collapsible title="Story Heights">
          <StoryHeights />
        </Collapsible>
        </ErrorBoundary>

        <ErrorBoundary>
        <Collapsible title="Tree Growth Rates">
          <TreeGrowthRates />
        </Collapsible>
        </ErrorBoundary>

        <ErrorBoundary>
        <Collapsible title="Grading Factors">
          <GradingFactors />
        </Collapsible>
        </ErrorBoundary>

        <ErrorBoundary>
        <Collapsible title="Neighborhood Factors">
          <NeighborhoodFactor />
        </Collapsible>
        </ErrorBoundary>

        <ErrorBoundary>
        <Collapsible title="Zone Use">
          <Zones />
        </Collapsible>
        </ErrorBoundary>

        <ErrorBoundary>
        <Collapsible title="Land Uses">
          <LandUse />
        </Collapsible>
        </ErrorBoundary>

        <ErrorBoundary>
        <Collapsible title="Topography">
          <Topography />
        </Collapsible>
        </ErrorBoundary>

        <ErrorBoundary>
        <Collapsible title="Utilities">
          <Utilities />
        </Collapsible>
        </ErrorBoundary>

        <ErrorBoundary>
        <Collapsible title="Street Types">
          <StreetType />
        </Collapsible>
        </ErrorBoundary>

        <ErrorBoundary>
        <Collapsible title="Building Styles">
          <BuildingStyles />
        </Collapsible>
        </ErrorBoundary>

        <ErrorBoundary>
        <Collapsible title="Foundation Types">
          <FoundationTypes />
        </Collapsible>
        </ErrorBoundary>

        <ErrorBoundary>
        <Collapsible title="Basement Types">
          <BasementTypes />
        </Collapsible>
        </ErrorBoundary>

        <ErrorBoundary>
        <Collapsible title="Exterior Wall Types">
          <ExteriorWallTypes />
        </Collapsible>
        </ErrorBoundary>

        <ErrorBoundary>
        <Collapsible title="Roofing Types">
          <RoofingTypes />
        </Collapsible>
        </ErrorBoundary>

        <ErrorBoundary>
        <Collapsible title="Roof Types">
          <RoofTypes />
        </Collapsible>
        </ErrorBoundary>
      </div>
    </div>
  );
};

export default GlobalValuesNew;
