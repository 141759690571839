// src/hooks/useCloseSidebarOnFocus.js
import { useEffect } from 'react';

const useCloseSidebarOnFocus = (toggleSidebar) => {
  useEffect(() => {
    const handleFocus = (event) => {
      if (window.innerWidth < 768) {
        toggleSidebar(true);
      }
    };

    const inputs = document.querySelectorAll('input');

    inputs.forEach((input) => {
      input.addEventListener('focus', handleFocus);
    });

    return () => {
      inputs.forEach((input) => {
        input.removeEventListener('focus', handleFocus);
      });
    };
  }, [toggleSidebar]);
};

export default useCloseSidebarOnFocus;
