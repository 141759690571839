import React, { useState, useRef, useEffect } from 'react';
import { Stage, Layer, Line, Text, Circle, Rect } from 'react-konva';
import { BsEraser } from 'react-icons/bs';
import './Sketch.css';

const GRID_SIZE = 20;
const SNAP_THRESHOLD = 10;

const Sketch = ({ data, onUpdate }) => {
  const [lines, setLines] = useState(data.lines || []);
  const [labels, setLabels] = useState(data.labels || []);
  const [drawing, setDrawing] = useState(false);
  const [currentLine, setCurrentLine] = useState([]);
  const [mode, setMode] = useState('draw');
  const [scale, setScale] = useState(1);
  const [selectedElement, setSelectedElement] = useState(null);
  const [showGrid, setShowGrid] = useState(true);
  const [history, setHistory] = useState([]);
  const [historyStep, setHistoryStep] = useState(0);
  const [stageScale, setStageScale] = useState(1);
  const [stageX, setStageX] = useState(0);
  const [stageY, setStageY] = useState(0);
  const stageRef = useRef(null);

  useEffect(() => {
    onUpdate({ lines, labels });
  }, [lines, labels, onUpdate]);
  
  useEffect(() => {
    setHistory([...history.slice(0, historyStep), { lines, labels }]);
    setHistoryStep(historyStep + 1);
  }, [lines, labels]);

  const snapToGrid = (x, y) => {
    const snappedX = Math.round(x / GRID_SIZE) * GRID_SIZE;
    const snappedY = Math.round(y / GRID_SIZE) * GRID_SIZE;
    return { x: snappedX, y: snappedY };
  };

  const snapToNearestPoint = (x, y) => {
    let nearestPoint = { x, y };
    let minDistance = Infinity;

    lines.forEach(line => {
      for (let i = 0; i < line.points.length; i += 2) {
        const distance = Math.sqrt(
          Math.pow(x - line.points[i], 2) + Math.pow(y - line.points[i + 1], 2)
        );
        if (distance < minDistance && distance < SNAP_THRESHOLD) {
          minDistance = distance;
          nearestPoint = { x: line.points[i], y: line.points[i + 1] };
        }
      }
    });

    return nearestPoint;
  };

  const handleMouseDown = (e) => {
    const stage = e.target.getStage();
    const pos = stage.getPointerPosition();
    const snappedPos = snapToNearestPoint(snapToGrid(pos.x, pos.y).x, snapToGrid(pos.y, pos.y).y);

    if (mode === 'draw') {
      setDrawing(true);
      setCurrentLine([snappedPos.x, snappedPos.y]);
    } else if (mode === 'label') {
      handleLabelAdd(snappedPos);
    } else if (mode === 'edit' || mode === 'delete') {
      const clickedElement = stage.getIntersection(pos);
      setSelectedElement(clickedElement);
    }
  };

  const handleMouseMove = (e) => {
    if (!drawing) return;
    const stage = e.target.getStage();
    const pos = stage.getPointerPosition();
    const snappedPos = snapToNearestPoint(snapToGrid(pos.x, pos.y).x, snapToGrid(pos.y, pos.y).y);
    setCurrentLine(currentLine.concat([snappedPos.x, snappedPos.y]));
  };

  const handleMouseUp = () => {
    if (mode === 'draw') {
      setDrawing(false);
      if (currentLine.length >= 4) {
        const newLine = {
          points: currentLine,
          length: calculateLineLength(currentLine)
        };
        setLines([...lines, newLine]);
      }
      setCurrentLine([]);
    } else if (mode === 'delete' && selectedElement) {
      if (selectedElement.getAttr('lineIndex') !== undefined) {
        setLines(lines.filter((_, i) => i !== selectedElement.getAttr('lineIndex')));
      } else if (selectedElement.getClassName() === 'Text') {
        setLabels(labels.filter((_, i) => i !== selectedElement.index));
      }
    }
    setSelectedElement(null);
  };

  const handleLabelAdd = (pos) => {
    const newLabel = {
      x: pos.x,
      y: pos.y,
      text: 'New Label',
    };
    setLabels([...labels, newLabel]);
  };

  const handleLabelEdit = (index, newText) => {
    const updatedLabels = [...labels];
    updatedLabels[index].text = newText;
    setLabels(updatedLabels);
  };

  const handleScaleChange = (e) => {
    setScale(Number(e.target.value));
  };

  const calculateLineLength = (points) => {
    let length = 0;
    for (let i = 0; i < points.length - 2; i += 2) {
      const dx = points[i + 2] - points[i];
      const dy = points[i + 3] - points[i + 1];
      length += Math.sqrt(dx * dx + dy * dy);
    }
    return (length / scale).toFixed(2);
  };

  const calculateArea = () => {
    if (lines.length < 3) return 0;
    let area = 0;
    const flatPoints = lines.flatMap(line => line.points);
    for (let i = 0; i < flatPoints.length; i += 2) {
      const j = (i + 2) % flatPoints.length;
      area += flatPoints[i] * flatPoints[j + 1];
      area -= flatPoints[j] * flatPoints[i + 1];
    }
    area = Math.abs(area) / 2;
    return (area / (scale * scale)).toFixed(2);
  };

  const renderGrid = () => {
    const gridLines = [];
    for (let i = 0; i < 800; i += GRID_SIZE) {
      gridLines.push(
        <Line key={`v${i}`} points={[i, 0, i, 600]} stroke="#ddd" strokeWidth={0.5} />,
        <Line key={`h${i}`} points={[0, i, 800, i]} stroke="#ddd" strokeWidth={0.5} />
      );
      // Add grid scale labels
      if (i % (GRID_SIZE * 5) === 0) {
        gridLines.push(
          <Text key={`vt${i}`} x={i} y={5} text={`${i / GRID_SIZE / scale}`} fontSize={10} />,
          <Text key={`ht${i}`} x={5} y={i} text={`${i / GRID_SIZE / scale}`} fontSize={10} />
        );
      }
    }
    return gridLines;
  };

  const handleUndo = () => {
    if (historyStep > 1) {
      setHistoryStep(historyStep - 1);
      const previousState = history[historyStep - 2];
      setLines(previousState.lines);
      setLabels(previousState.labels);
    }
  };

  const handleRedo = () => {
    if (historyStep < history.length) {
      const nextState = history[historyStep];
      setLines(nextState.lines);
      setLabels(nextState.labels);
      setHistoryStep(historyStep + 1);
    }
  };

  const handleWheel = (e) => {
    e.evt.preventDefault();

    const scaleBy = 1.1;
    const stage = e.target.getStage();
    const oldScale = stage.scaleX();
    const mousePointTo = {
      x: stage.getPointerPosition().x / oldScale - stage.x() / oldScale,
      y: stage.getPointerPosition().y / oldScale - stage.y() / oldScale
    };

    const newScale = e.evt.deltaY < 0 ? oldScale * scaleBy : oldScale / scaleBy;

    setStageScale(newScale);
    setStageX(-(mousePointTo.x - stage.getPointerPosition().x / newScale) * newScale);
    setStageY(-(mousePointTo.y - stage.getPointerPosition().y / newScale) * newScale);
  };

  const handleDragStart = () => {
    setMode('pan');
  };

  const handleDragEnd = () => {
    setMode('draw');
  };

  const handleDeleteClick = () => {
    setMode(mode === 'delete' ? 'draw' : 'delete');
  };

  return (
    <div className="sketch">
      <h3>Building Sketch</h3>
      <div className="controls">
        <button onClick={() => setMode('draw')}>Draw</button>
        <button onClick={() => setMode('label')}>Add Label</button>
        <button onClick={() => setMode('edit')}>Edit</button>
        <button onClick={() => setMode('delete')}>Delete</button>
        <button onClick={() => setShowGrid(!showGrid)}>Toggle Grid</button>
        <button onClick={handleUndo}>Undo</button>
        <button onClick={handleRedo}>Redo</button>
        <input 
          type="number" 
          value={scale} 
          onChange={handleScaleChange} 
          placeholder="Pixels per foot"
        />
        <span>Area: {calculateArea()} sq ft</span>
        <span>Zoom: {Math.round(stageScale * 100)}%</span>
        <span>Grid Size: {GRID_SIZE / scale} ft</span>
      </div>
      <Stage
        width={800}
        height={600}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onWheel={handleWheel}
        scaleX={stageScale}
        scaleY={stageScale}
        x={stageX}
        y={stageY}
        draggable={mode === 'pan'}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        ref={stageRef}
      >
        <Layer>
          {showGrid && renderGrid()}
          {lines.map((line, i) => (
            <React.Fragment key={i}>
              <Line
                points={line.points}
                stroke="#df4b26"
                strokeWidth={2}
                tension={0.5}
                lineCap="round"
                lineIndex={i}
                onClick={() => setSelectedElement({ getAttr: () => i })}
              />
              <Text
                x={(line.points[0] + line.points[line.points.length - 2]) / 2}
                y={(line.points[1] + line.points[line.points.length - 1]) / 2}
                text={`${line.length} ft`}
                fontSize={12}
                fill="black"
              />
            </React.Fragment>
          ))}
          <Line
            points={currentLine}
            stroke="#df4b26"
            strokeWidth={2}
            tension={0.5}
            lineCap="round"
          />
          {labels.map((label, i) => (
            <Text
              key={i}
              x={label.x}
              y={label.y}
              text={label.text}
              fontSize={16}
              fill="black"
              draggable
              onDblClick={() => {
                const newText = prompt('Enter new label text:', label.text);
                if (newText) handleLabelEdit(i, newText);
              }}
            />
          ))}
          {mode === 'label' && (
            <Circle
              x={stageRef.current?.getPointerPosition().x}
              y={stageRef.current?.getPointerPosition().y}
              radius={5}
              fill="black"
            />
          )}
        </Layer>
      </Stage>
    </div>
  );
};

export default Sketch;