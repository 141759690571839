// src/config.js
const config = {
    development: {
        apiUrl: 'http://localhost:5000',
    },
    production: {
        apiUrl: 'https://api.devinparent.com',
    },
};

const getApiUrl = () => {
    const env = process.env.NODE_ENV || 'development';
    return config[env].apiUrl;
};

export default getApiUrl;
