import { useState, useEffect } from 'react';
import axiosInstance from '../../../axiosInstance';

const useUtilities = () => {
  const [utilityTypes, setUtilityTypes] = useState([]);
  const [newUtilityType, setNewUtilityType] = useState({ utility_type: '' });
  const [updateSuccess, setUpdateSuccess] = useState('');

  useEffect(() => {
    fetchUtilityTypes();
  }, []);

  const fetchUtilityTypes = async () => {
    try {
      const response = await axiosInstance.get('/api/utilities');
      setUtilityTypes(response.data);
    } catch (error) {
      console.error('Error fetching utility types:', error);
    }
  };

  const handleAddUtilityType = async () => {
    try {
      const response = await axiosInstance.post('/api/utilities', newUtilityType);
      if (response.data.success) {
        setUtilityTypes([...utilityTypes, { ...newUtilityType, id: response.data.id }]);
        setNewUtilityType({ utility_type: '' });
      }
    } catch (error) {
      console.error('Error adding utility type:', error);
    }
  };

  const handleUpdateUtilityType = async (id, updatedUtilityType) => {
    try {
      const response = await axiosInstance.put(`/api/utilities/${id}`, updatedUtilityType);
      if (response.data.success) {
        setUtilityTypes(utilityTypes.map(type => (type.id === id ? updatedUtilityType : type)));
        setUpdateSuccess('Utility type updated successfully!');
        setTimeout(() => setUpdateSuccess(''), 3000);
      }
    } catch (error) {
      console.error('Error updating utility type:', error);
    }
  };

  const handleDeleteUtilityType = async (id) => {
    try {
      const response = await axiosInstance.delete(`/api/utilities/${id}`);
      if (response.data.success) {
        setUtilityTypes(utilityTypes.filter(type => type.id !== id));
      }
    } catch (error) {
      console.error('Error deleting utility type:', error);
    }
  };

  const handleUtilityTypeInputChange = (index, field, value) => {
    const updatedUtilityTypes = [...utilityTypes];
    updatedUtilityTypes[index][field] = value;
    setUtilityTypes(updatedUtilityTypes);
  };

  return {
    utilityTypes,
    newUtilityType,
    setNewUtilityType,
    handleAddUtilityType,
    handleUpdateUtilityType,
    handleDeleteUtilityType,
    handleUtilityTypeInputChange,
    updateSuccess
  };
};

export default useUtilities;