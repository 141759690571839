import { useState, useEffect } from 'react';
import axiosInstance from '../../../axiosInstance';

const useBuildingSchedules = () => {
  const [buildingSchedules, setBuildingSchedules] = useState([]);
  const [newBuildingSchedule, setNewBuildingSchedule] = useState({ area_sf: '', base: '' });
  const [updateSuccess, setUpdateSuccess] = useState(''); // New state for success message

  useEffect(() => {
    fetchBuildingSchedules();
  }, []);

  const fetchBuildingSchedules = async () => {
    try {
      const response = await axiosInstance.get('/api/building-schedules');
      setBuildingSchedules(response.data);
    } catch (error) {
      console.error('Error fetching building schedules:', error);
    }
  };

  const handleAddBuildingSchedule = async () => {
    try {
      const response = await axiosInstance.post('/api/building-schedules', newBuildingSchedule);
      if (response.data.success) {
        setBuildingSchedules([...buildingSchedules, { ...newBuildingSchedule, id: response.data.id }]);
        setNewBuildingSchedule({ area_sf: '', base: '' });
      }
    } catch (error) {
      console.error('Error adding building schedule:', error);
    }
  };

  const handleUpdateBuildingSchedule = async (id, updatedBuildingSchedule) => {
    try {
      const response = await axiosInstance.put(`/api/building-schedules/${id}`, updatedBuildingSchedule);
      if (response.data.success) {
        setBuildingSchedules(buildingSchedules.map(bs => (bs.id === id ? updatedBuildingSchedule : bs)));
        setUpdateSuccess('Building schedule updated successfully!'); // Set success message
        setTimeout(() => setUpdateSuccess(''), 3000); // Clear message after 3 seconds
      }
    } catch (error) {
      console.error('Error updating building schedule:', error);
    }
  };

  const handleDeleteBuildingSchedule = async (id) => {
    try {
      const response = await axiosInstance.delete(`/api/building-schedules/${id}`);
      if (response.data.success) {
        setBuildingSchedules(buildingSchedules.filter(bs => bs.id !== id));
      }
    } catch (error) {
      console.error('Error deleting building schedule:', error);
    }
  };

  const handleBuildingScheduleInputChange = (index, field, value) => {
    const updatedBuildingSchedules = [...buildingSchedules];
    updatedBuildingSchedules[index][field] = value;
    setBuildingSchedules(updatedBuildingSchedules);
  };

  return {
    buildingSchedules,
    newBuildingSchedule,
    setNewBuildingSchedule,
    handleAddBuildingSchedule,
    handleUpdateBuildingSchedule,
    handleDeleteBuildingSchedule,
    handleBuildingScheduleInputChange,
    updateSuccess // Return the success message
  };
};

export default useBuildingSchedules;
