import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Sidebar from './components/Sidebar/Sidebar';
import Navbar from './components/Navbar/Navbar';
import CreateOwner from './pages/PropertyOwners/components/CreateOwner';
import Dashboard from './pages/Dashboard/Dashboard';
import Reports from './pages/Reports/Reports';
import Help from './pages/Help/Help';
import GlobalValues from './pages/GlobalValues/GlobalValues';
import CertifiedRatioMVR from './pages/CertifiedRatioMVR/CertifiedRatioMVR';
import NewAccount from './pages/NewAccount/NewAccount';
import AccountSearch from './pages/AccountSearch/AccountSearch';
import PropertyOwners from './pages/PropertyOwners/PropertyOwners';
import PropertyOwnerDetail from './pages/PropertyOwners/PropertyOwnerDetail';
import Login from './pages/Login/Login';
import AccountSettings from './pages/AccountSettings/AccountSettings';
import PropertyTaxCard from './pages/PropertyTaxCard/PropertyTaxCard';
import useCloseSidebarOnFocus from './hooks/useCloseSidebarOnFocus';
import UserManagement from './pages/UserManagement/UserManagement';
import useAuth from './hooks/useAuth';
import './index.css';
import './pages/AccountSearch/AccountSearch.css'; // Corrected path
import UserManagementCreate from './pages/UserManagement/UserManagementCreate';
import UserManagementEdit from './pages/UserManagement/UserManagementEdit';
import UserManagementDeleted from './pages/UserManagement/UserManagementDeleted';
import ResetPassword from './pages/UserManagement/ResetPassword';
import About from './pages/About/About';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const PrivateRoute = ({ children }) => {
  const [token] = useAuth();
  if (token === null) {
    return <div>Loading...</div>; // or a spinner
  }
  return token ? children : <Navigate to="/login" />;
};

const App = () => {
  const [token] = useAuth(); // useAuth hook handles the token
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = (forceClose = false) => {
    setSidebarOpen((prevState) => (forceClose ? false : !prevState));
  };

  useCloseSidebarOnFocus(toggleSidebar);

  return (
    <>
    <Router>
      {!token ? (
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="*" element={<Navigate to="/login" replace />} />
        </Routes>
      ) : (
        <div className="flex">
          <Sidebar isOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
          <div className={`content flex-1 p-4 ${sidebarOpen ? 'md:ml-64' : ''}`}>
            <Navbar toggleSidebar={toggleSidebar} />
            <Routes>
              <Route path="/login" element={<Navigate to="/dashboard" replace />} />
              <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
              <Route path="/usermanagement" element={<PrivateRoute><UserManagement /></PrivateRoute>} />
              <Route path="/usermanagement/create" element={<PrivateRoute><UserManagementCreate /></PrivateRoute>} />
              <Route path="/usermanagement/edit/:id" element={<PrivateRoute><UserManagementEdit /></PrivateRoute>} />
              <Route path="/usermanagement/deleted" element={<PrivateRoute><UserManagementDeleted /></PrivateRoute>} />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route path="/about" element={<About />} />
              <Route path="/reports" element={<PrivateRoute><Reports /></PrivateRoute>} />
              <Route path="/help" element={<PrivateRoute><Help /></PrivateRoute>} />
              <Route path="/global-values" element={<PrivateRoute><GlobalValues /></PrivateRoute>} />
              <Route path="/certified-ratio-mvr" element={<PrivateRoute><CertifiedRatioMVR /></PrivateRoute>} />
              <Route path="/new-account" element={<PrivateRoute><NewAccount /></PrivateRoute>} />
              <Route path="/account-search" element={<PrivateRoute><AccountSearch /></PrivateRoute>} />
              <Route path="/property-owners" element={<PropertyOwners />} />
              <Route path="/property-owner/:id" element={<PropertyOwnerDetail />} />
              <Route path="/account-settings" element={<PrivateRoute><AccountSettings /></PrivateRoute>} />
              <Route path="/property-tax-card/:accountNumber" element={<PrivateRoute><PropertyTaxCard /></PrivateRoute>} />
              <Route path="/create-owner" element={<CreateOwner />} />
              <Route path="/" element={<Navigate to="/dashboard" replace />} exact />
            </Routes>
          </div>
        </div>
      )}
    </Router>
    <ToastContainer />
    </>
  );
};

export default App;