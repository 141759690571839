import React from 'react';
import useBuildingStyles from '../hooks/useBuildingStyles';
import './BuildingStyles.css';

const BuildingStyles = () => {
  console.log('Rendering BuildingStyles component');
  const {
    buildingStyles,
    newBuildingStyle,
    setNewBuildingStyle,
    handleAddBuildingStyle,
    handleUpdateBuildingStyle,
    handleDeleteBuildingStyle,
    handleBuildingStyleInputChange,
    updateSuccess
  } = useBuildingStyles();

  return (
    <>
      {updateSuccess && <div className="update-success">{updateSuccess}</div>}
      <div className="building-styles">
        <table className="building-styles-table">
          <thead>
            <tr>
              <th>Building Style</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {buildingStyles.map((buildingStyle, index) => (
              <tr key={buildingStyle.id}>
                <td>
                  <input
                    type="text"
                    value={buildingStyle.style_name}
                    onChange={(e) =>
                      handleBuildingStyleInputChange(index, 'style_name', e.target.value)
                    }
                    placeholder="Building Style"
                  />
                </td>
                <td>
                  {/* Wrap the buttons inside a div with class "button-group" */}
                  <div className="button-group">
                    <button
                      onClick={() => handleUpdateBuildingStyle(buildingStyle.id, buildingStyle)}
                      className="update-button"
                    >
                      Update
                    </button>
                    <button
                      onClick={() => handleDeleteBuildingStyle(buildingStyle.id)}
                      className="delete-button"
                    >
                      Delete
                    </button>
                  </div>
                </td>
              </tr>
            ))}
            <tr className="new-building-style">
              <td>
                <input
                  type="text"
                  value={newBuildingStyle.style_name}
                  onChange={(e) =>
                    setNewBuildingStyle({ ...newBuildingStyle, style_name: e.target.value })
                  }
                  placeholder="New Building Style"
                />
              </td>
              <td>
                {/* Wrap the add button inside a div with class "button-group" */}
                <div className="button-group">
                  <button onClick={handleAddBuildingStyle} className="add-button">
                    Add Building Style
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default BuildingStyles;
