import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faEarthAmericas, faAddressCard } from '@fortawesome/free-solid-svg-icons';
import './Dashboard.css';
import axiosInstance from '../../axiosInstance';
import getApiUrl from '../../config'; // Import getApiUrl (adjust the path as needed)

const Dashboard = () => {
  const [dashboardData, setDashboardData] = useState({
    totalAccounts: 0,
    totalLandValue: '0',
    totalBuildingValue: '0',
  });

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const apiUrl = getApiUrl(); // Get the correct API URL
        console.log('Fetching dashboard data from:', apiUrl);
        const response = await axiosInstance.get(`${apiUrl}/api/dashboard/summary`);
        console.log('Dashboard data received:', response.data);
        setDashboardData(response.data);
      } catch (error) {
        console.error('Error fetching dashboard data:', error.response ? error.response.data : error.message);
      }
    };

    fetchDashboardData();
  }, []);

  console.log('Current dashboard data:', dashboardData);

  return (
    <div className="dashboard-container">
      <div className="dashboard-content">
        <h2>Welcome to the Dashboard</h2>
        <p>This is where you'll view property tax information. Use the sidebar to access different sections of the application.</p>
        <div className="dashboard-cards">
          <div className="dashboard-card">
            <FontAwesomeIcon icon={faAddressCard} className="card-icon" />
            <h3>Total Accounts</h3>
            <p>{dashboardData.totalAccounts}</p>
          </div>
          <div className="dashboard-card">
            <FontAwesomeIcon icon={faEarthAmericas} className="card-icon" />
            <h3>Total Land Value</h3>
            <p>${parseFloat(dashboardData.totalLandValue).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}</p>
          </div>
          <div className="dashboard-card">
            <FontAwesomeIcon icon={faBuilding} className="card-icon" />
            <h3>Total Building Value</h3>
            <p>${parseFloat(dashboardData.totalBuildingValue).toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;