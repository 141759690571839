// src/components/ConfirmationModal.js

import React from 'react';
import './ConfirmationModal.css'; // Create and style as needed

const ConfirmationModal = ({ isOpen, onClose, onConfirm, title, message }) => {
    if (!isOpen) return null;

    return (
        <div className="modal-overlay" role="dialog" aria-modal="true" aria-labelledby="confirmation-modal-title">
            <div className="modal-content">
                <h2 id="confirmation-modal-title">{title}</h2>
                <p>{message}</p>
                <div className="button-container">
                    <button onClick={onConfirm} className="primary-button">Yes</button>
                    <button onClick={onClose} className="secondary-button">No</button>
                </div>
            </div>
        </div>
    );
};

export default ConfirmationModal;
