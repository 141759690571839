import React, { useState } from 'react';
import useExemptions from '../hooks/useExemptions';
import ExemptionsModal from '../modals/ExemptionsModal';
import './Exemptions.css';

const Exemptions = () => {
  console.log('Rendering Exemptions component');

  // Use the custom hook to get exemptions state and functions
  const {
    exemptions,
    newExemption,
    setNewExemption,
    handleAddExemption,
    handleUpdateExemption,
    handleDeleteExemption,
    handleExemptionInputChange,
    updateSuccess // Destructure the success message
  } = useExemptions();

  // Local state to manage the visibility of the add exemption modal
  const [isExemptionsModalOpen, setExemptionsModalOpen] = useState(false);

  // Functions to open and close the add exemption modal
  const handleOpenExemptionsModal = () => setExemptionsModalOpen(true);
  const handleCloseExemptionsModal = () => setExemptionsModalOpen(false);

  // Function to handle form submission when adding a new exemption
  const handleSubmit = (e) => {
    e.preventDefault();
    handleAddExemption();
    handleCloseExemptionsModal(); // Close the modal after adding the exemption
  };

  return (
    <div className="exemptions">
      {/* Display success message if present */}
      {updateSuccess && <div className="update-success">{updateSuccess}</div>} {/* Display success message */}
      
      {/* Table to display existing exemptions */}
      <table className="exemptions-table">
        <thead>
          <tr>
            <th>Exemption Name</th>
            <th>Amount Type</th>
            <th>Apply Order</th>
            <th>Exemption Amount</th>
            <th>Adjust by Certified Ratio</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {exemptions.map((exemption, index) => (
            <tr key={exemption.id}>
              {/* Input fields for each exemption property */}
              <td>
                <input
                  type="text"
                  value={exemption.exemption_name}
                  onChange={(e) => handleExemptionInputChange(index, 'exemption_name', e.target.value)}
                  placeholder="Exemption Name"
                />
              </td>
              <td>
                <select
                  value={exemption.amount_type}
                  onChange={(e) => handleExemptionInputChange(index, 'amount_type', e.target.value)}
                >
                  <option value="dollar">Dollar Amount</option>
                  <option value="percentage">Percentage Amount</option>
                </select>
              </td>
              <td>
                <select
                  value={exemption.apply_order}
                  onChange={(e) => handleExemptionInputChange(index, 'apply_order', e.target.value)}
                >
                  <option value="building_first">Building First</option>
                  <option value="land_first">Land First</option>
                </select>
              </td>
              <td>
                <input
                  type="number"
                  value={exemption.exemption_amount}
                  onChange={(e) => handleExemptionInputChange(index, 'exemption_amount', e.target.value)}
                  placeholder="Exemption Amount"
                />
              </td>
              <td>
                <label className="checkbox-label">
                  <input
                    type="checkbox"
                    checked={exemption.adjust_by_ratio}
                    onChange={(e) => handleExemptionInputChange(index, 'adjust_by_ratio', e.target.checked)}
                  />
                </label>
              </td>
              <td>
                <div className="button-group">
                  <button
                    onClick={() => handleUpdateExemption(exemption.id, exemption)}
                    className="update-button"
                  >
                    Update
                  </button>
                  <button
                    onClick={() => handleDeleteExemption(exemption.id)}
                    className="delete-button"
                  >
                    Delete
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Button to open the add exemption modal */}
      <button className="add-button" onClick={handleOpenExemptionsModal}>Add Exemption</button>
      
      {/* Modal component for adding a new exemption */}
      <ExemptionsModal
        isOpen={isExemptionsModalOpen}
        onClose={handleCloseExemptionsModal}
        onSubmit={handleSubmit}
        newExemption={newExemption}
        setNewExemption={setNewExemption}
      />
    </div>
  );
};

export default Exemptions;
