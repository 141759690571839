import React, { useState, useEffect, useRef } from 'react';
import useNeighborhoodFactor from '../hooks/useNeighborhoodFactor';
import DeleteConfirmationModal from '../modals/DeleteConfirmationModal';
import './NeighborhoodFactor.css';

const NeighborhoodFactor = () => {
    console.log('Rendering Exemptions component');
    const {
        neighborhoodFactors,
        newFactor,
        setNewFactor,
        handleAddFactor,
        handleUpdateFactor,
        handleDeleteFactor,
        handleFactorInputChange,
        updateSuccess,
        error,
        clearMessages
    } = useNeighborhoodFactor();

    const [deleteConfirmation, setDeleteConfirmation] = useState(null);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const successMessageRef = useRef(null);

    useEffect(() => {
        if (updateSuccess) {
            setShowSuccessMessage(true);
            const timer = setTimeout(() => {
                setShowSuccessMessage(false);
                clearMessages();
            }, 3000);
            return () => clearTimeout(timer);
        }
    }, [updateSuccess, clearMessages]);

    const validateInput = (field, value) => {
        if (field === 'name' && value.trim() === '') {
            return 'Name is required';
        }
        if (field === 'factor_value' && (isNaN(value) || value <= 0)) {
            return 'Factor value must be a positive number';
        }
        return null;
    };

    const handleConfirmDelete = () => {
        if (deleteConfirmation) {
            handleDeleteFactor(deleteConfirmation);
            setDeleteConfirmation(null);
        }
    };

    return (
        <div className="neighborhood-factor">
            {showSuccessMessage && (
                <div
                    ref={successMessageRef}
                    className={`update-success ${showSuccessMessage ? 'show' : ''}`}
                >
                    {updateSuccess}
                </div>
            )}
            {error && <div className="error-message">{error}</div>}
            <table className="neighborhood-factor-table">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Impact Type</th>
                        <th>Application Method</th>
                        <th>Factor Value</th>
                        <th>Description</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {neighborhoodFactors.map((factor, index) => (
                        <tr key={factor.id}>
                            <td>
                                <input
                                    type="text"
                                    value={factor.name}
                                    onChange={(e) => {
                                        handleFactorInputChange(index, 'name', e.target.value);
                                        clearMessages();
                                    }}
                                    placeholder="Name"
                                />
                            </td>
                            <td>
                                <select
                                    value={factor.impact_type}
                                    onChange={(e) => {
                                        handleFactorInputChange(index, 'impact_type', e.target.value);
                                        clearMessages();
                                    }}
                                >
                                    <option value="Land Only">Land Only</option>
                                    <option value="Building Only">Building Only</option>
                                    <option value="Both">Both</option>
                                </select>
                            </td>
                            <td>
                                <select
                                    value={factor.application_method}
                                    onChange={(e) => {
                                        handleFactorInputChange(index, 'application_method', e.target.value);
                                        clearMessages();
                                    }}
                                >
                                    <option value="Percentage">Percentage</option>
                                    <option value="Fixed Amount">Fixed Amount</option>
                                </select>
                            </td>
                            <td>
                                <input
                                    type="number"
                                    value={factor.factor_value}
                                    onChange={(e) => {
                                        handleFactorInputChange(index, 'factor_value', e.target.value);
                                        clearMessages();
                                    }}
                                    placeholder="Factor Value"
                                />
                            </td>
                            <td>
                                <input
                                    type="text"
                                    value={factor.description}
                                    onChange={(e) => {
                                        handleFactorInputChange(index, 'description', e.target.value);
                                        clearMessages();
                                    }}
                                    placeholder="Description"
                                />
                            </td>
                            <td>
                                <button
                                    onClick={() => {
                                        const nameError = validateInput('name', factor.name);
                                        const valueError = validateInput('factor_value', factor.factor_value);
                                        if (nameError || valueError) {
                                            clearMessages();
                                            alert(nameError || valueError);
                                        } else {
                                            handleUpdateFactor(factor.id, factor);
                                        }
                                    }}
                                    className="update-button"
                                >
                                    Update
                                </button>
                                <button
                                    onClick={() => setDeleteConfirmation(factor.id)}
                                    className="delete-button"
                                >
                                    Delete
                                </button>
                            </td>
                        </tr>
                    ))}
                    <tr className="new-factor">
                        <td>
                            <input
                                type="text"
                                value={newFactor.name}
                                onChange={(e) => {
                                    setNewFactor({ ...newFactor, name: e.target.value });
                                    clearMessages();
                                }}
                                placeholder="New Name"
                            />
                        </td>
                        <td>
                            <select
                                value={newFactor.impact_type}
                                onChange={(e) => {
                                    setNewFactor({ ...newFactor, impact_type: e.target.value });
                                    clearMessages();
                                }}
                            >
                                <option value="Land Only">Land Only</option>
                                <option value="Building Only">Building Only</option>
                                <option value="Both">Both</option>
                            </select>
                        </td>
                        <td>
                            <select
                                value={newFactor.application_method}
                                onChange={(e) => {
                                    setNewFactor({ ...newFactor, application_method: e.target.value });
                                    clearMessages();
                                }}
                            >
                                <option value="Percentage">Percentage</option>
                                <option value="Fixed Amount">Fixed Amount</option>
                            </select>
                        </td>
                        <td>
                            <input
                                type="number"
                                value={newFactor.factor_value}
                                onChange={(e) => {
                                    setNewFactor({ ...newFactor, factor_value: e.target.value });
                                    clearMessages();
                                }}
                                placeholder="New Factor Value"
                            />
                        </td>
                        <td>
                            <input
                                type="text"
                                value={newFactor.description}
                                onChange={(e) => {
                                    setNewFactor({ ...newFactor, description: e.target.value });
                                    clearMessages();
                                }}
                                placeholder="New Description"
                            />
                        </td>
                        <td>
                            <button
                                onClick={() => {
                                    const nameError = validateInput('name', newFactor.name);
                                    const valueError = validateInput('factor_value', newFactor.factor_value);
                                    if (nameError || valueError) {
                                        clearMessages();
                                        alert(nameError || valueError);
                                    } else {
                                        handleAddFactor();
                                    }
                                }}
                                className="add-button"
                            >
                                Add Factor
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <DeleteConfirmationModal
                isOpen={!!deleteConfirmation}
                onClose={() => setDeleteConfirmation(null)}
                onConfirm={handleConfirmDelete}
                itemName="neighborhood factor"
            />
        </div>
    );
};

export default NeighborhoodFactor;