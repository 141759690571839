import React from 'react';
import useTreeGrowthRates from '../hooks/useTreeGrowthRates';
import './TreeGrowthRates.css';

const TreeGrowthRates = () => {
  console.log('Rendering Exemptions component');
  const { treeGrowthRates, handleTreeGrowthRatesChange, handleUpdateTreeGrowthRates, updateSuccess, dataExists } = useTreeGrowthRates();

  return (
    <>
      {updateSuccess && <div className="update-success">{updateSuccess}</div>}
      <div className="tree-growth-rates">
        <table className="tree-growth-rates-table">
          <thead>
            <tr>
              <th>Softwood Value</th>
              <th>Mixedwood Value</th>
              <th>Hardwood Value</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <input
                  type="number"
                  value={treeGrowthRates.softwoodvalue}
                  onChange={(e) => handleTreeGrowthRatesChange('softwoodvalue', e.target.value)}
                />
              </td>
              <td>
                <input
                  type="number"
                  value={treeGrowthRates.mixedwoodvalue}
                  onChange={(e) => handleTreeGrowthRatesChange('mixedwoodvalue', e.target.value)}
                />
              </td>
              <td>
                <input
                  type="number"
                  value={treeGrowthRates.hardwoodvalue}
                  onChange={(e) => handleTreeGrowthRatesChange('hardwoodvalue', e.target.value)}
                />
              </td>
              <td>
                <button onClick={handleUpdateTreeGrowthRates} className="update-button">
                  {dataExists ? 'Update' : 'Insert'}
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default TreeGrowthRates;