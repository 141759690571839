// src/pages/PropertyTaxCard/components/modals/AddOwnerForm.js

import React, { useState } from 'react';
import './AddOwnerForm.css'; // Create and style as needed

const AddOwnerForm = ({ onAddOwner, onCancel }) => {
    const [ownerData, setOwnerData] = useState({
        firstName: '',
        lastName: '',
        phoneNumber: '',
        emailAddress: '',
        address: '',
        city: '',
        state_province: '',
        zip_postal_code: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setOwnerData({ ...ownerData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onAddOwner(ownerData);
    };

    return (
        <div className="modal-overlay" role="dialog" aria-modal="true" aria-labelledby="add-owner-modal-title">
            <div className="modal-content">
                <h2 id="add-owner-modal-title">Add New Owner</h2>
                <button className="close-button" onClick={onCancel} aria-label="Close Modal">×</button>
                <form onSubmit={handleSubmit} className="add-owner-form">
                    <div className="form-group">
                        <label htmlFor="firstName">First Name:</label>
                        <input
                            type="text"
                            id="firstName"
                            name="firstName"
                            value={ownerData.firstName}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="lastName">Last Name:</label>
                        <input
                            type="text"
                            id="lastName"
                            name="lastName"
                            value={ownerData.lastName}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="phoneNumber">Phone Number:</label>
                        <input
                            type="text"
                            id="phoneNumber"
                            name="phoneNumber"
                            value={ownerData.phoneNumber}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="emailAddress">Email Address:</label>
                        <input
                            type="email"
                            id="emailAddress"
                            name="emailAddress"
                            value={ownerData.emailAddress}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="address">Address:</label>
                        <input
                            type="text"
                            id="address"
                            name="address"
                            value={ownerData.address}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="city">City:</label>
                        <input
                            type="text"
                            id="city"
                            name="city"
                            value={ownerData.city}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="state_province">State/Province:</label>
                        <input
                            type="text"
                            id="state_province"
                            name="state_province"
                            value={ownerData.state_province}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="zip_postal_code">ZIP/Postal Code:</label>
                        <input
                            type="text"
                            id="zip_postal_code"
                            name="zip_postal_code"
                            value={ownerData.zip_postal_code}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="form-actions">
                        <button type="submit" className="primary-button">Add Owner</button>
                        <button type="button" onClick={onCancel} className="secondary-button">Cancel</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default AddOwnerForm;
