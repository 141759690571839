import React from 'react';
import useRoofTypes from '../hooks/useRoofTypes';
import './RoofTypes.css';

const RoofTypes = () => {
  console.log('Rendering RoofTypes component');
  const {
    roofTypes,
    newRoofType,
    setNewRoofType,
    handleAddRoofType,
    handleUpdateRoofType,
    handleDeleteRoofType,
    handleRoofTypeInputChange,
    updateSuccess
  } = useRoofTypes();

  return (
    <>
      {updateSuccess && <div className="update-success">{updateSuccess}</div>}
      <div className="roof-types">
        <table className="roof-types-table">
          <thead>
            <tr>
              <th>Roof Type</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {roofTypes.map((roofType, index) => (
              <tr key={roofType.id}>
                <td>
                  <input
                    type="text"
                    value={roofType.type_name}
                    onChange={(e) =>
                      handleRoofTypeInputChange(index, 'type_name', e.target.value)
                    }
                    placeholder="Roof Type"
                  />
                </td>
                <td>
                  {/* Wrap the buttons inside a div with class "button-group" */}
                  <div className="button-group">
                    <button
                      onClick={() => handleUpdateRoofType(roofType.id, roofType)}
                      className="update-button"
                    >
                      Update
                    </button>
                    <button
                      onClick={() => handleDeleteRoofType(roofType.id)}
                      className="delete-button"
                    >
                      Delete
                    </button>
                  </div>
                </td>
              </tr>
            ))}
            <tr className="new-roof-type">
              <td>
                <input
                  type="text"
                  value={newRoofType.type_name}
                  onChange={(e) =>
                    setNewRoofType({ ...newRoofType, type_name: e.target.value })
                  }
                  placeholder="New Roof Type"
                />
              </td>
              <td>
                {/* Wrap the add button inside a div with class "button-group" */}
                <div className="button-group">
                  <button
                    onClick={handleAddRoofType}
                    className="add-button"
                  >
                    Add Roof Type
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default RoofTypes;
