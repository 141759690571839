import React, { useState } from 'react';
import LicenseModal from './modals/LicenseModal';
import './About.css';

const About = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <div className="about-container">
  <div className="about-content">
    <h1 className="about-title">About PropTax Pro</h1>
    <p>
      PropTax Pro is designed to help assessors and municipalities manage properties efficiently.
      The app allows users to create and manage property records, calculate taxes, and generate reports.
    </p>
    <div className="button-container">
      <button className="about-button" onClick={toggleModal}>View License</button>
    </div>
    <footer>
  <p>
    PropTax Pro © 2024. Includes third-party software licensed under open-source licenses. 
    See <a href="/third-party/third-party-notices.html" target="_blank" rel="noopener noreferrer">Third-Party Notices</a> for details.
  </p>
  <p>
    FontAwesome Icons © FontAwesome, licensed under <a href="https://creativecommons.org/licenses/by/4.0/">CC-BY-4.0</a>.
  </p>
</footer>
    {isModalOpen && <LicenseModal closeModal={toggleModal} />}
  </div>
</div>
  );
};

export default About;
