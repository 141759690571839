import { useState, useEffect } from 'react';
import axiosInstance from '../../../axiosInstance';

const useRoofingTypes = () => {
  const [roofingTypes, setRoofingTypes] = useState([]);
  const [newRoofingType, setNewRoofingType] = useState({ type_name: '' });
  const [updateSuccess, setUpdateSuccess] = useState('');

  useEffect(() => {
    fetchRoofingTypes();
  }, []);

  const fetchRoofingTypes = async () => {
    try {
      const response = await axiosInstance.get('/api/roofing-types');
      setRoofingTypes(response.data);
    } catch (error) {
      console.error('Error fetching roofing types:', error);
    }
  };

  const handleAddRoofingType = async () => {
    try {
      const response = await axiosInstance.post('/api/roofing-types', newRoofingType);
      if (response.data.success) {
        setRoofingTypes([...roofingTypes, { ...newRoofingType, id: response.data.id }]);
        setNewRoofingType({ type_name: '' });
      }
    } catch (error) {
      console.error('Error adding roofing type:', error);
    }
  };

  const handleUpdateRoofingType = async (id, updatedRoofingType) => {
    try {
      const response = await axiosInstance.put(`/api/roofing-types/${id}`, updatedRoofingType);
      if (response.data.success) {
        setRoofingTypes(roofingTypes.map(type => (type.id === id ? updatedRoofingType : type)));
        setUpdateSuccess('Roofing type updated successfully!');
        setTimeout(() => setUpdateSuccess(''), 3000);
      }
    } catch (error) {
      console.error('Error updating roofing type:', error);
    }
  };

  const handleDeleteRoofingType = async (id) => {
    try {
      const response = await axiosInstance.delete(`/api/roofing-types/${id}`);
      if (response.data.success) {
        setRoofingTypes(roofingTypes.filter(type => type.id !== id));
      }
    } catch (error) {
      console.error('Error deleting roofing type:', error);
    }
  };

  const handleRoofingTypeInputChange = (index, field, value) => {
    const updatedRoofingTypes = [...roofingTypes];
    updatedRoofingTypes[index][field] = value;
    setRoofingTypes(updatedRoofingTypes);
  };

  return {
    roofingTypes,
    newRoofingType,
    setNewRoofingType,
    handleAddRoofingType,
    handleUpdateRoofingType,
    handleDeleteRoofingType,
    handleRoofingTypeInputChange,
    updateSuccess
  };
};

export default useRoofingTypes;