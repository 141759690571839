// src/hooks/usePropertyData.js

import { useState, useEffect } from 'react';
import axiosInstance from '../../../axiosInstance'; // Use axiosInstance instead of axios

const usePropertyData = (accountNumber) => {
  const [propertyData, setPropertyData] = useState({});
  const [saleData, setSaleData] = useState([]);
  const [inspectionData, setInspectionData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // New state for dropdown options
  const [neighborhoodOptions, setNeighborhoodOptions] = useState([]);
  const [zoneOptions, setZoneOptions] = useState([]);
  const [landUseOptions, setLandUseOptions] = useState([]);
  const [topographyOptions, setTopographyOptions] = useState([]);
  const [utilityOptions, setUtilityOptions] = useState([]);
  const [streetTypeOptions, setStreetTypeOptions] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      if (!accountNumber) {
        setError('No account number provided');
        setLoading(false);
        return;
      }
      try {
        const endpoints = [
          { name: 'property', url: `/api/property-data/account/${accountNumber}` },
          { name: 'sales', url: `/api/property-data/account/${accountNumber}/sales` },
          { name: 'inspections', url: `/api/property-data/account/${accountNumber}/inspections` },
          { name: 'neighborhoods', url: `/api/property-data/neighborhoods` },
          { name: 'zones', url: `/api/property-data/zones` },
          { name: 'landUses', url: `/api/property-data/land-uses` },
          { name: 'topographies', url: `/api/property-data/topographies` },
          { name: 'utilities', url: `/api/property-data/utilities` },
          { name: 'streetTypes', url: `/api/property-data/street-types` },
        ];

        const responses = await Promise.all(
          endpoints.map(endpoint =>
            axiosInstance.get(endpoint.url).catch(error => {
              console.error(`Error fetching ${endpoint.name} data:`, error);
              return { data: null, error: error };
            })
          )
        );

        const [
          propertyResponse,
          salesResponse,
          inspectionsResponse,
          neighborhoodResponse,
          zoneResponse,
          landUseResponse,
          topographyResponse,
          utilityResponse,
          streetTypeResponse,
        ] = responses;

        if (propertyResponse.error)
          throw new Error(`Failed to fetch property data: ${propertyResponse.error.message}`);
        if (salesResponse.error)
          throw new Error(`Failed to fetch sales data: ${salesResponse.error.message}`);
        if (inspectionsResponse.error)
          throw new Error(`Failed to fetch inspection data: ${inspectionsResponse.error.message}`);

        setPropertyData(propertyResponse.data);
        setSaleData(salesResponse.data);
        setInspectionData(inspectionsResponse.data);
        setNeighborhoodOptions(neighborhoodResponse.data || []);
        setZoneOptions(zoneResponse.data || []);
        setLandUseOptions(landUseResponse.data || []);
        setTopographyOptions(topographyResponse.data || []);
        setUtilityOptions(utilityResponse.data || []);
        setStreetTypeOptions(streetTypeResponse.data || []);
        setLoading(false);
      } catch (err) {
        console.error('Error in usePropertyData:', err);
        setError(err.message || 'Error fetching property data');
        setLoading(false);
      }
    };

    fetchData();
  }, [accountNumber]);

  const savePropertyData = async data => {
    try {
      const response = await axiosInstance.post(`/api/property-data/account/${accountNumber}`, data);
      setPropertyData(response.data);
      return response.data;
    } catch (err) {
      console.error('Error saving property data:', err);
      setError('Error saving property data');
      throw err;
    }
  };

  const addSaleRecord = async data => {
    try {
      const response = await axiosInstance.post(
        `/api/property-data/account/${accountNumber}/sales`,
        data
      );
      setSaleData(prevData => [...prevData, response.data]);
      return response.data;
    } catch (err) {
      console.error('Error adding sale record:', err);
      setError('Error adding sale record');
      throw err;
    }
  };

  const addInspectionRecord = async data => {
    try {
      const response = await axiosInstance.post(
        `/api/property-data/account/${accountNumber}/inspections`,
        data
      );
      setInspectionData(prevData => [...prevData, response.data]);
      return response.data;
    } catch (err) {
      console.error('Error adding inspection record:', err);
      setError('Error adding inspection record');
      throw err;
    }
  };

  const updateSaleRecord = async updatedSale => {
    try {
      const response = await axiosInstance.put(
        `/api/property-data/account/${accountNumber}/sales/${updatedSale.id}`,
        updatedSale
      );
      setSaleData(prevData =>
        prevData.map(sale => (sale.id === updatedSale.id ? response.data : sale))
      );
      return response.data;
    } catch (err) {
      console.error('Error updating sale record:', err);
      setError('Error updating sale record');
      throw err;
    }
  };

  const updateInspectionRecord = async updatedInspection => {
    try {
      const response = await axiosInstance.put(
        `/api/property-data/account/${accountNumber}/inspections/${updatedInspection.id}`,
        updatedInspection
      );
      setInspectionData(prevData =>
        prevData.map(inspection =>
          inspection.id === updatedInspection.id ? response.data : inspection
        )
      );
      return response.data;
    } catch (err) {
      console.error('Error updating inspection record:', err);
      setError('Error updating inspection record');
      throw err;
    }
  };

  return {
    propertyData,
    saleData,
    inspectionData,
    loading,
    error,
    savePropertyData,
    addSaleRecord,
    addInspectionRecord,
    updateSaleRecord,
    updateInspectionRecord,
    neighborhoodOptions,
    zoneOptions,
    landUseOptions,
    topographyOptions,
    utilityOptions,
    streetTypeOptions,
  };
};

export default usePropertyData;
