import React, { useState, useEffect } from 'react';
import axiosInstance from '../../axiosInstance';
import { useNavigate } from 'react-router-dom';
import getApiUrl from '../../config';
import './PropertyOwners.css';

const PropertyOwners = () => {
  const [searchParams, setSearchParams] = useState({
    firstName: '',
    lastName: '',
  });
  const [allOwners, setAllOwners] = useState([]);
  const [results, setResults] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 640);
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 640);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const fetchAllOwners = async () => {
      const apiUrl = getApiUrl();
      try {
        const response = await axiosInstance.get(`${apiUrl}/api/property-owners`);
        const sortedOwners = response.data.sort((a, b) => a.lastName.localeCompare(b.lastName));
        setAllOwners(sortedOwners);
        setResults(sortedOwners);
      } catch (error) {
        console.error('Error fetching property owners', error);
      }
    };
    fetchAllOwners();
  }, []);

  useEffect(() => {
    const filteredResults = allOwners.filter(owner =>
      owner.firstName.toLowerCase().includes(searchParams.firstName.toLowerCase()) &&
      owner.lastName.toLowerCase().includes(searchParams.lastName.toLowerCase())
    ).sort((a, b) => a.lastName.localeCompare(b.lastName));

    setResults(filteredResults);
  }, [searchParams, allOwners]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSearchParams({ ...searchParams, [name]: value });
  };

  const handleResultClick = (ownerId) => {
    navigate(`/property-owner/${ownerId}`);
  };

  const handleCreateOwner = () => {
    navigate('/create-owner');
  };

  const renderTableHeader = () => {
    if (isMobile) {
      return (
        <tr>
          <th className="property-owners-results-th">Name</th>
          <th className="property-owners-results-th">Contact</th>
        </tr>
      );
    }
    return (
      <tr>
        <th className="property-owners-results-th">First Name</th>
        <th className="property-owners-results-th">Last Name</th>
        <th className="property-owners-results-th">Phone</th>
        <th className="property-owners-results-th">Email</th>
        <th className="property-owners-results-th">Address</th>
        <th className="property-owners-results-th">City</th>
        <th className="property-owners-results-th">State/Province</th>
        <th className="property-owners-results-th">Zip/Postal Code</th>
      </tr>
    );
  };

  const renderTableRow = (result) => {
    if (isMobile) {
      return (
        <tr key={result.ownerId} onClick={() => handleResultClick(result.ownerId)}>
          <td className="property-owners-results-td">
            {result.firstName} {result.lastName}
          </td>
          <td className="property-owners-results-td">
            {result.phoneNumber}<br />
            {result.emailAddress}
          </td>
        </tr>
      );
    }
    return (
      <tr key={result.ownerId} onClick={() => handleResultClick(result.ownerId)}>
        <td className="property-owners-results-td">{result.firstName}</td>
        <td className="property-owners-results-td">{result.lastName}</td>
        <td className="property-owners-results-td">{result.phoneNumber}</td>
        <td className="property-owners-results-td">{result.emailAddress}</td>
        <td className="property-owners-results-td">{result.address}</td>
        <td className="property-owners-results-td">{result.city}</td>
        <td className="property-owners-results-td">{result.state_province}</td>
        <td className="property-owners-results-td">{result.zip_postal_code}</td>
      </tr>
    );
  };

  return (
    <div className="property-owners-container">
      <div className="property-owners-content">
        <h1 className="property-owners-title">Property Owners Search</h1>
        <form className="property-owners-form">
          <div className="property-owners-grid">
            <div className="property-owners-field">
              <label htmlFor="firstName" className="property-owners-label">First Name</label>
              <input
                type="text"
                id="firstName"
                name="firstName"
                value={searchParams.firstName}
                onChange={handleChange}
                className="property-owners-input"
              />
            </div>
            <div className="property-owners-field">
              <label htmlFor="lastName" className="property-owners-label">Last Name</label>
              <input
                type="text"
                id="lastName"
                name="lastName"
                value={searchParams.lastName}
                onChange={handleChange}
                className="property-owners-input"
              />
            </div>
          </div>
          <div className="property-owners-button-container">
            <button type="button" className="property-owners-button" onClick={handleCreateOwner}>Create New Owner</button>
          </div>
        </form>
        {results.length > 0 && (
          <div className="property-owners-results">
            <h2 className="property-owners-results-title">Search Results</h2>
            <div className="property-owners-results-table-container">
              <table className="property-owners-results-table">
                <thead>
                  {renderTableHeader()}
                </thead>
                <tbody>
                  {results.map(renderTableRow)}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PropertyOwners;