import React, { useState } from 'react';
import axiosInstance from '../../axiosInstance'; // Use axiosInstance instead of axios
import { useSearchParams, useNavigate } from 'react-router-dom';
import './ResetPassword.css';

const ResetPassword = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const [newPassword, setNewPassword] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        const email = searchParams.get('email');
        const token = searchParams.get('token');

        axiosInstance.post('/api/reset-password', { email, token, newPassword })
            .then(() => {
                alert('Password has been reset');
                navigate('/login');
            })
            .catch(error => {
                console.error('There was an error resetting the password!', error);
            });
    };

    return (
        <div className="reset-password-container">
            <div className="reset-password-content">
                <h2 className="reset-password-title">Reset Password</h2>
                <form className="reset-password-form" onSubmit={handleSubmit}>
                    <div>
                        <label>New Password</label>
                        <input
                            type="password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            required
                        />
                    </div>
                    <button className="reset-password-button" type="submit">Reset Password</button>
                </form>
            </div>
        </div>
    );
};

export default ResetPassword;