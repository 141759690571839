import { useState, useEffect } from 'react';
import axiosInstance from '../../../axiosInstance';

const useBuildingStyles = () => {
  const [buildingStyles, setBuildingStyles] = useState([]);
  const [newBuildingStyle, setNewBuildingStyle] = useState({ style_name: '' });
  const [updateSuccess, setUpdateSuccess] = useState('');

  useEffect(() => {
    fetchBuildingStyles();
  }, []);

  const fetchBuildingStyles = async () => {
    try {
      const response = await axiosInstance.get('/api/building-styles');
      setBuildingStyles(response.data);
    } catch (error) {
      console.error('Error fetching building styles:', error);
    }
  };

  const handleAddBuildingStyle = async () => {
    try {
      const response = await axiosInstance.post('/api/building-styles', newBuildingStyle);
      if (response.data.success) {
        setBuildingStyles([...buildingStyles, { ...newBuildingStyle, id: response.data.id }]);
        setNewBuildingStyle({ style_name: '' });
      }
    } catch (error) {
      console.error('Error adding building style:', error);
    }
  };

  const handleUpdateBuildingStyle = async (id, updatedBuildingStyle) => {
    try {
      const response = await axiosInstance.put(`/api/building-styles/${id}`, updatedBuildingStyle);
      if (response.data.success) {
        setBuildingStyles(buildingStyles.map(style => (style.id === id ? updatedBuildingStyle : style)));
        setUpdateSuccess('Building style updated successfully!');
        setTimeout(() => setUpdateSuccess(''), 3000);
      }
    } catch (error) {
      console.error('Error updating building style:', error);
    }
  };

  const handleDeleteBuildingStyle = async (id) => {
    try {
      const response = await axiosInstance.delete(`/api/building-styles/${id}`);
      if (response.data.success) {
        setBuildingStyles(buildingStyles.filter(style => style.id !== id));
      }
    } catch (error) {
      console.error('Error deleting building style:', error);
    }
  };

  const handleBuildingStyleInputChange = (index, field, value) => {
    const updatedBuildingStyles = [...buildingStyles];
    updatedBuildingStyles[index][field] = value;
    setBuildingStyles(updatedBuildingStyles);
  };

  return {
    buildingStyles,
    newBuildingStyle,
    setNewBuildingStyle,
    handleAddBuildingStyle,
    handleUpdateBuildingStyle,
    handleDeleteBuildingStyle,
    handleBuildingStyleInputChange,
    updateSuccess
  };
};

export default useBuildingStyles;