import React from 'react';
import './Systems.css';

const Systems = ({ data, onUpdate }) => {
  const handleInputChange = (system, index, field, value) => {
    const updatedData = { ...data };
    if (!updatedData[system]) {
      updatedData[system] = [];
    }
    if (!updatedData[system][index]) {
      updatedData[system][index] = {};
    }
    updatedData[system][index][field] = value;
    onUpdate(updatedData);
  };

  const handleElectricalChange = (field, value) => {
    const updatedData = {
      ...data,
      electrical: {
        ...(data.electrical || {}),
        [field]: value
      }
    };
    onUpdate(updatedData);
  };

  const addSystemItem = (system) => {
    const updatedData = { ...data };
    if (!updatedData[system]) {
      updatedData[system] = [];
    }
    updatedData[system].push({ type: '', condition: '' });
    onUpdate(updatedData);
  };

  const removeSystemItem = (system, index) => {
    const updatedData = { ...data };
    updatedData[system] = (updatedData[system] || []).filter((_, i) => i !== index);
    onUpdate(updatedData);
  };

  const renderDropdown = (system, index, field, label, options) => (
    <select
      value={(data[system] && data[system][index] && data[system][index][field]) || ''}
      onChange={(e) => handleInputChange(system, index, field, e.target.value)}
    >
      <option value="">Select {label}</option>
      {options.map(option => (
        <option key={option} value={option}>{option}</option>
      ))}
    </select>
  );

  const renderSystemSection = (system, title, typeOptions, conditionOptions) => (
    <div className="system-section">
      <h4>{title}</h4>
      <table>
        <thead>
          <tr>
            <th>Type</th>
            <th>Condition</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {(data[system] || []).map((item, index) => (
            <tr key={index}>
              <td>{renderDropdown(system, index, 'type', 'Type', typeOptions)}</td>
              <td>{renderDropdown(system, index, 'condition', 'Condition', conditionOptions)}</td>
              <td>
                <button onClick={() => removeSystemItem(system, index)} className="remove-btn">Remove</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <button onClick={() => addSystemItem(system)} className="add-btn">Add {title}</button>
    </div>
  );

  return (
    <div className="systems">
      <h3>Building Systems</h3>
      {renderSystemSection('heating', 'Heating', 
        ['Forced Air', 'Radiator', 'Heat Pump', 'Baseboard', 'Radiant'],
        ['Excellent', 'Good', 'Fair', 'Poor']
      )}
      {renderSystemSection('cooling', 'Cooling',
        ['Central Air', 'Window Units', 'Split System', 'Evaporative'],
        ['Excellent', 'Good', 'Fair', 'Poor']
      )}
      {renderSystemSection('plumbing', 'Plumbing',
        ['Copper', 'PVC', 'Galvanized', 'PEX'],
        ['Excellent', 'Good', 'Fair', 'Poor']
      )}
      <div className="system-section">
        <h4>Electrical</h4>
        <div className="form-group">
          <label htmlFor="outletWiring">Outlets & Wiring:</label>
          <select
            id="outletWiring"
            value={(data.electrical && data.electrical.outletWiring) || ''}
            onChange={(e) => handleElectricalChange('outletWiring', e.target.value)}
          >
            <option value="">Select Type</option>
            <option value="Grounded">Grounded</option>
            <option value="Ungrounded">Ungrounded</option>
            <option value="Mixed">Mixed</option>
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="outletWiringCondition">Outlets & Wiring Condition:</label>
          <select
            id="outletWiringCondition"
            value={(data.electrical && data.electrical.outletWiringCondition) || ''}
            onChange={(e) => handleElectricalChange('outletWiringCondition', e.target.value)}
          >
            <option value="">Select Condition</option>
            <option value="Excellent">Excellent</option>
            <option value="Good">Good</option>
            <option value="Fair">Fair</option>
            <option value="Poor">Poor</option>
          </select>
        </div>
      </div>
    </div>
  );
};

export default Systems;