import { useState, useEffect } from 'react';
import axiosInstance from '../../../axiosInstance';

const useTopography = () => {
  const [topographyTypes, setTopographyTypes] = useState([]);
  const [newTopographyType, setNewTopographyType] = useState({ topography_type: '' });
  const [updateSuccess, setUpdateSuccess] = useState('');

  useEffect(() => {
    fetchTopographyTypes();
  }, []);

  const fetchTopographyTypes = async () => {
    try {
      const response = await axiosInstance.get('/api/topography');
      setTopographyTypes(response.data);
    } catch (error) {
      console.error('Error fetching topography types:', error);
    }
  };

  const handleAddTopographyType = async () => {
    try {
      const response = await axiosInstance.post('/api/topography', newTopographyType);
      if (response.data.success) {
        setTopographyTypes([...topographyTypes, { ...newTopographyType, id: response.data.id }]);
        setNewTopographyType({ topography_type: '' });
      }
    } catch (error) {
      console.error('Error adding topography type:', error);
    }
  };

  const handleUpdateTopographyType = async (id, updatedTopographyType) => {
    try {
      const response = await axiosInstance.put(`/api/topography/${id}`, updatedTopographyType);
      if (response.data.success) {
        setTopographyTypes(topographyTypes.map(type => (type.id === id ? updatedTopographyType : type)));
        setUpdateSuccess('Topography type updated successfully!');
        setTimeout(() => setUpdateSuccess(''), 3000);
      }
    } catch (error) {
      console.error('Error updating topography type:', error);
    }
  };

  const handleDeleteTopographyType = async (id) => {
    try {
      const response = await axiosInstance.delete(`/api/topography/${id}`);
      if (response.data.success) {
        setTopographyTypes(topographyTypes.filter(type => type.id !== id));
      }
    } catch (error) {
      console.error('Error deleting topography type:', error);
    }
  };

  const handleTopographyTypeInputChange = (index, field, value) => {
    const updatedTopographyTypes = [...topographyTypes];
    updatedTopographyTypes[index][field] = value;
    setTopographyTypes(updatedTopographyTypes);
  };

  return {
    topographyTypes,
    newTopographyType,
    setNewTopographyType,
    handleAddTopographyType,
    handleUpdateTopographyType,
    handleDeleteTopographyType,
    handleTopographyTypeInputChange,
    updateSuccess
  };
};

export default useTopography;