import { useState, useEffect } from 'react';
import axiosInstance from '../../../axiosInstance';

const useFoundationTypes = () => {
  const [foundationTypes, setFoundationTypes] = useState([]);
  const [newFoundationType, setNewFoundationType] = useState({ type_name: '' });
  const [updateSuccess, setUpdateSuccess] = useState('');

  useEffect(() => {
    fetchFoundationTypes();
  }, []);

  const fetchFoundationTypes = async () => {
    try {
      const response = await axiosInstance.get('/api/foundation-types');
      setFoundationTypes(response.data);
    } catch (error) {
      console.error('Error fetching foundation types:', error);
    }
  };

  const handleAddFoundationType = async () => {
    try {
      const response = await axiosInstance.post('/api/foundation-types', newFoundationType);
      if (response.data.success) {
        setFoundationTypes([...foundationTypes, { ...newFoundationType, id: response.data.id }]);
        setNewFoundationType({ type_name: '' });
      }
    } catch (error) {
      console.error('Error adding foundation type:', error);
    }
  };

  const handleUpdateFoundationType = async (id, updatedFoundationType) => {
    try {
      const response = await axiosInstance.put(`/api/foundation-types/${id}`, updatedFoundationType);
      if (response.data.success) {
        setFoundationTypes(foundationTypes.map(type => (type.id === id ? updatedFoundationType : type)));
        setUpdateSuccess('Foundation type updated successfully!');
        setTimeout(() => setUpdateSuccess(''), 3000);
      }
    } catch (error) {
      console.error('Error updating foundation type:', error);
    }
  };

  const handleDeleteFoundationType = async (id) => {
    try {
      const response = await axiosInstance.delete(`/api/foundation-types/${id}`);
      if (response.data.success) {
        setFoundationTypes(foundationTypes.filter(type => type.id !== id));
      }
    } catch (error) {
      console.error('Error deleting foundation type:', error);
    }
  };

  const handleFoundationTypeInputChange = (index, field, value) => {
    const updatedFoundationTypes = [...foundationTypes];
    updatedFoundationTypes[index][field] = value;
    setFoundationTypes(updatedFoundationTypes);
  };

  return {
    foundationTypes,
    newFoundationType,
    setNewFoundationType,
    handleAddFoundationType,
    handleUpdateFoundationType,
    handleDeleteFoundationType,
    handleFoundationTypeInputChange,
    updateSuccess
  };
};

export default useFoundationTypes;