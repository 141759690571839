import React, { useState, useEffect } from 'react';
import axiosInstance from '../../axiosInstance'; // Use axiosInstance instead of axios
import './UserManagementDeleted.css';

const UserManagementDeleted = () => {
    const [deletedUsers, setDeletedUsers] = useState([]);

    useEffect(() => {
        axiosInstance.get('/api/deleted-users')
            .then(response => {
                console.log('Fetched deleted users:', response.data);
                setDeletedUsers(response.data);
            })
            .catch(error => {
                console.error('There was an error fetching the deleted users!', error);
            });
    }, []);

    const handleUndelete = (id) => {
        axiosInstance.put(`/api/undelete-user/${id}`)
            .then(() => {
                setDeletedUsers(deletedUsers.filter(user => user.id !== id));
            })
            .catch(error => {
                console.error('There was an error undeleting the user!', error);
            });
    };

    return (
        <div className="user-management-deleted-container">
            <div className="user-management-deleted-content">
                <h2 className="user-management-deleted-title">Deleted Users</h2>
                <div className="user-management-deleted-table-container">
                    <table className="user-management-deleted-table">
                        <thead>
                            <tr>
                                <th className="user-management-deleted-th">ID</th>
                                <th className="user-management-deleted-th">Username</th>
                                <th className="user-management-deleted-th">Email</th>
                                <th className="user-management-deleted-th">Role</th>
                                <th className="user-management-deleted-th">Undelete</th>
                            </tr>
                        </thead>
                        <tbody>
                            {deletedUsers.length > 0 ? (
                                deletedUsers.map(user => (
                                    <tr key={user.id}>
                                        <td className="user-management-deleted-td">{user.id}</td>
                                        <td className="user-management-deleted-td">{user.username}</td>
                                        <td className="user-management-deleted-td">{user.email}</td>
                                        <td className="user-management-deleted-td">{user.role}</td>
                                        <td className="user-management-deleted-td">
                                            <input
                                                type="checkbox"
                                                onChange={() => handleUndelete(user.id)}
                                            />
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="5" className="user-management-deleted-td">No deleted users found.</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default UserManagementDeleted;
