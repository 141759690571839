import React from 'react';
import useExteriorWallTypes from '../hooks/useExteriorWallTypes';
import './ExteriorWallTypes.css';

const ExteriorWallTypes = () => {
  console.log('Rendering ExteriorWallTypes component');
  const {
    exteriorWallTypes,
    newExteriorWallType,
    setNewExteriorWallType,
    handleAddExteriorWallType,
    handleUpdateExteriorWallType,
    handleDeleteExteriorWallType,
    handleExteriorWallTypeInputChange,
    updateSuccess
  } = useExteriorWallTypes();

  return (
    <>
      {updateSuccess && <div className="update-success">{updateSuccess}</div>}
      <div className="exterior-wall-types">
        <table className="exterior-wall-types-table">
          <thead>
            <tr>
              <th>Exterior Wall Type</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {exteriorWallTypes.map((exteriorWallType, index) => (
              <tr key={exteriorWallType.id}>
                <td>
                  <input
                    type="text"
                    value={exteriorWallType.type_name}
                    onChange={(e) =>
                      handleExteriorWallTypeInputChange(index, 'type_name', e.target.value)
                    }
                    placeholder="Exterior Wall Type"
                  />
                </td>
                <td>
                  {/* Wrap the buttons inside a div with class "button-group" */}
                  <div className="button-group">
                    <button
                      onClick={() =>
                        handleUpdateExteriorWallType(exteriorWallType.id, exteriorWallType)
                      }
                      className="update-button"
                    >
                      Update
                    </button>
                    <button
                      onClick={() => handleDeleteExteriorWallType(exteriorWallType.id)}
                      className="delete-button"
                    >
                      Delete
                    </button>
                  </div>
                </td>
              </tr>
            ))}
            <tr className="new-exterior-wall-type">
              <td>
                <input
                  type="text"
                  value={newExteriorWallType.type_name}
                  onChange={(e) =>
                    setNewExteriorWallType({
                      ...newExteriorWallType,
                      type_name: e.target.value
                    })
                  }
                  placeholder="New Exterior Wall Type"
                />
              </td>
              <td>
                {/* Wrap the add button inside a div with class "button-group" */}
                <div className="button-group">
                  <button onClick={handleAddExteriorWallType} className="add-button">
                    Add Exterior Wall Type
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default ExteriorWallTypes;
