import { useState, useEffect, useCallback } from 'react';
import axiosInstance from '../../../axiosInstance';

const useLandSchedules = () => {
  const [landTypes, setLandTypes] = useState([]);
  const [newLandType, setNewLandType] = useState({ type: '', value_per_acre: '', improvement: false, std_lot: 1, std_acre: 1 });
  const [updateSuccess, setUpdateSuccess] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const clearMessages = useCallback(() => {
    setUpdateSuccess('');
    setError('');
  }, []);

  useEffect(() => {
    fetchLandTypes();
    console.log('Fetched Land Types:', landTypes);
  }, []);

  useEffect(() => {
    if (updateSuccess || error) {
      const timer = setTimeout(() => {
        clearMessages();
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [updateSuccess, error, clearMessages]);

  const fetchLandTypes = async () => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get('/api/land-types');
      setLandTypes(response.data.map(lt => ({
        ...lt,
        improvement: !!lt.improvement,  // Ensure it's a boolean
        std_lot: lt.std_lot || 1,  // Default to 1 if undefined
        std_acre: lt.std_acre || 1  // Default to 1 if undefined
      })));
    } catch (error) {
      setError('Error fetching land types. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };
  

  const handleAddLandType = async () => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.post('/api/land-types', newLandType);
      if (response.data.success) {
        setLandTypes([...landTypes, { ...newLandType, id: response.data.id }]);
        setNewLandType({ type: '', value_per_acre: '', improvement: false, std_lot: 1, std_acre: 1 });
        setUpdateSuccess('New land type added successfully!');
      }
    } catch (error) {
      setError('Error adding land type. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdateLandType = async (id, updatedLandType) => {
    setIsLoading(true);
    try {
      await axiosInstance.put(`/api/land-types/${id}`, updatedLandType);
      await axiosInstance.put(`/api/land-types/recalculate-land-values/${id}`, updatedLandType);
      await fetchLandTypes();
      setUpdateSuccess('Land type updated and land values recalculated successfully!');
    } catch (error) {
      setError('Error updating land type. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteLandType = async (id) => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.delete(`/api/land-types/${id}`);
      if (response.data.success) {
        setLandTypes(landTypes.filter(lt => lt.id !== id));
        setUpdateSuccess('Land type deleted successfully!');
      }
    } catch (error) {
      setError('Error deleting land type. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleLandTypeInputChange = (index, field, value) => {
    const updatedLandTypes = [...landTypes];
    updatedLandTypes[index][field] = value;
    setLandTypes(updatedLandTypes);
  };

  return {
    landTypes,
    newLandType,
    setNewLandType,
    handleAddLandType,
    handleUpdateLandType,
    handleDeleteLandType,
    handleLandTypeInputChange,
    updateSuccess,
    error,
    isLoading,
    clearMessages
  };
};

export default useLandSchedules;
