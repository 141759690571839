import React, { useState } from 'react';
import axiosInstance from '../../axiosInstance'; // Use axiosInstance instead of axios
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import './UserManagementCreate.css';

const UserManagementCreate = () => {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [role, setRole] = useState('read');
    const navigate = useNavigate(); // Initialize useNavigate

    const handleSubmit = (e) => {
        e.preventDefault();
        axiosInstance.post('/api/users', { username, email, password, role })
            .then(() => {
                navigate('/usermanagement'); // Redirect to user management page
            })
            .catch(error => {
                console.error('There was an error creating the user!', error);
            });
    };

    return (
        <div className="user-management-create-container">
            <div className="user-management-create-content">
                <h2 className="user-management-create-title">Create New User</h2>
                <form className="user-management-create-form" onSubmit={handleSubmit}>
                    <div>
                        <label>Username</label>
                        <input
                            type="text"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            required
                        />
                    </div>
                    <div>
                        <label>Email</label>
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <div>
                        <label>Password</label>
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                    </div>
                    <div>
                        <label>Role</label>
                        <select value={role} onChange={(e) => setRole(e.target.value)}>
                            <option value="admin">Admin</option>
                            <option value="read">Read</option>
                            <option value="read/write">Read/Write</option>
                        </select>
                    </div>
                    <button className="user-management-create-button" type="submit">Create User</button>
                </form>
            </div>
        </div>
    );
};

export default UserManagementCreate;
