import React, { useState } from 'react';
import axiosInstance from '../../../axiosInstance';
import { useNavigate } from 'react-router-dom';
import getApiUrl from '../../../config';
import './CreateOwner.css';

const CreateOwner = () => {
  const navigate = useNavigate();
  const [owner, setOwner] = useState({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    emailAddress: '',
    address: '',
    city: '',
    state_province: '',
    zip_postal_code: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setOwner({ ...owner, [name]: value });
  };

  const handleSave = async () => {
    const apiUrl = getApiUrl();
    try {
      const response = await axiosInstance.post(`${apiUrl}/api/property-owners`, owner);
      if (response.status === 200) {
        alert('Owner created successfully');
        navigate('/property-owners'); // Redirect to property owners list
      } else {
        alert('Failed to create owner');
      }
    } catch (error) {
      alert(`Error creating property owner: ${error.response?.data || error.message}`);
      console.error('Error creating property owner', error);
    }
  };

  return (
    <div className="create-owner-container">
      <div className="create-owner-content">
        <h1 className="create-owner-title">Create Property Owner</h1>
        <form className="create-owner-form">
          <div className="create-owner-grid">
            <div className="create-owner-field">
              <label htmlFor="firstName" className="create-owner-label">First Name</label>
              <input
                type="text"
                id="firstName"
                name="firstName"
                value={owner.firstName}
                onChange={handleChange}
                className="create-owner-input"
              />
            </div>
            <div className="create-owner-field">
              <label htmlFor="lastName" className="create-owner-label">Last Name</label>
              <input
                type="text"
                id="lastName"
                name="lastName"
                value={owner.lastName}
                onChange={handleChange}
                className="create-owner-input"
              />
            </div>
            <div className="create-owner-field">
              <label htmlFor="phoneNumber" className="create-owner-label">Phone Number</label>
              <input
                type="text"
                id="phoneNumber"
                name="phoneNumber"
                value={owner.phoneNumber}
                onChange={handleChange}
                className="create-owner-input"
              />
            </div>
            <div className="create-owner-field">
              <label htmlFor="emailAddress" className="create-owner-label">Email Address</label>
              <input
                type="email"
                id="emailAddress"
                name="emailAddress"
                value={owner.emailAddress}
                onChange={handleChange}
                className="create-owner-input"
              />
            </div>
            <div className="create-owner-field">
              <label htmlFor="address" className="create-owner-label">Address</label>
              <input
                type="text"
                id="address"
                name="address"
                value={owner.address}
                onChange={handleChange}
                className="create-owner-input"
              />
            </div>
            <div className="create-owner-field">
              <label htmlFor="city" className="create-owner-label">City</label>
              <input
                type="text"
                id="city"
                name="city"
                value={owner.city}
                onChange={handleChange}
                className="create-owner-input"
              />
            </div>
            <div className="create-owner-field">
              <label htmlFor="state_province" className="create-owner-label">State/Province</label>
              <input
                type="text"
                id="state_province"
                name="state_province"
                value={owner.state_province}
                onChange={handleChange}
                className="create-owner-input"
              />
            </div>
            <div className="create-owner-field">
              <label htmlFor="zip_postal_code" className="create-owner-label">Zip/Postal Code</label>
              <input
                type="text"
                id="zip_postal_code"
                name="zip_postal_code"
                value={owner.zip_postal_code}
                onChange={handleChange}
                className="create-owner-input"
              />
            </div>
          </div>
          <div className="create-owner-button-container">
            <button type="button" onClick={handleSave} className="create-owner-button">Save</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateOwner;